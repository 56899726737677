/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Order, ReceivedOrder } from "../../../models/order";
import { useNotification } from "../../../utils/notification/notificationContext";
import { useSort } from "../../../hooks/useSort";
import { Column } from "../../../tables/models";
import LoadingWheel from "../../../components/loadingWheel";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../tables/receivedOrdersTable";
import { ActionButton } from "../../../components/buttons";
import { InputTextField } from "../../../components/inputField";
import {
    createWarehouseClarification,
    fetchOrdersFromWarehouseClarification,
    markWarehouseClarificationTaskComplete,
    updateWarehouseClarification,
} from "./api/warehouseClarificationApi";
import { WarehouseClarificationTask } from "./tasks/page";
import OrdersTable, { getTableHeaders } from "../../../tables/ordersTable";
import { MultipleFileUpload } from "../../../utils/files/multipleFileUpload";

interface WarehouseClarificationModalProps {
    orderType: "received" | "inventory";
    modalType: "create" | "view";
    orders: ReceivedOrder[] | Order[];
    setOrders:
        | React.Dispatch<React.SetStateAction<ReceivedOrder[]>>
        | React.Dispatch<React.SetStateAction<Order[]>>;
    openWarehouseClarificationModal: boolean;
    setOpenWarehouseClarificationModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    handleRefetch: () => void;
    selectedWarehouseClarification?: WarehouseClarificationTask | null;
}

function WarehouseClarificationModal({
    orderType,
    modalType,
    orders,
    setOrders,
    openWarehouseClarificationModal,
    setOpenWarehouseClarificationModal,
    handleRefetch,
    selectedWarehouseClarification,
}: WarehouseClarificationModalProps) {
    const isAdmin = () => window.location.href.includes("admin");

    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        orderType === "received"
            ? initialReceivedOrdersColumnOrder
            : getTableHeaders("inventory")
    );

    const [loading, setLoading] = useState(false);

    const [selectedOrders, setSelectedOrders] = useState<ReceivedOrder[]>([]);
    const [selectedInvOrders, setSelectedInvOrders] = useState<Order[]>([]);

    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");

    const handleRequest = async () => {
        setLoading(true);
        const data = await createWarehouseClarification(
            orders.map((o) =>
                "received_order_id" in o ? o.received_order_id : o.order_id
            ),
            question,
            orderType
        );
        if (data.status === "success") {
            showNotification(
                "Created warehouse clarification request.",
                "",
                "success"
            );
            setQuestion("");
            setOpenWarehouseClarificationModal(false);
        } else {
            showNotification("Failed to create request", data.message, "error");
        }
        setLoading(false);
    };

    const [dataLoading, setDataLoading] = useState(false);
    const fetchData = async () => {
        if (selectedWarehouseClarification) {
            setDataLoading(true);
            const data = await fetchOrdersFromWarehouseClarification(
                selectedWarehouseClarification.id
            );
            if (data.status === "success") {
                setOrders(data.data.orders);
            }
            setDataLoading(false);
        }
    };

    useEffect(() => {
        if (
            selectedWarehouseClarification &&
            modalType === "view" &&
            openWarehouseClarificationModal
        ) {
            fetchData();
        }
        if (!openWarehouseClarificationModal) {
            setOrders([]);
            setSelectedFiles([]);
            setAnswer("");
        }
    }, [openWarehouseClarificationModal]);

    const [updateLoading, setUpdateLoading] = useState(false);
    const handleUpdate = async () => {
        if (selectedWarehouseClarification) {
            setUpdateLoading(true);
            const data = await updateWarehouseClarification(
                selectedWarehouseClarification.id,
                answer,
                selectedFiles
            );
            if (data.status === "success") {
                showNotification(
                    "Successfully responded to warehouse request",
                    "",
                    "success"
                );
                handleRefetch();
                setOpenWarehouseClarificationModal(false);
            } else {
                showNotification(
                    "Failed to respond to warehouse request",
                    data.message,
                    "error"
                );
            }
            setUpdateLoading(false);
        }
    };

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const handleFileChange = (orderId: string, files: File[]) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };

    const removeFile = (orderId: string, fileToRemove: File) => {
        setSelectedFiles(selectedFiles.filter((file) => file !== fileToRemove));
    };

    const handleMarkComplete = async () => {
        setLoading(true);
        const data = await markWarehouseClarificationTaskComplete(
            selectedWarehouseClarification?.id || -1
        );
        if (data.status === "success") {
            showNotification(data.message, "", "success");
            handleRefetch();
            setOpenWarehouseClarificationModal(false);
        } else {
            showNotification(data.message, data.errors[0], "error");
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openWarehouseClarificationModal}
            onClose={setOpenWarehouseClarificationModal}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-[95%] sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenWarehouseClarificationModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            {modalType === "create"
                                ? "Request Warehouse Clarification"
                                : "Warehouse Clarification Request"}
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            {!dataLoading && orderType === "received" ? (
                                <ReceivedOrdersTable
                                    orders={orders as ReceivedOrder[]}
                                    setOrders={
                                        setOrders as React.Dispatch<
                                            React.SetStateAction<
                                                ReceivedOrder[]
                                            >
                                        >
                                    }
                                    results={null}
                                    selectedOrders={selectedOrders}
                                    setSelectedOrders={setSelectedOrders}
                                    sortLoading={sortLoading}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    handleRefetch={handleRefetch}
                                    setTotalSum={setTotalSum}
                                    columnOrder={unitsColumnOrder}
                                    hiddenHeaders={[
                                        "select",
                                        "input_shipping_quantity",
                                        "quantity_to_dispose",
                                    ]}
                                    onRowClick={() => {}}
                                    client_type="admin"
                                />
                            ) : !dataLoading && orderType === "inventory" ? (
                                <OrdersTable
                                    orderStatus="inventory"
                                    orders={orders as Order[]}
                                    results={null}
                                    selectedOrders={selectedInvOrders}
                                    setSelectedOrders={setSelectedInvOrders}
                                    sortLoading={sortLoading}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    handleRefetch={handleRefetch}
                                    client_type="admin"
                                    setTotalSum={setTotalSum}
                                    columnOrder={unitsColumnOrder}
                                    hiddenHeaders={[
                                        "select",
                                        "input_shipping_quantity",
                                        "input_shelf_location",
                                        "qa",
                                        "remove_selected_row",
                                        "quantity_to_dispose",
                                    ]}
                                />
                            ) : (
                                <div className="flex flex-row items-center justify-center pt-4">
                                    <LoadingWheel />
                                </div>
                            )}
                            <div className="flex flex-row items-start space-x-10">
                                <InputTextField
                                    label="Question / Clarification"
                                    value={
                                        modalType === "create" &&
                                        !selectedWarehouseClarification
                                            ? question
                                            : selectedWarehouseClarification?.question ||
                                              ""
                                    }
                                    onChange={(e) =>
                                        setQuestion(e.target.value)
                                    }
                                    disabled={modalType !== "create"}
                                />
                                {modalType === "view" &&
                                    selectedWarehouseClarification && (
                                        <div className="flex flex-col items-end space-y-3 w-full">
                                            <InputTextField
                                                label="Employee Answer"
                                                value={
                                                    selectedWarehouseClarification.response
                                                        ? selectedWarehouseClarification.response
                                                        : answer
                                                }
                                                onChange={(e) =>
                                                    setAnswer(e.target.value)
                                                }
                                                disabled={
                                                    selectedWarehouseClarification.response
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!selectedWarehouseClarification.response && (
                                                <>
                                                    <MultipleFileUpload
                                                        selectedFiles={
                                                            selectedFiles
                                                        }
                                                        handleFileChangeRoot={
                                                            handleFileChange
                                                        }
                                                        removeFile={removeFile}
                                                        orderId={""}
                                                        fileType="Images"
                                                    />
                                                    <ActionButton
                                                        label="Save"
                                                        handleClick={() =>
                                                            handleUpdate()
                                                        }
                                                        loading={updateLoading}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    )}
                            </div>
                            <div className="flex flex-row items-center">
                                {selectedWarehouseClarification &&
                                    selectedWarehouseClarification.images.map(
                                        (img) => (
                                            <img
                                                className="max-h-96 border border-gray-200"
                                                src={img}
                                            />
                                        )
                                    )}
                            </div>
                        </div>
                        <div className="flex flex-row-reverse">
                            {modalType === "create" && (
                                <ActionButton
                                    label="Request clarification"
                                    handleClick={() => handleRequest()}
                                    height={9}
                                    loading={loading}
                                />
                            )}
                            {selectedWarehouseClarification?.status ===
                                "answered" && (
                                <ActionButton
                                    label="Mark completed"
                                    handleClick={() => handleMarkComplete()}
                                    height={9}
                                    loading={loading}
                                />
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default WarehouseClarificationModal;
