/** @format */

import { useEffect, useState } from "react";
import DashboardFeatures from "../../components/website/dashboardFeatures";
import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import Services from "../../components/website/services";
import EmailPopup from "./emailPopup";
import RequestQuotePage from "./requestQuote";
import { PiPackageLight } from "react-icons/pi";
import { LiaShippingFastSolid } from "react-icons/lia";
import { PiShippingContainerLight } from "react-icons/pi";
import useExitIntent from "../../hooks/useExitIntent";
import { CiCreditCard1 } from "react-icons/ci";
import { Helmet } from "react-helmet";
import LoadingWheel from "../../components/loadingWheel";
import { sendContactRequest } from "./api/contactApi";
import { useNotification } from "../../utils/notification/notificationContext";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import FurtherContactModal from "./furtherContactModal";
import FeaturesTable from "./featuresTable";
import ServicesSection from "./servicesSection";

const homeElements = [
    { id: 1, name: "Order Fulfillment", icon: PiPackageLight },
    { id: 3, name: "FBA Preparation", icon: PiShippingContainerLight },
    { id: 2, name: "Two-Step Dropshipping", icon: LiaShippingFastSolid },
    { id: 4, name: "Tax-Free Purchasing", icon: CiCreditCard1 },
];

export default function HomePage() {
    const { showNotification } = useNotification();
    const [showPopup, setShowPopup] = useState(false);
    const { isActive, disable } = useExitIntent({
        delayTime: 1000,
        initiallyDisabled: false,
    });

    useEffect(() => {
        if (isActive) {
            setShowPopup(true);
        }
    }, [isActive]);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleContact = async () => {
        setLoading(true);
        if (email === "") {
            setLoading(false);
            showNotification(
                "Please enter your email before submitting.",
                "",
                "error"
            );
            return;
        }

        const data = await sendContactRequest(
            "",
            "",
            email,
            "",
            "",
            "",
            "Contact form - Home"
        );
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
            setSubmittedForm(true);
            setOpenFurtherContactModal(true);
        } else {
            showNotification(
                "Failed sending contact request. Try again.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    const [submittedForm, setSubmittedForm] = useState(false);

    const [openFurtherContactModal, setOpenFurtherContactModal] =
        useState(false);

    return (
        <div className="scroll-smooth">
            <Helmet>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=AW-16730636970"
                ></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'AW-16730636970');
          `}
                </script>
            </Helmet>
            <img
                alt=""
                src="/homebg.png"
                className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-55"></div>
            <Header />
            <div className="absolute bottom-36" id="learn"></div>
            <div>
                <div className="relative isolate px-6 lg:px-8 h-screen flex items-center">
                    <div className="mx-auto max-w-2xl">
                        <div className="text-center">
                            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-6xl">
                                Focus on your{" "}
                                <span className="text-accent">business</span>{" "}
                                while we handle the rest
                            </h1>
                            <div className="text-gray-300 grid grid-cols-4 sm:gap-x-0 gap-x-2 sm:grid-cols-4 justify-center pt-10 place-items-center">
                                {homeElements.map((item) => (
                                    <div className="flex flex-col justify-center items-center max-w-12 sm:max-w-16">
                                        <item.icon className="sm:w-16 sm:h-16 w-10 h-10" />
                                        <span className=" text-xs sm:text-sm font-semibold">
                                            {item.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <div className="mt-6 sm:flex sm:max-w-md lg:mt-0 sm:flex-col text-center">
                                    <label
                                        htmlFor="email-address"
                                        className="text-gray-300 font-medium "
                                    >
                                        Discover how we can help your business
                                    </label>
                                    <div className="flex flex-row items-center space-x-2 pt-1">
                                        <input
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            id="email-address"
                                            name="email-address"
                                            type="email"
                                            required
                                            placeholder="Enter your email"
                                            autoComplete="email"
                                            className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:w-56 sm:text-sm/6"
                                        />
                                        <div className="">
                                            <button
                                                disabled={submittedForm}
                                                onClick={() => handleContact()}
                                                className={`${
                                                    submittedForm
                                                        ? "bg-green-200 text-green-500 cursor-default"
                                                        : "bg-accent hover:bg-accenthighlight text-white"
                                                } flex items-center justify-center w-20 rounded-md h-9 px-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent`}
                                            >
                                                {submittedForm ? (
                                                    <CheckCircleIcon className="w-5 h-5 items-center flex" />
                                                ) : !loading ? (
                                                    <>Submit</>
                                                ) : (
                                                    <LoadingWheel
                                                        size="small"
                                                        color="white"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="learn"></div>
            <div className="pt-4 pb-4 sm:pb-4 sm:pt-16">
                <ServicesSection />
            </div>
            <Services />
            <DashboardFeatures />

            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl lg:text-center">
                    <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
                        Confidentiality & Security
                    </p>
                    <p className="mt-6 text-lg/8 text-gray-600">
                        Our packing stations are monitored and recorded 24/7,
                        giving you video proof to resolve any customer or
                        supplier claims. Combined with strict NDAs for all staff
                        and clients, and no outside access to our warehouse, we
                        keep your business secure, confidential, and in trusted
                        hands.
                    </p>
                </div>
            </div>

            <div id="request-quote"></div>
            <RequestQuotePage disable={disable} />
            <Footer />

            <EmailPopup show={showPopup} onClose={handleClosePopup} />

            <FurtherContactModal
                openFurtherContactModal={openFurtherContactModal}
                setOpenFurtherContactModal={setOpenFurtherContactModal}
                email={email}
            />
        </div>
    );
}
