/** @format */

import { useEffect, useState } from "react";
import { ActionButton, SecondaryButton } from "../../../../components/buttons";
import ClientSearchBar from "../../../../components/client/searchBar";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import LoadingWheel from "../../../../components/loadingWheel";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../../tables/receivedOrdersTable";
import { ReceivedOrder } from "../../../../models/order";
import Results from "../../../../models/results";
import { Column } from "../../../../tables/models";
import { useSort } from "../../../../hooks/useSort";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { orderTypes } from "../../../../models/data";
import { useClient } from "../../../../utils/client/clientContext";
import { fetchReceivedOrders } from "../api/receivedApi";
import EditReceivedOrderModal from "../../../admin/received/editReceivedOrderModal";
import { SmallSelectorWithNavigate } from "../../../../components/dropdowns/smallSelector";
import { exportData } from "../../api/exportApi";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import ClientDashboardLayout from "../../../../components/client/dashboardLayout";
import ReceivedRequestActionsModal from "../receivedRequestActionsModal";

function NeedsCheckInPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();
    const [keywords, setKeywords] = useState<string[]>([]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    const [orders, setOrders] = useState<ReceivedOrder[]>([]);
    const [results, setResults] = useState<Results | null>(null);

    const [loading, setLoading] = useState(false);

    const [selectedOrders, setSelectedOrders] = useState<ReceivedOrder[]>([]);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    const fetchData = async () => {
        const data = await fetchReceivedOrders(
            "received",
            searchParams.get("offset") ? Number(searchParams.get("offset")) : 0,
            searchParams.get("limit") ? Number(searchParams.get("limit")) : -1,
            sortConfig
        );
        if (data.status === "success") {
            setOrders(data.data.received_orders);
            setResults(data.data.results);
        } else {
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [sortConfig, refetchData]);

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        initialReceivedOrdersColumnOrder
    );

    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);

    const [openRequestActionsModal, setOpenRequestActionsModal] =
        useState(false);

    return (
        <ClientDashboardLayout>
            <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                <div className="flex flex-row items-center">
                    <span className="text-2xl font-bold">Checked In</span>
                    <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                        {orderCount?.received.toLocaleString()}
                    </span>
                </div>
                {totalSum !== "" && (
                    <div className="text-gray-700 text-sm">
                        Total Sum: {totalSum}
                    </div>
                )}
                <ActionButton
                    label="Request Actions"
                    handleClick={() => setOpenRequestActionsModal(true)}
                    viewDisabled={selectedOrders.length < 1}
                    height={8}
                />
            </div>
            <div className="flex flex-row items-start py-4 space-x-4">
                <SmallSelectorWithNavigate
                    selectorData={[
                        {
                            id: 2,
                            name: "Needs Check In",
                            value: "/received/needs-check-in",
                        },
                        {
                            id: 1,
                            name: "Checked In",
                            value: "/received/checked-in",
                        },
                    ]}
                />
                <ClientSearchBar
                    setData={setOrders}
                    setLoading={setLoading}
                    handleRefetch={handleRefetch}
                    page="received"
                    sub_page="received"
                    keywords={keywords}
                    setKeywords={setKeywords}
                />
                <ColumnOrderDropdown
                    columnOrder={unitsColumnOrder}
                    setColumnOrder={setUnitsColumnOrder}
                    localStorageKey="receivedOrdersColumnOrder"
                    selectedOrderType={selectedOrderType}
                    initialColumnOrder={initialReceivedOrdersColumnOrder}
                    buttonHeightClass="h-8"
                />
                <SecondaryButton
                    label="Export"
                    iconDisplayed={ArrowUpTrayIcon}
                    handleClick={() =>
                        exportData(
                            "received",
                            "units",
                            setExportLoading,
                            showNotification
                        )
                    }
                    loading={exportLoading}
                    height={8}
                />
                {selectedOrders.length > 0 && (
                    <SecondaryButton
                        label="Bulk edit"
                        handleClick={() => setOpenBulkEditModal(true)}
                        viewDisabled={selectedOrders.length < 1}
                        height={8}
                    />
                )}
            </div>
            {!loading ? (
                <ReceivedOrdersTable
                    orders={orders}
                    setOrders={setOrders}
                    results={results}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    sortLoading={sortLoading}
                    sortConfig={sortConfig}
                    toggleSort={toggleSort}
                    handleRefetch={() => {}}
                    setTotalSum={setTotalSum}
                    columnOrder={unitsColumnOrder}
                    hiddenHeaders={[
                        "location",
                        "order_name",
                        "input_shipping_quantity",
                        "quantity_to_dispose",
                    ]}
                    onRowClick={() => {}}
                />
            ) : (
                <div className="flex flex-grow items-center justify-center">
                    <LoadingWheel />
                </div>
            )}
            <EditReceivedOrderModal
                receivedOrder={null}
                setOrders={setOrders}
                openEditReceivedOrderModal={openBulkEditModal}
                setOpenEditReceivedOrderModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
                bulkEdit={true}
                selectedOrders={selectedOrders}
            />
            <ReceivedRequestActionsModal
                openRequestActionsModal={openRequestActionsModal}
                setOpenRequestActionsModal={setOpenRequestActionsModal}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                handleRefetch={handleRefetch}
            />
        </ClientDashboardLayout>
    );
}

export default NeedsCheckInPage;
