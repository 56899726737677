/** @format */
import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import LoadingWheel from "../../../components/loadingWheel";
import ClientOutboundTable from "../../admin/clients/client-data/shipments/client-orders-data/clientOutboundTable";
import {
    fetchOutboundOrders,
    fetchShipmentAndOrders,
    unrequestOutboundShipment,
} from "./api/outboundApi";
import Shipment from "../../../models/shipments";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import { useNotification } from "../../../utils/notification/notificationContext";
import { ShipmentTracking } from "../../../models/shipments";
import ShipmentTrackingsTable from "../../../components/tables/shipmentTrackings";
import { Order } from "../../../models/order";
import Results from "../../../models/results";
import {
    downloadShipmentPdf,
    getShipmentPdfUrl,
} from "../../admin/api/labelsApi";
import { Label } from "../../../models/label";
import { LabelModal } from "../inventory/inventory-data/request-shipment/labelModal";
import ClientSearchBar from "../../../components/client/searchBar";

export default function OutboundOrdersModal({
    shipmentLoaded,
    openOutboundOrders,
    setOpenOutboundOrders,
    shipment_id,
    handleRefetch,
    showSearchBar = true,
}: {
    shipmentLoaded: Shipment | null;
    openOutboundOrders: boolean;
    setOpenOutboundOrders: React.Dispatch<React.SetStateAction<boolean>>;
    shipment_id?: string | null;
    handleRefetch?: () => void;
    showSearchBar?: boolean;
}) {
    const { user_id, invoice_id } = useParams();
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState<string[]>([]);

    const [outboundOrders, setOutboundOrders] = useState<Order[]>([]);
    const [shipmentTrackings, setShipmentTrackings] = useState<
        ShipmentTracking[]
    >([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });
    const [refetchOrders, setRefetchOrders] = useState(0);
    const handleRefetchOrders = () => {
        setRefetchOrders((prev) => prev + 1);
    };

    const [shipment, setShipment] = useState<Shipment | null>(null);

    const [offset, setOffset] = useState(0);

    const [fullLoading, setFullLoading] = useState(false);

    const shipmentInfo = [
        {
            label: "Date",
            value: formatDateFromDb(shipment?.date ? shipment?.date : ""),
        },
        {
            label: "Notes",
            value: shipment?.notes,
        },
    ];

    const requestedShipmentInfo = [
        {
            label: "Date",
            value: formatDateFromDb(shipment?.date ? shipment?.date : ""),
        },
        {
            label: "Notes",
            value: shipment?.notes,
        },
    ];

    useEffect(() => {
        if (!openOutboundOrders) {
            setTimeout(() => {
                setShipment(null);
                setSelectedViewingLabel(null);
            }, 250);
        }
    }, [openOutboundOrders]);

    useEffect(() => {
        if (openOutboundOrders || offset) {
            if (shipmentLoaded) {
                const fetchData = async () => {
                    setLoading(true);
                    const data = await fetchOutboundOrders(
                        shipmentLoaded?.shipment_id
                            ? shipmentLoaded?.shipment_id
                            : "",
                        offset
                    );
                    if (data.status === "success") {
                        setOutboundOrders(data.data.outbound);
                        setShipmentTrackings(data.data.trackings);
                        setResults(data.data.results);
                    } else {
                        showNotification(
                            "Failed fetching data",
                            data.message,
                            "error"
                        );
                    }
                    setLoading(false);
                };
                setShipment(shipmentLoaded);
                fetchData();
            } else {
                const fetchData = async () => {
                    setFullLoading(true);
                    const data = await fetchShipmentAndOrders(
                        shipment_id || "",
                        offset,
                        user_id
                    );
                    if (data.status === "success") {
                        setShipment(data.data.shipment);
                        setOutboundOrders(data.data.outbound);
                        setShipmentTrackings(data.data.trackings);
                        setResults(data.data.results);
                    } else {
                        showNotification(
                            "Failed fetching data",
                            data.message,
                            "error"
                        );
                    }
                    setFullLoading(false);
                };
                fetchData();
            }
        }
    }, [openOutboundOrders, offset, refetchOrders]);

    const [downloadLoading, setDownloadLoading] = useState(false);
    const handleDownload = async () => {
        setDownloadLoading(true);
        const success = await downloadShipmentPdf(shipment?.shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf", "", "error");
        }
        setDownloadLoading(false);
    };

    const [openLabelModal, setOpenLabelModal] = useState(false);
    const [selectedViewingLabel, setSelectedViewingLabel] =
        useState<Label | null>(null);
    const [viewingLabelLoading, setViewingLabelLoading] = useState(false);
    const handleViewShipmentPdf = async () => {
        if (!selectedViewingLabel) {
            setViewingLabelLoading(true);
            const pdfUrl = await getShipmentPdfUrl(shipment?.shipment_id);
            if (pdfUrl) {
                setSelectedViewingLabel({
                    label_id: "",
                    public_url: pdfUrl,
                    name: "",
                    used_pages: [],
                });
            }
            setViewingLabelLoading(false);
        } else {
            setOpenLabelModal(true);
        }
    };

    useEffect(() => {
        if (selectedViewingLabel) {
            setOpenLabelModal(true);
        }
    }, [selectedViewingLabel]);

    const [unrequestLoading, setUnrequestLoading] = useState(false);
    const handleUnrequestShipment = async () => {
        if (shipment?.admin_status === "pending") {
            showNotification(
                "Can not unrequest this shipment",
                "The team is already working on your shipment.",
                "error"
            );
            return;
        }
        setUnrequestLoading(true);
        const data = await unrequestOutboundShipment(
            shipment?.shipment_id || ""
        );
        if (data.status === "success") {
            showNotification(
                "Successfully unrequested shipment",
                "",
                "success"
            );
            setOpenOutboundOrders(false);
            if (handleRefetch) handleRefetch();
        } else {
            showNotification(
                "Failed to unrequested shipment",
                data.message,
                "error"
            );
        }
        setUnrequestLoading(false);
    };

    return (
        <Dialog
            open={openOutboundOrders}
            onClose={() => setOpenOutboundOrders(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenOutboundOrders(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Shipment:{" "}
                            {shipment?.name !== ""
                                ? shipment?.name
                                : shipment?.shipment_id}
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto">
                            {!fullLoading ? (
                                <div>
                                    <dl className="grid grid-cols-1 sm:grid-cols-4 pb-4">
                                        {(shipment?.status === "shipped"
                                            ? shipmentInfo
                                            : requestedShipmentInfo
                                        ).map((info) => (
                                            <div
                                                key={info.label}
                                                className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0"
                                            >
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    {info.label}
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                                    {info.value}
                                                </dd>
                                            </div>
                                        ))}
                                        <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                                Shipping supplies
                                            </dt>
                                            {shipment &&
                                                shipment.boxes &&
                                                shipment.boxes.map((box) => (
                                                    <dd className="mt-0.5 text-sm leading-6 text-gray-700 sm:mt-0.5">
                                                        {box.name}
                                                        {": "}
                                                        {box.quantity}
                                                    </dd>
                                                ))}
                                        </div>
                                        <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                                Extra Services
                                            </dt>
                                            {shipment &&
                                                shipment.services &&
                                                shipment.services.map(
                                                    (service) => (
                                                        <dd className="mt-0.5 text-sm leading-6 text-gray-700 sm:mt-0.5">
                                                            {service.label}:{" "}
                                                            {service.quantity}
                                                        </dd>
                                                    )
                                                )}
                                        </div>
                                        {shipment?.status === "requested" && (
                                            <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Attached labels
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 sm:mt-1 text-accent cursor-pointer">
                                                    <div className="flex flex-row space-x-4">
                                                        {downloadLoading ? (
                                                            <LoadingWheel size="small" />
                                                        ) : (
                                                            <span
                                                                onClick={() =>
                                                                    handleDownload()
                                                                }
                                                                className="text-base font-medium hover:text-accenthighlight"
                                                            >
                                                                Print
                                                            </span>
                                                        )}
                                                        {viewingLabelLoading ? (
                                                            <LoadingWheel size="small" />
                                                        ) : (
                                                            <span
                                                                onClick={() =>
                                                                    handleViewShipmentPdf()
                                                                }
                                                                className="text-base font-medium hover:text-accenthighlight"
                                                            >
                                                                View
                                                            </span>
                                                        )}
                                                    </div>
                                                </dd>
                                            </div>
                                        )}
                                    </dl>
                                    <div className="font-semibold text-base text-gray-900 pb-1">
                                        Shipment contents
                                    </div>
                                    {showSearchBar && (
                                        <ClientSearchBar
                                            setData={setOutboundOrders}
                                            setLoading={setLoading}
                                            handleRefetch={handleRefetchOrders}
                                            page="outbound"
                                            sub_page={
                                                shipment?.shipment_id || ""
                                            }
                                            keywords={keywords}
                                            setKeywords={setKeywords}
                                        />
                                    )}
                                    {!loading ? (
                                        <>
                                            <ClientOutboundTable
                                                outboundOrders={outboundOrders}
                                                results={results}
                                                offset={offset}
                                                setOffset={setOffset}
                                            />

                                            <div className="py-2">
                                                <div className="h-px w-full bg-gray-300"></div>
                                            </div>

                                            {shipmentTrackings &&
                                            shipmentTrackings.length > 0 ? (
                                                <ShipmentTrackingsTable
                                                    trackings={
                                                        shipmentTrackings
                                                    }
                                                />
                                            ) : (
                                                <span className="text-sm text-gray-600">
                                                    No trackings on this
                                                    shipment
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <div className="flex flex-grow items-center pt-44 justify-center">
                                            <LoadingWheel />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>
                        {shipment?.status === "requested" &&
                            shipment.admin_status !== "shipped" && (
                                <div className="pt-4 sm:pt-0 sm:flex-row-reverse justify-between flex flex-row-reverse">
                                    <button
                                        onClick={() =>
                                            handleUnrequestShipment()
                                        }
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:mt-0 sm:w-auto items-center"
                                    >
                                        {unrequestLoading ? (
                                            <span className="pr-2 items-center flex flex-row justify-center">
                                                <LoadingWheel
                                                    size="small"
                                                    color="white"
                                                />
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        Unrequest shipment
                                    </button>
                                </div>
                            )}
                    </DialogPanel>
                </div>
            </div>
            <LabelModal
                selectedViewingLabel={selectedViewingLabel}
                openLabelModal={openLabelModal}
                setOpenLabelModal={setOpenLabelModal}
                selectedPages={[]}
                pdfLabel={true}
            />
        </Dialog>
    );
}
