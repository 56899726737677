/** @format */
/** @format */
import { useState, useEffect } from "react";
import LoadingWheel from "../../../components/loadingWheel";
import { loginRequest } from "../api/authenticationApi";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../../../utils/auth/verifyAuthToken";
import { setAdminType } from "../../../utils/adminTypes/actions";
import Cookies from "js-cookie";
import { setClientType } from "../../../utils/clientTypes/actions";
import { useClient } from "../../../utils/client/clientContext";

export default function LoginPage() {
    const navigate = useNavigate();

    const {
        client,
        setClient,
        clientLoading,
        user_id,
        setUserId,
        handleOrderCountRefetch,
    } = useClient();

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [failed, setFailed] = useState(false);

    useEffect(() => {
        const verifyAuth = async () => {
            const authData = await checkAuth();
            if (authData.authenticated) {
                if (
                    authData.clientType === "client" ||
                    authData.clientType === "client_team_member"
                ) {
                    navigate("/");
                } else {
                    navigate("/admin/clients");
                }
            }
        };

        verifyAuth();
    }, []);

    const handleLogin = async () => {
        setLoading(true);
        const data = await loginRequest(email, password, "admin");
        if (data.status === "success") {
            setLoading(false);
            Cookies.set("jwt", data.data.jwt_token, {
                expires: 30,
                secure: true,
            });

            setUserId("");

            if (
                data.data.client_type === "admin" ||
                data.data.client_type === "employee"
            ) {
                setAdminType(data.data.admin_type);
                navigate("/admin/clients");
                handleOrderCountRefetch();
            } else {
                setClientType(data.data.client_type);
                navigate("/");
            }
        } else {
            setFailed(true);
        }
        setLoading(false);
    };

    return (
        <div className="bg-gray-50 h-screen">
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 pt-12 pb-8 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                alt="Your Company"
                                src={process.env.REACT_APP_LOGIN_LOGO_URL}
                                className="mx-auto h-auto w-28"
                            />
                            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Sign in to your account
                            </h2>
                        </div>
                        <div className="space-y-6 mt-10">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex flex-row justify-between">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password
                                    </label>
                                    <a
                                        href="/forgot-password"
                                        className="font-semibold text-accent hover:text-accenthighlight text-sm"
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                                <div className="mt-2 pb-2">
                                    <input
                                        disabled={loading}
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        autoComplete="current-password"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            {failed ? (
                                <span className="text-red-500 text-sm">
                                    Email or password is incorrect.
                                </span>
                            ) : (
                                <></>
                            )}

                            <div>
                                <button
                                    disabled={email.length < 1 || loading}
                                    onClick={() => handleLogin()}
                                    type="submit"
                                    className={`${
                                        email.length >= 1 && !loading
                                            ? "bg-accent hover:bg-accenthighlight cursor-pointer transition"
                                            : email.length >= 1 && loading
                                            ? "bg-accent cursor-default transition"
                                            : "bg-accentdim cursor-default transition"
                                    } items-center flex w-full justify-center rounded-md px-3 h-9 text-sm font-semibold leading-6 text-white shadow-sm `}
                                >
                                    {loading ? (
                                        <LoadingWheel color="white" />
                                    ) : (
                                        <span>Sign in</span>
                                    )}
                                </button>
                                <div className="flex flex-row items-center justify-center pt-4 space-x-2">
                                    <p className="text-sm text-gray-800 ">
                                        Powered by Urva
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{" "}
                        <a
                            href="/signup"
                            className="font-semibold leading-6 text-accent hover:text-accenthighlight"
                        >
                            Create your account
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
