/** @format */

import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cleanTrackingNumber } from "../utils/carriers/cleanTrackingNumber";
import { TrackingInfo } from "../models/trackings";

interface MultiTrackingInputProps {
    trackings: TrackingInfo[];
    setTrackings: React.Dispatch<React.SetStateAction<TrackingInfo[]>>;
    currentInput: string;
    setCurrentInput: React.Dispatch<React.SetStateAction<string>>;
}

const MultiTrackingInput: React.FC<MultiTrackingInputProps> = ({
    trackings,
    setTrackings,
    currentInput,
    setCurrentInput,
}) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();
            const [trackingNumber, carrier] = cleanTrackingNumber(
                currentInput.trim()
            );
            setTrackings((prev) => [
                ...prev,
                {
                    tracking_id: "",
                    date: "",
                    tracking: trackingNumber,
                    tracking_url: "",
                    carrier: carrier,
                },
            ]);
            setCurrentInput("");
        }
    };

    const removeTrackingNumber = (index: number) => {
        setTrackings((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <div className="space-y-0.5">
            <div className="flex flex-row justify-between">
                <span className="font-medium text-sm">Tracking</span>
                <span className="text-sm text-gray-500">Optional</span>
            </div>
            <input
                type="text"
                id="tracking"
                value={currentInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                placeholder="Press enter to add"
            />
            <div className="pt-0.5"></div>
            {trackings && trackings.length > 0 && (
                <div className="mt-4 flex flex-wrap gap-2">
                    {trackings.map((tracking, index) => (
                        <div
                            key={index}
                            className="flex items-center bg-gray-100 p-1.5 rounded-md"
                        >
                            <span className="text-xs mr-2">
                                {tracking.tracking}
                                {" - "}
                                {tracking.carrier}
                            </span>
                            <button
                                onClick={() => removeTrackingNumber(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                <XMarkIcon className="w-4 h-4" />
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiTrackingInput;
