/** @format */

import Header from "../header";
import AdminSideBar from "./sideBar";

interface AdminDashboardLayoutProps {
    user_id: string | null;
    orderCount: any; // Adjust type accordingly
    children: React.ReactNode;
}

const AdminDashboardLayout: React.FC<AdminDashboardLayoutProps> = ({
    user_id,
    orderCount,
    children,
}) => {
    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />
                {children}
            </div>
        </div>
    );
};

export default AdminDashboardLayout;
