/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useNotification } from "../../../../utils/notification/notificationContext";
import MediumSelector from "../../../../components/dropdowns/mediumSelector";
import SingleCalendar from "../../../../components/singleCalendar";
import { ActionButton } from "../../../../components/buttons";
import TimeSelector from "../../../../components/dropdowns/timeSelector";
import dayjs, { Dayjs } from "dayjs";
import { createScheduleEvent, fetchEmployees } from "../api/employeesApi";
import { Employee } from "../../../../models/employees";
import { InputField } from "../../../../components/inputField";
import isoWeek from "dayjs/plugin/isoWeek";
import WeekCalendar from "./weekCalendar";

dayjs.extend(isoWeek);

function AddScheduleEventModal({
    openAddScheduleEventModal,
    setOpenAddScheduleEventModal,
    weekToSelect,
    handleRefetch,
}: {
    openAddScheduleEventModal: boolean;
    setOpenAddScheduleEventModal: React.Dispatch<React.SetStateAction<boolean>>;
    weekToSelect: Dayjs[];
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState<boolean>(false);

    const [timeSlots, setTimeSlots] = useState<{
        [key: string]: {
            startHour?: string;
            startMinute?: string;
            startPeriod?: string;
            endHour: string | null;
            endMinute: string | null;
            endPeriod: string | null;
            description: string;
        };
    }>({});

    const handleAddTimeSlot = (day: string) => {
        if (!timeSlots[day]) {
            const previousDay = timeSlots[weekToSelect[0].format("YYYY-MM-DD")];
            let startHour: undefined | string = undefined;
            let startMinute: undefined | string = undefined;
            let startPeriod: undefined | string = undefined;

            let endHour: undefined | string = undefined;
            let endMinute: undefined | string = undefined;
            let endPeriod: undefined | string = undefined;
            if (previousDay) {
                startHour = previousDay.startHour;
                startMinute = previousDay.startMinute;
                startPeriod = previousDay.startPeriod;
                endHour = previousDay.endHour || "";
                endMinute = previousDay.endMinute || "";
                endPeriod = previousDay.endPeriod || "";
            }

            setTimeSlots((prev) => ({
                ...prev,
                [day]: {
                    startHour: startHour ? startHour : undefined,
                    startMinute: startMinute ? startMinute : undefined,
                    startPeriod: startPeriod ? startPeriod : undefined,
                    endHour: endHour || "",
                    endMinute: endMinute || "",
                    endPeriod: endPeriod || "",
                    description: "",
                },
            }));
        }
    };

    const handleTimeChange = (day: string, field: string, value: any) => {
        setTimeSlots((prev) => ({
            ...prev,
            [day]: {
                ...prev[day], // Spread existing object
                [field]: value, // Update specific field
            },
        }));
    };

    const handleToggleEndTime = (day: string) => {
        setTimeSlots((prev) => {
            const currentSlot = prev[day];

            if (!currentSlot) return prev;

            return {
                ...prev,
                [day]: {
                    ...currentSlot,
                    ...(currentSlot.endHour === null
                        ? {
                              // Set end time to current time
                              endHour: String(new Date().getHours() % 12 || 12),
                              endMinute: String(new Date().getMinutes()),
                              endPeriod:
                                  new Date().getHours() >= 12 ? "PM" : "AM",
                          }
                        : {
                              // Clear end time
                              endHour: null,
                              endMinute: null,
                              endPeriod: null,
                          }),
                },
            };
        });
    };

    const handleRemoveTimeSlot = (day: string) => {
        setTimeSlots((prev) => {
            const updatedSlots = { ...prev };
            delete updatedSlots[day]; // Remove the time slot for the specified day
            return updatedSlots;
        });
    };

    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<{
        id: string;
        name: string;
        value: string;
    }>({
        id: "0",
        name: "Select an employee",
        value: "",
    });

    const selectorData = [
        ...employees
            .filter((employee) => !employee.hidden) // Exclude hidden employees
            .map((employee) => ({
                id: employee.employee_id,
                name: employee.name,
                value: employee.email,
            })),
    ];

    const fetchEmployeesData = async () => {
        const data = await fetchEmployees();
        if (data.status === "success") {
            setEmployees(data.data.employees);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
    };

    useEffect(() => {
        if (openAddScheduleEventModal && employees.length < 1) {
            fetchEmployeesData();
        }
        if (!openAddScheduleEventModal) {
            setTimeSlots({});
        }
        if (openAddScheduleEventModal) {
            setSelectedWeek(weekToSelect);
        }
    }, [openAddScheduleEventModal]);

    const handleCreate = async () => {
        setLoading(true);
        const data = await createScheduleEvent(selectedEmployee.id, timeSlots);
        if (data.status === "success") {
            showNotification("Created new schedule event.", "", "success");
            setOpenAddScheduleEventModal(false);

            setSelectedEmployee({
                id: "0",
                name: "Select an employee",
                value: "",
            });
            setTimeSlots({});
            handleRefetch();
        } else {
            showNotification(
                "Failed to create new event",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    const [selectedWeek, setSelectedWeek] = useState<Dayjs[]>([]); // use this variable for

    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    useEffect(() => {
        const startOfWeek = getStartOfWeekMonday(dayjs());
        updateSelectedWeek(startOfWeek);
    }, []);

    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    const updateSelectedWeek = (startOfWeek: Dayjs) => {
        const weekDays: Dayjs[] = [];
        let day = startOfWeek;
        for (let i = 0; i < 7; i++) {
            weekDays.push(day);
            day = day.add(1, "day");
        }
        setSelectedWeek(weekDays);
        setCurrentDate(startOfWeek); // Update current date to reflect selected week
    };

    return (
        <Dialog
            open={openAddScheduleEventModal}
            onClose={() => setOpenAddScheduleEventModal(false)}
            className="relative z-30"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-30 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenAddScheduleEventModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-3"
                        >
                            Add schedule event
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <>
                                <div className="grid sm:grid-cols-1 grid-cols-1 gap-x-10 gap-y-5">
                                    <div className="flex flex-row items-center space-x-4">
                                        <div className="w-full">
                                            <h3 className="text-sm font-medium pb-1">
                                                Employee
                                            </h3>
                                            <MediumSelector
                                                selected={selectedEmployee}
                                                setSelected={
                                                    setSelectedEmployee
                                                }
                                                selectorData={selectorData}
                                                maxHeight="max-h-max"
                                            />
                                        </div>
                                        <div>
                                            <h3 className="text-sm font-medium pb-1 max-h-96">
                                                Date
                                            </h3>
                                            <WeekCalendar
                                                selectedWeek={selectedWeek}
                                                setSelectedWeek={
                                                    setSelectedWeek
                                                }
                                                currentDate={currentDate}
                                                setCurrentDate={setCurrentDate}
                                                isCalendarVisible={
                                                    isCalendarVisible
                                                }
                                                setIsCalendarVisible={
                                                    setIsCalendarVisible
                                                }
                                                includeBorder={true}
                                            />
                                        </div>
                                    </div>
                                    {selectedWeek.map((day, index) => {
                                        const dayKey = day.format("YYYY-MM-DD");
                                        const timeSlot = timeSlots[dayKey];

                                        return (
                                            <div
                                                key={index}
                                                className="mb-4 border-b pb-4"
                                            >
                                                <h3 className="text-sm font-medium pb-1">
                                                    {day.format("dddd, MMM D")}
                                                </h3>

                                                {!timeSlot ? (
                                                    <button
                                                        onClick={() =>
                                                            handleAddTimeSlot(
                                                                dayKey
                                                            )
                                                        }
                                                        className="bg-accent text-white px-3 py-1 rounded text-sm hover:bg-accenthighlight"
                                                    >
                                                        + Add Time Slot
                                                    </button>
                                                ) : (
                                                    <div className="grid grid-cols-1 sm:grid-cols-2 pb-2 mt-2 border p-2 rounded-md bg-gray-100">
                                                        <div>
                                                            <h3 className="text-sm font-medium pb-1">
                                                                Start time
                                                            </h3>
                                                            <TimeSelector
                                                                selectedHour={
                                                                    timeSlot.startHour ||
                                                                    ""
                                                                }
                                                                setSelectedHour={(
                                                                    value
                                                                ) =>
                                                                    handleTimeChange(
                                                                        dayKey,
                                                                        "startHour",
                                                                        value
                                                                    )
                                                                }
                                                                selectedMinute={
                                                                    timeSlot.startMinute ||
                                                                    ""
                                                                }
                                                                setSelectedMinute={(
                                                                    value
                                                                ) =>
                                                                    handleTimeChange(
                                                                        dayKey,
                                                                        "startMinute",
                                                                        value
                                                                    )
                                                                }
                                                                selectedPeriod={
                                                                    timeSlot.startPeriod ||
                                                                    ""
                                                                }
                                                                setSelectedPeriod={(
                                                                    value
                                                                ) =>
                                                                    handleTimeChange(
                                                                        dayKey,
                                                                        "startPeriod",
                                                                        value
                                                                    )
                                                                }
                                                                flipContent={
                                                                    index === 6
                                                                }
                                                                initializeTime={
                                                                    index === 0
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="flex flex-row items-center space-x-2 pb-1">
                                                                <h3 className="text-sm font-medium">
                                                                    End time
                                                                </h3>
                                                                <button
                                                                    onClick={() =>
                                                                        handleToggleEndTime(
                                                                            dayKey
                                                                        )
                                                                    }
                                                                    className="text-accent cursor-pointer hover:text-accenthighlight text-sm"
                                                                >
                                                                    {timeSlot.endHour ===
                                                                    null
                                                                        ? "Add end time"
                                                                        : "No end time"}
                                                                </button>
                                                            </div>
                                                            <div className="relative">
                                                                {timeSlot.endHour !==
                                                                null ? (
                                                                    <TimeSelector
                                                                        selectedHour={
                                                                            timeSlot.endHour
                                                                        }
                                                                        setSelectedHour={(
                                                                            value
                                                                        ) =>
                                                                            handleTimeChange(
                                                                                dayKey,
                                                                                "endHour",
                                                                                value
                                                                            )
                                                                        }
                                                                        selectedMinute={
                                                                            timeSlot.endMinute ||
                                                                            ""
                                                                        }
                                                                        setSelectedMinute={(
                                                                            value
                                                                        ) =>
                                                                            handleTimeChange(
                                                                                dayKey,
                                                                                "endMinute",
                                                                                value
                                                                            )
                                                                        }
                                                                        selectedPeriod={
                                                                            timeSlot.endPeriod ||
                                                                            ""
                                                                        }
                                                                        setSelectedPeriod={(
                                                                            value
                                                                        ) =>
                                                                            handleTimeChange(
                                                                                dayKey,
                                                                                "endPeriod",
                                                                                value
                                                                            )
                                                                        }
                                                                        flipContent={
                                                                            index ===
                                                                            6
                                                                        }
                                                                        initializeTime={
                                                                            index ===
                                                                            0
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div className="absolute inset-0 z-20 h-full flex items-center justify-center">
                                                                        <div className="z-20 absolute inset-0 bg-gray-200 bg-opacity-50 rounded-md"></div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-span-2 pt-2">
                                                            <InputField
                                                                label="Description"
                                                                value={
                                                                    timeSlot.description
                                                                }
                                                                onChange={(e) =>
                                                                    handleTimeChange(
                                                                        dayKey,
                                                                        "description",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        {/* Remove Time Slot Button */}
                                                        <div className="col-span-2 flex justify-end pt-3">
                                                            <button
                                                                onClick={() =>
                                                                    handleRemoveTimeSlot(
                                                                        dayKey
                                                                    )
                                                                }
                                                                className="bg-red-500 text-white px-3 py-1 rounded text-sm hover:bg-red-600"
                                                            >
                                                                Remove Time Slot
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                            <div className="h-4 w-full"></div>
                        </div>
                        <div className="flex flex-row-reverse">
                            <ActionButton
                                label="Add events"
                                handleClick={() => handleCreate()}
                                height={9}
                                loading={loading}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default AddScheduleEventModal;
