/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import SingleCalendar, { SingleDayCalendar } from "../singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { ActionButton } from "../buttons";

const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

export interface CalendarModalProps {
    openCalendarModal: boolean;
    setOpenCalendarModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedDate: Dayjs;
    setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    handleSaveDate: (
        setSaveLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => Promise<void>;
}

function CalendarModal({
    openCalendarModal,
    setOpenCalendarModal,
    selectedDate,
    setSelectedDate,
    handleSaveDate,
}: CalendarModalProps) {
    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());

    const today = dayjs();

    const allowFutureDates = true;
    const isNextMonthDisabled =
        !allowFutureDates &&
        currentDate.add(1, "month").isAfter(today, "month");

    const startOfMonth = currentDate.startOf("month");
    const endOfMonth = currentDate.endOf("month");

    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    // Adjust the start and end of the calendar based on the week starting on Monday
    const startDate = getStartOfWeekMonday(startOfMonth);
    const endDate = getStartOfWeekMonday(endOfMonth).add(6, "days");

    const generateCalendarDays = () => {
        const days: Dayjs[] = [];
        let day = startDate;

        while (day.isBefore(endDate, "day") || day.isSame(endDate, "day")) {
            days.push(day);
            day = day.add(1, "day");
        }

        return days;
    };

    const handleDateClick = (date: Dayjs) => {
        if (!allowFutureDates && date.isAfter(today, "day")) return; // Block future dates if allowFutureDates is false
        setSelectedDate(date);
    };

    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            open={openCalendarModal}
            onClose={() => setOpenCalendarModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/6 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-md sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenCalendarModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>
                        <div className="flex-1 overflow-y-auto pt-4">
                            <div className="w-full mx-auto transition-all duration-100 ease-in-out z-30">
                                <div className="p-4">
                                    <div className="flex items-center justify-between mb-8">
                                        <button
                                            onClick={() =>
                                                setCurrentDate(
                                                    currentDate.subtract(
                                                        1,
                                                        "month"
                                                    )
                                                )
                                            }
                                            className="text-gray-600 hover:text-gray-800"
                                        >
                                            <GoChevronLeft className="h-5 w-5" />
                                        </button>
                                        <span className="text-sm font-semibold">
                                            {currentDate.format("MMMM YYYY")}
                                        </span>
                                        <button
                                            onClick={() =>
                                                !isNextMonthDisabled &&
                                                setCurrentDate(
                                                    currentDate.add(1, "month")
                                                )
                                            }
                                            className={`text-gray-600 hover:text-gray-800 ${
                                                isNextMonthDisabled
                                                    ? "cursor-not-allowed opacity-50"
                                                    : ""
                                            }`}
                                            disabled={isNextMonthDisabled}
                                        >
                                            <GoChevronRight className="h-5 w-5" />
                                        </button>
                                    </div>
                                    <div className="grid grid-cols-7 text-center mb-3">
                                        {daysOfWeek.map((day, index) => (
                                            <div
                                                key={index}
                                                className="text-xs text-gray-500"
                                            >
                                                {day}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="border border-gray-200 rounded-xl overflow-hidden">
                                        <div className="grid grid-cols-7 gap-px bg-gray-200 cursor-pointer">
                                            {generateCalendarDays().map(
                                                (day) => {
                                                    const isToday = day.isSame(
                                                        today,
                                                        "day"
                                                    );
                                                    const isSelected =
                                                        selectedDate &&
                                                        day.isSame(
                                                            selectedDate,
                                                            "day"
                                                        );
                                                    const isCurrentMonth =
                                                        day.month() ===
                                                        currentDate.month();
                                                    const isFuture =
                                                        day.isAfter(
                                                            today,
                                                            "day"
                                                        );

                                                    let dayBackgroundClass =
                                                        "bg-white hover:bg-gray-100";
                                                    let dayTextClass =
                                                        "text-gray-800";

                                                    if (!isCurrentMonth) {
                                                        dayBackgroundClass =
                                                            "bg-gray-50 hover:bg-gray-100";
                                                        dayTextClass =
                                                            "text-gray-400";
                                                    }
                                                    if (
                                                        !allowFutureDates &&
                                                        isFuture
                                                    ) {
                                                        dayBackgroundClass =
                                                            "bg-gray-50 cursor-not-allowed";
                                                        dayTextClass =
                                                            "text-gray-400";
                                                    }
                                                    if (isSelected) {
                                                        dayBackgroundClass =
                                                            "bg-accent text-white";
                                                        dayTextClass =
                                                            "text-white";
                                                    } else if (isToday) {
                                                        dayTextClass =
                                                            "text-accent";
                                                    }

                                                    return (
                                                        <div
                                                            key={day.format(
                                                                "YYYY-MM-DD"
                                                            )}
                                                            onClick={() =>
                                                                (allowFutureDates ||
                                                                    !isFuture) &&
                                                                handleDateClick(
                                                                    day
                                                                )
                                                            }
                                                            className={`relative text-xs flex items-center justify-center h-8 ${dayBackgroundClass}`}
                                                        >
                                                            <span
                                                                className={
                                                                    dayTextClass
                                                                }
                                                            >
                                                                {day.date()}
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                            <ActionButton
                                label="Save"
                                handleClick={() => {
                                    handleSaveDate(setLoading);
                                }}
                                height={9}
                                loading={loading}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default CalendarModal;
