/** @format */

import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
} from "@heroicons/react/24/outline";

const features = [
    {
        name: "Streamlined Cash Flow & Consistent Purchase Orders",
        description:
            "We provide a reliable revenue stream by purchasing inventory from you consistently every month. As our partnership grows, we continually increase purchase orders, ensuring steady cash flow for your business.",
        icon: CloudArrowUpIcon,
    },
    {
        name: "Professional Listing Renovation",
        description:
            "We enhance your existing product listings with professional photography, videography, and optimized content to boost conversion rates. Our expert team ensures your listings stand out, increasing brand credibility and driving more sales",
        icon: LockClosedIcon,
    },
    {
        name: "Protection Against Unauthorized Sellers",
        description:
            "We prevent unauthorized third-party sellers from listing and undercutting your products, helping you maintain price integrity and brand reputation. By working with us as an authorized reseller, you ensure a consistent, high-quality customer experience.",
        icon: ArrowPathIcon,
    },
    {
        name: "Strategic Listing Creation & Bundling",
        description:
            "We create optimized multi-pack and bundle listings that help increase your sales and give you a competitive edge. These strategies improve search rankings, attract more customers, and maximize your sales on eCommerce platforms.",
        icon: FingerPrintIcon,
    },
];

export default function ServicesSection() {
    return (
        <div id="services-section" className="bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl lg:text-center">
                    <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
                        eCommerce Order Fulfillment Services
                    </p>
                    <p className="mt-6 text-lg/8 text-gray-600">
                        Our eCommerce order fulfillment services let you focus
                        on scaling your business while we take care of the
                        logistics. From seamless inventory management to fast,
                        reliable shipping, we ensure your operations run
                        smoothly and efficiently.
                    </p>
                </div>
                {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-6xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div
                                key={feature.name}
                                className="relative pl-20 bg-gray-100 rounded-md p-6"
                            >
                                <dt className="text-base/7 font-semibold text-gray-900">
                                    <div className="absolute left-4 top-7 flex size-10 items-center justify-center rounded-lg bg-accent">
                                        <feature.icon
                                            aria-hidden="true"
                                            className="size-6 text-white"
                                        />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base/7 text-gray-600">
                                    {feature.description}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div> */}
            </div>
        </div>
    );
}
