/** @format */

import { useState } from "react";
import ModalLayout from "../../../../../components/modalLayout";
import { ActionButton } from "../../../../../components/buttons";
import MediumSelector from "../../../../../components/dropdowns/mediumSelector";
import {
    InputNumberField,
    InputTextField,
} from "../../../../../components/inputField";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { createEmployeeError } from "../../api/kpisApi";

interface AddEmployeeErrorModalProps {
    openAddEmployeeErrorModal: boolean;
    setOpenAddEmployeeErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
    employeeDetails: {
        employee_name: string;
        employee_id: string;
    } | null;
    handleRefetch: () => void;
}

function AddEmployeeErrorModal({
    openAddEmployeeErrorModal,
    setOpenAddEmployeeErrorModal,
    employeeDetails,
    handleRefetch,
}: AddEmployeeErrorModalProps) {
    const { showNotification } = useNotification();
    const actionTypes = [
        { id: "1", name: "Parcel Forwarding", value: "parcel-forwarding" },
        { id: "2", name: "FBA", value: "fba" },
        { id: "3", name: "Merchant", value: "merchant" },
        { id: "4", name: "Receiving", value: "receiving" },
    ];
    const [selectedAction, setSelectionAction] = useState<{
        id: string;
        name: string;
        value: string;
    }>({ id: "-1", name: "Choose an action", value: "" });
    const [quantity, setQuantity] = useState("");
    const [notes, setNotes] = useState("");

    const [loading, setLoading] = useState(false);
    const handleCreate = async () => {
        setLoading(true);
        const data = await createEmployeeError(
            employeeDetails?.employee_id || "",
            selectedAction.value,
            quantity,
            notes
        );
        if (data.status === "success") {
            setOpenAddEmployeeErrorModal(false);
            showNotification(data.message, "", "success");
            setSelectionAction({
                id: "-1",
                name: "Choose an action",
                value: "",
            });
            setQuantity("");
            setNotes("");
            handleRefetch();
        } else {
            showNotification(data.message, data.errors[0], "error");
        }
        setLoading(false);
    };
    return (
        <ModalLayout
            title="Add Employee Error"
            height="h-3/5"
            width="sm:max-w-xl"
            openModal={openAddEmployeeErrorModal}
            setOpenModal={setOpenAddEmployeeErrorModal}
        >
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                <div className="flex flex-row w-full space-x-5">
                    <div className="flex flex-col w-full">
                        <h4 className="text-sm font-medium text pb-0.5">
                            Action Type
                        </h4>
                        <MediumSelector
                            selectorData={actionTypes}
                            selected={selectedAction}
                            setSelected={setSelectionAction}
                        />
                    </div>
                    <InputNumberField
                        label="Quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <InputTextField
                    label="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
            <div className="flex flex-row-reverse">
                <ActionButton
                    label="Add error"
                    handleClick={() => handleCreate()}
                    height={9}
                    loading={loading}
                />
            </div>
        </ModalLayout>
    );
}

export default AddEmployeeErrorModal;
