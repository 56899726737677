/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

interface ModalLayoutProps {
    title: string;
    height: string;
    width: string;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}

const ModalLayout: React.FC<ModalLayoutProps> = ({
    title,
    height,
    width,
    openModal,
    setOpenModal,
    children,
}) => {
    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            className="relative z-30"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-30 w-screen">
                <div
                    className={`${height} flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0`}
                >
                    <DialogPanel
                        transition
                        className={`${height} ${width} w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-3"
                        >
                            {title}
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        {children}

                        {/* <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <div className="h-4 w-full"></div>
                        </div>
                        <div className="flex flex-row-reverse"></div> */}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalLayout;
