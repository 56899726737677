/** @format */

import { useEffect, useState } from "react";
import AdminDashboardLayout from "../../../../components/admin/dashboardLayout";
import { useClient } from "../../../../utils/client/clientContext";
import { AssignedTask } from "../../../../models/adminDashboard";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import { ActionButton } from "../../../../components/buttons";
import { fetchWorklist, markAssignedTaskDone } from "../api/worklistApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import AssignTaskModal from "../assignTaskModal";
import LoadingWheel from "../../../../components/loadingWheel";

function AdminAssignedTasksPage() {
    const { user_id, orderCount } = useClient();
    const { showNotification } = useNotification();

    const [assignedTasks, setAssignedTasks] = useState<AssignedTask[]>([]);
    const [openAssignTaskModal, setOpenAssignTaskModal] = useState(false);
    const [tasksDoneLoadings, setTasksDoneLoadings] = useState<string[]>([]);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const handleAssignedTaskDone = async (task_id: string) => {
        setTasksDoneLoadings((prev) => [...prev, task_id]);
        const data = await markAssignedTaskDone(task_id);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Marked assigned task as done", "", "success");
        } else {
            showNotification(
                "Failed to mark assigned task as done",
                "",
                "error"
            );
        }
        setTasksDoneLoadings((prev) => prev.filter((id) => id !== task_id));
    };

    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        const data = await fetchWorklist("assigned_tasks", true);
        if (data.status === "success") {
            setAssignedTasks(data.data.assigned_tasks.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    return (
        <AdminDashboardLayout user_id={user_id} orderCount={orderCount}>
            <div className="flex flex-row items-center justify-between">
                <span className="text-2xl font-bold">Assigned Tasks</span>
            </div>

            <div>
                <div className="flex flex-row items-center justify-between pt-3">
                    <span
                        onClick={() => setOpenAssignTaskModal(true)}
                        className="text-sm text-accent cursor-pointer inline-flex items-center gap-x-1 hover:text-accenthighlight"
                    >
                        <PlusCircleIcon className="h-4 w-4" />
                        Assign Task
                    </span>
                </div>
                {!loading ? (
                    <div className="grid grid-cols-1 w-full gap-y-2 pt-2">
                        {assignedTasks && assignedTasks.length < 1 && (
                            <p className="text-sm text-gray-600">
                                No assigned admin tasks
                            </p>
                        )}
                        {assignedTasks &&
                            assignedTasks.map((action) => (
                                <div key={action.task_id}>
                                    <div className="flex flex-row items-center justify-between hover:bg-gray-100 py-1 rounded-md cursor-pointer">
                                        <div className="flex flex-col">
                                            <h4 className="font-medium text-gray-700">
                                                {capitalizeFirstLetter(
                                                    action.task
                                                )}{" "}
                                            </h4>
                                            <p className="text-gray-600 text-sm">
                                                By {action.employee_name}
                                            </p>
                                        </div>
                                        <ActionButton
                                            label="Done"
                                            handleClick={(e: any) => {
                                                e.stopPropagation();
                                                handleAssignedTaskDone(
                                                    action.task_id
                                                );
                                            }}
                                            loading={tasksDoneLoadings.includes(
                                                action.task_id
                                            )}
                                        />
                                    </div>
                                    <div className="pt-2">
                                        <div className="w-full h-px bg-gray-300"></div>
                                    </div>
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="flex flex-grow items-center pt-44 justify-center">
                        <LoadingWheel />
                    </div>
                )}
            </div>

            <AssignTaskModal
                openAssignTaskModal={openAssignTaskModal}
                setOpenAssignTaskModal={setOpenAssignTaskModal}
                handleRefetch={handleRefetch}
            />
        </AdminDashboardLayout>
    );
}

export default AdminAssignedTasksPage;
