/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { ActionButton, DeleteButton } from "../../../../components/buttons";
import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { ScheduleEvent } from "./page";
import { deleteScheduleEvent, editScheduleEvent } from "../api/employeesApi";
import { getAdminType } from "../../../../utils/adminTypes/actions";
import SingleCalendar from "../../../../components/singleCalendar";
import TimeSelector from "../../../../components/dropdowns/timeSelector";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { convertToDayjsFromString } from "../../../../utils/date/date_formatter";

dayjs.extend(isoWeek);

function EditScheduleEventModal({
    openEditScheduleEventModal,
    setOpenEditScheduleEventModal,
    event,
    handleRefetch,
}: {
    openEditScheduleEventModal: boolean;
    setOpenEditScheduleEventModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    event: ScheduleEvent | null;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const isOwner = getAdminType() === "owner";

    const [loading, setLoading] = useState<boolean>(false);

    const handleDelete = async () => {
        if (event?.event_id) {
            setLoading(true);
            const data = await deleteScheduleEvent(event.event_id);
            if (data.status === "success") {
                showNotification("Deleted schedule event.", "", "success");
                setOpenEditScheduleEventModal(false);
                handleRefetch();
            } else {
                showNotification(
                    "Failed to delete schedule event.",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
        }
    };

    const [selectedDate, setSelectedDate] = useState(dayjs());

    const [selectedStartHour, setSelectedStartHour] = useState("");
    const [selectedStartMinute, setSelectedStartMinute] = useState("");
    const [selectedStartPeriod, setSelectedStartPeriod] = useState("");

    const [selectedEndHour, setSelectedEndHour] = useState("");
    const [selectedEndMinute, setSelectedEndMinute] = useState("");
    const [selectedEndPeriod, setSelectedEndPeriod] = useState("");

    useEffect(() => {
        if (openEditScheduleEventModal && event) {
            setSelectedDate(convertToDayjsFromString(event.date));

            const [startTime, startPeriod] = event.start_time.split(" ");
            const [hour, minute] = startTime.split(":");

            setSelectedStartHour(hour);
            setSelectedStartMinute(minute);
            setSelectedStartPeriod(startPeriod);

            if (event.end_time) {
                const [endTime, endPeriod] = event.end_time.split(" ");
                const [endHour, endMinute] = endTime.split(":");

                setSelectedEndHour(endHour);
                setSelectedEndMinute(endMinute);
                setSelectedEndPeriod(endPeriod);
            }
        } else {
            setAddEndTime(false);
        }
    }, [openEditScheduleEventModal]);

    const [addEndTime, setAddEndTime] = useState(false);

    const [editLoading, setEditLoading] = useState(false);
    const handleEdit = async () => {
        setEditLoading(true);
        const data = await editScheduleEvent(
            event?.event_id || "",
            selectedStartHour,
            selectedStartMinute,
            selectedStartPeriod,
            selectedEndHour,
            selectedEndMinute,
            selectedEndPeriod,
            selectedDate.format("YYYY-MM-DD")
        );
        if (data.status === "success") {
            showNotification("Edited schedule event.", "", "success");
            setOpenEditScheduleEventModal(false);
            handleRefetch();
        } else {
            showNotification("Failed to edit event.", data.message, "error");
        }
        setEditLoading(false);
    };

    return (
        <Dialog
            open={openEditScheduleEventModal}
            onClose={() => setOpenEditScheduleEventModal(false)}
            className="relative z-30"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-30 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenEditScheduleEventModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-3"
                        >
                            Edit schedule event
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <div className="grid grid-cols-2 gap-y-4 gap-x-4">
                                <div className="w-full">
                                    {/* <h3 className="text-sm font-medium">
                                        Date:
                                    </h3>
                                    <p className="text-sm text-gray-700">
                                        {event?.date}
                                    </p> */}
                                    <SingleCalendar
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        allowFutureDates={true}
                                    />
                                </div>
                                <div className="flex flex-row items-center space-x-4">
                                    <h3 className="text-sm font-medium">
                                        Employee:
                                    </h3>
                                    <p className="text-sm text-gray-700">
                                        {event?.employee_name}
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <h3 className="text-sm font-medium pb-1">
                                        Start time
                                    </h3>
                                    <TimeSelector
                                        selectedHour={selectedStartHour}
                                        setSelectedHour={setSelectedStartHour}
                                        selectedMinute={selectedStartMinute}
                                        setSelectedMinute={
                                            setSelectedStartMinute
                                        }
                                        selectedPeriod={selectedStartPeriod}
                                        setSelectedPeriod={
                                            setSelectedStartPeriod
                                        }
                                        initializeTime={false}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <h3 className="text-sm font-medium pb-1">
                                        End time
                                    </h3>
                                    {event?.end_time || addEndTime ? (
                                        <TimeSelector
                                            selectedHour={selectedEndHour}
                                            setSelectedHour={setSelectedEndHour}
                                            selectedMinute={selectedEndMinute}
                                            setSelectedMinute={
                                                setSelectedEndMinute
                                            }
                                            selectedPeriod={selectedEndPeriod}
                                            setSelectedPeriod={
                                                setSelectedEndPeriod
                                            }
                                            initializeTime={false}
                                        />
                                    ) : (
                                        <PlusCircleIcon
                                            onClick={() => setAddEndTime(true)}
                                            className="w-6 pt-1 text-accent hover:text-accenthighlight"
                                        />
                                    )}
                                </div>
                                <div className="flex flex-row items-center space-x-4">
                                    <h3 className="text-sm font-medium">
                                        Description:
                                    </h3>
                                    <p className="text-sm text-gray-700">
                                        {event?.description
                                            ? event?.description
                                            : "None"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {isOwner && (
                            <div className="flex flex-row-reverse justify-between">
                                <ActionButton
                                    label="Save"
                                    handleClick={() => handleEdit()}
                                    height={9}
                                    loading={editLoading}
                                />
                                <DeleteButton
                                    label="Delete event"
                                    handleClick={() => handleDelete()}
                                    height={9}
                                    loading={loading}
                                />
                            </div>
                        )}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default EditScheduleEventModal;
