/** @format */

import { useState, useEffect, useCallback } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../components/loadingWheel";
import { createBulkInboundOrder, createInboundOrder } from "./api/inboundApi";
import { FileUpload } from "../../../utils/files/fileUpload";
import {
    InputField,
    InputFileField,
    InputNumberField,
} from "../../../components/inputField";
import SingleCalendar from "../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { formatDateFromObject } from "../../../utils/date/date_formatter";
import { useNotification } from "../../../utils/notification/notificationContext";
import MultiTrackingInput from "../../../components/multipleTrackingInput";
import FulfilledBySelector from "../../../components/fulfilledBySelector";
import {
    CreateOrder,
    fulfilledByOptions,
    Order,
    ReceivedOrder,
    soldAsOptions,
} from "../../../models/order";
import { ActionButton, SecondaryButton } from "../../../components/buttons";
import { TrackingInfo } from "../../../models/trackings";
import { useSearchParams } from "react-router-dom";
import { apiRequest } from "../../../utils/api/apiRequest";
import { debounce } from "lodash";
import { SuggestedNamesDropDown } from "../../admin/received/markReceivedOrder";
import MediumSelector from "../../../components/dropdowns/mediumSelector";
import { Employee } from "../../../models/employees";

export function AddOrderModal({
    openAddOrderModal,
    setOpenAddOrderModal,
    handleRefetch,
}: {
    openAddOrderModal: boolean;
    setOpenAddOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const [fulfilledBy, setFulfilledBy] = useState<{
        id: number;
        value: string;
    }>({ id: 1, value: "-- select an option --" });
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

    const [trackings, setTrackings] = useState<TrackingInfo[]>([]);

    const [missingFields, setMissingFields] = useState(false);

    const resetOrder = {
        item_name: "",
        order_number: "",
        size: "",
        brand_sku: "",
        asin: "",
        retailer: "",
        quantity: "",
        color: "",
        unit_cost: "",
        list_price: "",
        amazon_sku: "",
        notes: "",
    };

    const [order, setOrder] = useState<CreateOrder>(resetOrder);

    const updateOrder = (
        index: number,
        field: keyof ReceivedOrder | keyof CreateOrder,
        value: any
    ) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            [field]: value,
        }));
        if (!["quantity", "unit_cost", "list_price", "notes"].includes(field)) {
            debouncedSearch(value, field);
        }
    };

    const clearFields = () => {
        setSelectedDate(dayjs());
        setOrder(resetOrder);
        setTrackings([]);
        setSelectedShippingLabelFiles([]);
    };

    useEffect(() => {
        if (!openAddOrderModal) {
            setMissingFields(false);
            setFulfilledBy({ id: 1, value: "-- select an option --" });
            setSoldAs({
                id: "1",
                name: "Individual",
                value: "individual",
            });
            setUnitsPerPack("");
        } else {
            const openedModal = searchParams.get("openModal");
            if (openedModal === "new_inbound_shipment") {
                const matchedOption = fulfilledByOptions.find(
                    (option) =>
                        option.value.toLowerCase() ===
                            searchParams.get("fulfilled_by") || "".toLowerCase()
                );

                if (matchedOption) {
                    setFulfilledBy(matchedOption);
                }

                setOrder({
                    item_name: searchParams.get("item_name") || "",
                    order_number: searchParams.get("order_number") || "",
                    size: searchParams.get("size") || "",
                    brand_sku: searchParams.get("brand_sku") || "",
                    asin: searchParams.get("asin") || "",
                    retailer: searchParams.get("retailer") || "",
                    quantity: "0",
                    color: searchParams.get("color") || "",
                    unit_cost: searchParams.get("unit_cost") || "",
                    list_price: searchParams.get("list_price") || "",
                    amazon_sku: searchParams.get("amazon_sku") || "",
                    notes: "",
                });
                setSearchParams([]);
            }
        }
    }, [openAddOrderModal]);

    const [trackingInput, setTrackingInput] = useState<string>("");

    const handleCreateOrder = async () => {
        if (trackingInput.length > 0) {
            showNotification(
                "You forgot to press enter in the tracking number input.",
                "Hit enter on the input or remove it.",
                "error"
            );
            return;
        }
        setLoading(true);
        const data = await createInboundOrder(
            fulfilledBy.value.toLowerCase(),
            formatDateFromObject(selectedDate),
            order,
            trackings.map((t) => t.tracking),
            selectedShippingLabelFiles,
            soldAs.value,
            unitsPerPack
        );
        if (data.status === "success") {
            handleRefetch();
            setOpenAddOrderModal(false);
            showNotification("Your order has been created", "", "success");
            clearFields();
        } else {
            showNotification("An error occurred", data.message, "error");
        }
        setLoading(false);
    };

    const [selectedShippingLabelFiles, setSelectedShippingLabelFiles] =
        useState<File[]>([]);
    const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
        {}
    );
    const setColumnLoading = (column_name: string, isLoading: boolean) => {
        setLoadingStates((prev) => ({
            ...prev,
            [column_name]: isLoading,
        }));
    };
    const isColumnLoading = (column_name: string) =>
        loadingStates[column_name] || false;

    const [focusedField, setFocusedField] = useState<null | {
        column: string;
        index: number;
    }>(null);
    const [columnSuggestions, setColumnSuggestions] = useState<
        Record<string, string[]>
    >({});

    const [itemsLoading, setItemsLoading] = useState(false);
    const searchItemNames = async (name: string, column_name: string) => {
        if (!name || name === "") {
            setColumnSuggestions((prev) => ({
                ...prev,
                [column_name]: [], // Clear suggestions for the column
            }));
            setColumnLoading(column_name, false);
            return;
        }
        setColumnLoading(column_name, true);

        const data = await apiRequest(
            `/inbound/search-item-names?name=${name}&column_name=${column_name}`
        );
        if (data.status === "success") {
            setColumnSuggestions((prev) => ({
                ...prev,
                [column_name]: data.data || [], // Update suggestions for the column
            }));
        }
        setColumnLoading(column_name, false);
    };

    const debouncedSearch = useCallback(
        debounce(
            (value: string, column_name: string) =>
                searchItemNames(value, column_name),
            200
        ),
        []
    );

    const getSuggestionsForColumn = (column_name: string) =>
        columnSuggestions[column_name] || [];

    const [soldAs, setSoldAs] = useState<{
        id: string;
        name: string;
        value: string;
    }>({
        id: "1",
        name: "Individual",
        value: "individual",
    });

    const [unitsPerPack, setUnitsPerPack] = useState("");

    return (
        <Dialog
            open={openAddOrderModal}
            onClose={() => setOpenAddOrderModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-5/6 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenAddOrderModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Enter shipment
                            {missingFields ? (
                                <span className="text-red-500 pl-5">
                                    Missing fields, fill required fields.
                                </span>
                            ) : (
                                <></>
                            )}
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            <div className="flex-row flex space-x-10">
                                <div className="w-full">
                                    <span className="font-medium text-sm">
                                        Fulfillment method
                                    </span>
                                    <FulfilledBySelector
                                        selected={fulfilledBy}
                                        setSelected={setFulfilledBy}
                                        options={fulfilledByOptions}
                                    />
                                </div>
                                <div className="w-full">
                                    <span className="font-medium text-sm">
                                        Sold as
                                    </span>
                                    <MediumSelector
                                        selected={soldAs}
                                        setSelected={setSoldAs}
                                        selectorData={soldAsOptions}
                                    />
                                </div>
                            </div>

                            {soldAs.value !== "individual" && (
                                <div className="flex-row flex space-x-10 pt-4">
                                    <InputField
                                        label={`${
                                            soldAs.value === "multi-pack"
                                                ? "Units per pack"
                                                : "Units per bundle"
                                        }`}
                                        value={unitsPerPack}
                                        onChange={(e) =>
                                            setUnitsPerPack(e.target.value)
                                        }
                                        extraText={
                                            order.quantity !== "" &&
                                            unitsPerPack !== ""
                                                ? `${
                                                      Number(order.quantity) /
                                                      Number(unitsPerPack)
                                                  } total ${
                                                      soldAs.value ===
                                                      "multi-pack"
                                                          ? "packs"
                                                          : "bundles"
                                                  }`
                                                : ""
                                        }
                                    />
                                </div>
                            )}

                            <div className="flex-row flex space-x-10 pt-4">
                                <div className="space-y-4 w-full -mt-0.5">
                                    <SingleCalendar
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                    />
                                    <div className="relative">
                                        <InputField
                                            label="Item name"
                                            value={order.item_name}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "item_name",
                                                    e.target.value
                                                )
                                            }
                                            handleFocus={() =>
                                                setFocusedField({
                                                    column: "item_name",
                                                    index: 0,
                                                })
                                            }
                                            handleBlur={() => {
                                                setFocusedField(null);
                                            }}
                                            loading={isColumnLoading(
                                                "item_name"
                                            )}
                                        />
                                        <SuggestedNamesDropDown
                                            show={
                                                focusedField?.index === 0 &&
                                                order.item_name !== "" &&
                                                getSuggestionsForColumn(
                                                    "item_name"
                                                ).length > 0 &&
                                                focusedField?.column ===
                                                    "item_name"
                                            }
                                            itemsList={getSuggestionsForColumn(
                                                "item_name"
                                            )}
                                            index={0}
                                            updateOrder={updateOrder}
                                            setIsFocused={setFocusedField}
                                            columnName="item_name"
                                        />
                                    </div>
                                    <div className="relative">
                                        <InputField
                                            label="Size"
                                            value={order.size}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "size",
                                                    e.target.value
                                                )
                                            }
                                            handleFocus={() =>
                                                setFocusedField({
                                                    column: "size",
                                                    index: 0,
                                                })
                                            }
                                            handleBlur={() => {
                                                setFocusedField(null);
                                            }}
                                            loading={isColumnLoading("size")}
                                        />
                                        <SuggestedNamesDropDown
                                            show={
                                                focusedField?.index === 0 &&
                                                order.size !== "" &&
                                                getSuggestionsForColumn("size")
                                                    .length > 0 &&
                                                focusedField?.column === "size"
                                            }
                                            itemsList={getSuggestionsForColumn(
                                                "size"
                                            )}
                                            index={0}
                                            updateOrder={updateOrder}
                                            setIsFocused={setFocusedField}
                                            columnName="size"
                                        />
                                    </div>
                                    <InputField
                                        label="Unit cost"
                                        value={order.unit_cost}
                                        onChange={(e) =>
                                            updateOrder(
                                                0,
                                                "unit_cost",
                                                e.target.value
                                            )
                                        }
                                        placeholder=""
                                        extraText={`Order total: $${(
                                            Number(order.quantity) *
                                            Number(order.unit_cost)
                                        ).toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`}
                                    />
                                    <div className="relative">
                                        <InputField
                                            label="Brand SKU"
                                            value={order.brand_sku}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "brand_sku",
                                                    e.target.value
                                                )
                                            }
                                            placeholder=""
                                            optional={true}
                                            handleFocus={() =>
                                                setFocusedField({
                                                    column: "brand_sku",
                                                    index: 0,
                                                })
                                            }
                                            handleBlur={() => {
                                                setFocusedField(null);
                                            }}
                                            loading={isColumnLoading(
                                                "brand_sku"
                                            )}
                                        />
                                        <SuggestedNamesDropDown
                                            show={
                                                focusedField?.index === 0 &&
                                                order.brand_sku !== "" &&
                                                getSuggestionsForColumn(
                                                    "brand_sku"
                                                ).length > 0 &&
                                                focusedField?.column ===
                                                    "brand_sku"
                                            }
                                            itemsList={getSuggestionsForColumn(
                                                "brand_sku"
                                            )}
                                            index={0}
                                            updateOrder={updateOrder}
                                            setIsFocused={setFocusedField}
                                            columnName="brand_sku"
                                        />
                                    </div>
                                    {fulfilledBy.value.toLowerCase() !==
                                        "parcel-forwarding" && (
                                        <div className="relative">
                                            <InputField
                                                label="ASIN"
                                                value={order.asin}
                                                onChange={(e) =>
                                                    updateOrder(
                                                        0,
                                                        "asin",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder=""
                                                optional={true}
                                                handleFocus={() =>
                                                    setFocusedField({
                                                        column: "asin",
                                                        index: 0,
                                                    })
                                                }
                                                handleBlur={() => {
                                                    setFocusedField(null);
                                                }}
                                                loading={isColumnLoading(
                                                    "asin"
                                                )}
                                            />
                                            <SuggestedNamesDropDown
                                                show={
                                                    focusedField?.index === 0 &&
                                                    order.asin !== "" &&
                                                    getSuggestionsForColumn(
                                                        "asin"
                                                    ).length > 0 &&
                                                    focusedField?.column ===
                                                        "asin"
                                                }
                                                itemsList={getSuggestionsForColumn(
                                                    "asin"
                                                )}
                                                index={0}
                                                updateOrder={updateOrder}
                                                setIsFocused={setFocusedField}
                                                columnName="asin"
                                            />
                                        </div>
                                    )}
                                    <MultiTrackingInput
                                        trackings={trackings}
                                        setTrackings={setTrackings}
                                        currentInput={trackingInput}
                                        setCurrentInput={setTrackingInput}
                                    />
                                </div>
                                <div className="space-y-4 w-full">
                                    <div className="relative">
                                        <InputField
                                            label="Supplier"
                                            value={order.retailer}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "retailer",
                                                    e.target.value
                                                )
                                            }
                                            handleFocus={() =>
                                                setFocusedField({
                                                    column: "retailer",
                                                    index: 0,
                                                })
                                            }
                                            handleBlur={() => {
                                                setFocusedField(null);
                                            }}
                                            loading={isColumnLoading(
                                                "retailer"
                                            )}
                                        />
                                        <SuggestedNamesDropDown
                                            show={
                                                focusedField?.index === 0 &&
                                                order.retailer !== "" &&
                                                getSuggestionsForColumn(
                                                    "retailer"
                                                ).length > 0 &&
                                                focusedField?.column ===
                                                    "retailer"
                                            }
                                            itemsList={getSuggestionsForColumn(
                                                "retailer"
                                            )}
                                            index={0}
                                            updateOrder={updateOrder}
                                            setIsFocused={setFocusedField}
                                            columnName="retailer"
                                        />
                                    </div>
                                    <InputNumberField
                                        label="Quantity Ordered"
                                        value={order.quantity}
                                        onChange={(e) =>
                                            updateOrder(
                                                0,
                                                "quantity",
                                                e.target.value
                                            )
                                        }
                                        placeholder=""
                                    />
                                    <div className="relative">
                                        <InputField
                                            label="Color"
                                            value={order.color}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "color",
                                                    e.target.value
                                                )
                                            }
                                            handleFocus={() =>
                                                setFocusedField({
                                                    column: "color",
                                                    index: 0,
                                                })
                                            }
                                            handleBlur={() => {
                                                setFocusedField(null);
                                            }}
                                            loading={isColumnLoading("color")}
                                        />
                                        <SuggestedNamesDropDown
                                            show={
                                                focusedField?.index === 0 &&
                                                order.color !== "" &&
                                                getSuggestionsForColumn("color")
                                                    .length > 0 &&
                                                focusedField?.column === "color"
                                            }
                                            itemsList={getSuggestionsForColumn(
                                                "color"
                                            )}
                                            index={0}
                                            updateOrder={updateOrder}
                                            setIsFocused={setFocusedField}
                                            columnName="color"
                                        />
                                    </div>
                                    <div className="relative">
                                        <InputField
                                            label="Order #"
                                            value={order.order_number}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "order_number",
                                                    e.target.value
                                                )
                                            }
                                            handleFocus={() =>
                                                setFocusedField({
                                                    column: "order_number",
                                                    index: 0,
                                                })
                                            }
                                            handleBlur={() => {
                                                setFocusedField(null);
                                            }}
                                            loading={isColumnLoading(
                                                "order_number"
                                            )}
                                            optional={true}
                                        />
                                        <SuggestedNamesDropDown
                                            show={
                                                focusedField?.index === 0 &&
                                                order.order_number !== "" &&
                                                getSuggestionsForColumn(
                                                    "order_number"
                                                ).length > 0 &&
                                                focusedField?.column ===
                                                    "order_number"
                                            }
                                            itemsList={getSuggestionsForColumn(
                                                "order_number"
                                            )}
                                            index={0}
                                            updateOrder={updateOrder}
                                            setIsFocused={setFocusedField}
                                            columnName="order_number"
                                        />
                                    </div>
                                    {fulfilledBy.value.toLowerCase() !==
                                        "parcel-forwarding" && (
                                        <InputField
                                            label="List price"
                                            value={order.list_price}
                                            onChange={(e) =>
                                                updateOrder(
                                                    0,
                                                    "list_price",
                                                    e.target.value
                                                )
                                            }
                                            placeholder=""
                                            optional={true}
                                        />
                                    )}
                                    {fulfilledBy.value.toLowerCase() !==
                                        "parcel-forwarding" && (
                                        <div className="relative">
                                            <InputField
                                                label="Amazon SKU"
                                                value={order.amazon_sku}
                                                onChange={(e) =>
                                                    updateOrder(
                                                        0,
                                                        "amazon_sku",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder=""
                                                optional={true}
                                                handleFocus={() =>
                                                    setFocusedField({
                                                        column: "amazon_sku",
                                                        index: 0,
                                                    })
                                                }
                                                handleBlur={() => {
                                                    setFocusedField(null);
                                                }}
                                                loading={isColumnLoading(
                                                    "amazon_sku"
                                                )}
                                            />
                                            <SuggestedNamesDropDown
                                                show={
                                                    focusedField?.index === 0 &&
                                                    order.amazon_sku !== "" &&
                                                    getSuggestionsForColumn(
                                                        "amazon_sku"
                                                    ).length > 0 &&
                                                    focusedField?.column ===
                                                        "amazon_sku"
                                                }
                                                itemsList={getSuggestionsForColumn(
                                                    "amazon_sku"
                                                )}
                                                index={0}
                                                updateOrder={updateOrder}
                                                setIsFocused={setFocusedField}
                                                columnName="amazon_sku"
                                            />
                                        </div>
                                    )}
                                    <InputField
                                        label="Notes"
                                        value={order.notes}
                                        onChange={(e) =>
                                            updateOrder(
                                                0,
                                                "notes",
                                                e.target.value
                                            )
                                        }
                                        placeholder=""
                                        optional={true}
                                    />
                                    {fulfilledBy.value.toLowerCase() ===
                                        "parcel-forwarding" && (
                                        <InputFileField
                                            label="Shipping Label"
                                            value={""}
                                            onChange={() => {}}
                                            placeholder=""
                                            optional={true}
                                            selectedFiles={
                                                selectedShippingLabelFiles
                                            }
                                            setSelectedFiles={
                                                setSelectedShippingLabelFiles
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                            <ActionButton
                                label="Add shipment"
                                handleClick={() => handleCreateOrder()}
                                height={8}
                                loading={loading}
                            />
                            <div className="space-x-2 flex flex-row items-center">
                                <SecondaryButton
                                    label="Cancel"
                                    handleClick={() =>
                                        setOpenAddOrderModal(false)
                                    }
                                    height={8}
                                />
                                <SecondaryButton
                                    label="Clear fields"
                                    handleClick={() => clearFields()}
                                    height={8}
                                />
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export function AddBulkOrderModal({
    openAddBulkOrderModal,
    setOpenAddBulkOrderModal,
    handleRefetch,
}: {
    openAddBulkOrderModal: boolean;
    setOpenAddBulkOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    useEffect(() => {
        setSelectedFile(null);
    }, [openAddBulkOrderModal]);

    const handleCreateBulkOrder = async () => {
        setLoading(true);
        const { success, message } = await createBulkInboundOrder(
            selectedFile,
            showNotification
        );
        if (success) {
            handleRefetch();
            setOpenAddBulkOrderModal(false);
            showNotification("Your order has been created", "", "success");
        } else {
            showNotification("An error occurred", message, "error");
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openAddBulkOrderModal}
            onClose={() => setOpenAddBulkOrderModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenAddBulkOrderModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Upload CSV
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            <div className="flex flex-col w-full justify-between">
                                <span className="">
                                    1. Download the CSV template here:
                                </span>
                                <div className="flex flex-row items-center space-x-3 pt-1">
                                    <a
                                        download={"fba_fbm_template.csv"}
                                        href="/resources/fba_fbm_template.csv"
                                    >
                                        <button className="flex flex-row items-center border border-gray-300 hover:border-gray-400 rounded-md px-2 py-1 hover:bg-gray-50">
                                            <MdOutlineFileDownload />
                                            <span className="pl-2 text-sm">
                                                FBA/FBM template
                                            </span>
                                        </button>
                                    </a>
                                    <a
                                        download={
                                            "parcel_forwarding_template.csv"
                                        }
                                        href="/resources/parcel_forwarding_template.csv"
                                    >
                                        <button className="flex flex-row items-center border border-gray-300 hover:border-gray-400 rounded-md px-2 py-1 hover:bg-gray-50">
                                            <MdOutlineFileDownload />
                                            <span className="pl-2 text-sm">
                                                Parcel forwarding template
                                            </span>
                                        </button>
                                    </a>
                                </div>
                            </div>

                            <div className="pt-5">
                                <span className="w-full">
                                    2. Fill one row for each item you are
                                    shipping.
                                </span>
                            </div>

                            <div className="pt-5">
                                <span className="w-full">
                                    3. Upload the csv file here:
                                </span>
                            </div>

                            <FileUpload
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />

                            <div className=" inline-flex space-x-2 pt-2">
                                <InformationCircleIcon className="text-gray-500 w-4 h-4" />
                                <span className="text-xs text-gray-500">
                                    To add multiple tracking numbers, separate
                                    them by a colon: tracking1:tracking2
                                </span>
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                disabled={loading}
                                onClick={() => handleCreateBulkOrder()}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Add CSV
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenAddBulkOrderModal(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
