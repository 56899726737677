/** @format */

"use client";

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../../utils/notification/notificationContext";
import { updateReceivedOrderNotes } from "../api/receivedApi";
import LoadingWheel from "../../../components/loadingWheel";
import { ActionButton } from "../../../components/buttons";
import { OrderLog, ReceivedOrder } from "../../../models/order";
import { updateReceivedOrderClientNotes } from "../../client/received/api/receivedApi";
import { convertToESTV2 } from "../../../utils/date/date_formatter";
import CopyButton from "../../../components/copyButton";
import { fetchReceivedOrderHistoryLog } from "../api/unitsApi";

interface EditReceivedOrderModalProps {
    receivedOrder: ReceivedOrder | null;
    setOrders: React.Dispatch<React.SetStateAction<ReceivedOrder[]>>;
    openEditReceivedOrderModal: boolean;
    setOpenEditReceivedOrderModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    handleRefetch: () => void;
    bulkEdit?: boolean;
    selectedOrders?: ReceivedOrder[];
}

export default function EditReceivedOrderModal({
    receivedOrder,
    setOrders,
    openEditReceivedOrderModal,
    setOpenEditReceivedOrderModal,
    handleRefetch,
    bulkEdit = false,
    selectedOrders,
}: EditReceivedOrderModalProps) {
    const { showNotification } = useNotification();

    const isAdmin = () => window.location.href.includes("admin");

    const [clientNotesloading, setClientNotesloading] = useState(false);
    const [teamNotesloading, setTeamNotesloading] = useState(false);
    const [teamMemberNotes, setTeamMemberNotes] = useState("");
    const [clientNotes, setClientNotes] = useState("");
    useEffect(() => {
        if (receivedOrder && !bulkEdit) {
            setTeamMemberNotes(receivedOrder.team_member_notes);
            setClientNotes(receivedOrder.client_notes);
        } else {
            setTeamMemberNotes("");
            setClientNotes("");
        }
    }, [receivedOrder]);

    const handleUpdateTeamMemberNotes = async () => {
        setTeamNotesloading(true);
        const data = await updateReceivedOrderNotes(
            !bulkEdit
                ? [receivedOrder?.received_order_id || ""]
                : selectedOrders
                ? selectedOrders.map((o) => o.received_order_id)
                : [],
            teamMemberNotes
        );
        if (data.status === "success") {
            if (!bulkEdit) {
                setOrders((prevOrders) =>
                    prevOrders.map((order) =>
                        order.received_order_id ===
                        receivedOrder?.received_order_id
                            ? { ...order, team_member_notes: teamMemberNotes }
                            : order
                    )
                );
            } else if (selectedOrders) {
                setOrders((prevOrders) =>
                    prevOrders.map((order) => {
                        const selectedOrder = selectedOrders.find(
                            (selected) =>
                                selected.received_order_id ===
                                order.received_order_id
                        );

                        return selectedOrder
                            ? { ...order, team_member_notes: teamMemberNotes }
                            : order;
                    })
                );
            }
            showNotification("Saved notes.", "", "success");
            setOpenEditReceivedOrderModal(false);
        } else {
            showNotification("Failed to save notes.", data.message, "error");
        }
        setTeamNotesloading(false);
    };

    const handleUpdateClientNotes = async () => {
        setClientNotesloading(true);
        const data = await updateReceivedOrderClientNotes(
            !bulkEdit
                ? [receivedOrder?.received_order_id || ""]
                : selectedOrders
                ? selectedOrders.map((o) => o.received_order_id)
                : [],
            clientNotes
        );
        if (data.status === "success") {
            if (!bulkEdit) {
                setOrders((prevOrders) =>
                    prevOrders.map((order) =>
                        order.received_order_id ===
                        receivedOrder?.received_order_id
                            ? { ...order, client_notes: clientNotes }
                            : order
                    )
                );
            } else if (selectedOrders) {
                setOrders((prevOrders) =>
                    prevOrders.map((order) => {
                        const selectedOrder = selectedOrders.find(
                            (selected) =>
                                selected.received_order_id ===
                                order.received_order_id
                        );

                        return selectedOrder
                            ? { ...order, client_notes: clientNotes }
                            : order;
                    })
                );
            }
            setOpenEditReceivedOrderModal(false);
            showNotification("Saved notes.", "", "success");
        } else {
            showNotification("Failed to save notes.", data.message, "error");
        }
        setClientNotesloading(false);
    };

    const [logs, setLogs] = useState<OrderLog[]>([]);
    const [logsLoading, setLogsLoading] = useState(false);
    const fetchLog = async () => {
        if (receivedOrder) {
            setLogsLoading(true);
            const data = await fetchReceivedOrderHistoryLog(
                receivedOrder.received_order_id
            );
            if (data.status === "success") {
                setLogs(data.data.logs);
            }
            setLogsLoading(false);
        }
    };

    useEffect(() => {
        if (openEditReceivedOrderModal) {
            fetchLog();
        }
    }, [openEditReceivedOrderModal]);

    return (
        <Dialog
            open={openEditReceivedOrderModal}
            onClose={setOpenEditReceivedOrderModal}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setOpenEditReceivedOrderModal(false)
                                        }
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            aria-hidden="true"
                                            className="h-6 w-6"
                                        />
                                    </button>
                                </div>

                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900 pb-3 w-full"
                                >
                                    Edit received order
                                </DialogTitle>
                                <div className="text-sm text-gray-700 flex flex-row items-center justify-between">
                                    <span>
                                        Shipment ID:{" "}
                                        {receivedOrder?.received_order_id}
                                    </span>
                                    <CopyButton
                                        textToCopy={
                                            receivedOrder?.received_order_id ||
                                            ""
                                        }
                                    />
                                </div>
                                <div className="pb-2"></div>
                                <div className="w-full h-px bg-gray-200"></div>

                                <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                                    <div className="flex flex-row items-center justify-between mx-1">
                                        <span className="text-sm/6 font-medium text-gray-900">
                                            Date Received:
                                        </span>
                                        <span className="text-sm text-gray-800">
                                            {convertToESTV2(
                                                receivedOrder?.created_at,
                                                true
                                            )}{" "}
                                            EST
                                        </span>
                                    </div>
                                    {receivedOrder?.status === "checked-in" && (
                                        <div className="flex flex-row items-center justify-between mx-1">
                                            <span className="text-sm/6 font-medium text-gray-900">
                                                Date Checked In:
                                            </span>
                                            <span className="text-sm text-gray-800">
                                                {convertToESTV2(
                                                    receivedOrder?.updated_at,
                                                    true
                                                )}{" "}
                                                EST
                                            </span>
                                        </div>
                                    )}
                                    <div className="mx-1">
                                        <label
                                            htmlFor="comment"
                                            className="block text-sm/6 font-medium text-gray-900"
                                        >
                                            Check In Notes
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="comment"
                                                name="comment"
                                                rows={2}
                                                className="block w-full rounded-md bg-gray-100 px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                                                defaultValue={
                                                    receivedOrder?.check_in_notes
                                                }
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mx-1">
                                        <label
                                            htmlFor="comment"
                                            className="block text-sm/6 font-medium text-gray-900"
                                        >
                                            Team Member Notes
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="comment"
                                                name="comment"
                                                rows={2}
                                                className={`${
                                                    !isAdmin()
                                                        ? "bg-gray-100"
                                                        : "bg-white"
                                                } block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 sm:text-sm/6 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent`}
                                                defaultValue={teamMemberNotes}
                                                onChange={(e) =>
                                                    setTeamMemberNotes(
                                                        e.target.value
                                                    )
                                                }
                                                disabled={!isAdmin()}
                                            />
                                        </div>
                                    </div>
                                    {isAdmin() && (
                                        <div className="flex flex-row-reverse">
                                            <ActionButton
                                                label="Save"
                                                handleClick={() =>
                                                    handleUpdateTeamMemberNotes()
                                                }
                                                loading={teamNotesloading}
                                            />
                                        </div>
                                    )}
                                    <div className="mx-1">
                                        <div className="flex flex-row items-center justify-between">
                                            <label
                                                htmlFor="comment"
                                                className="block text-sm/6 font-medium text-gray-900"
                                            >
                                                Client Notes
                                            </label>
                                            <span className="block text-sm/6 text-gray-600 font-normal">
                                                {convertToESTV2(
                                                    receivedOrder?.client_notes_at,
                                                    true
                                                )}
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            <textarea
                                                id="comment"
                                                name="comment"
                                                rows={2}
                                                className={`${
                                                    isAdmin()
                                                        ? "bg-gray-100"
                                                        : "bg-white"
                                                } block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6`}
                                                defaultValue={clientNotes}
                                                disabled={isAdmin()}
                                                onChange={(e) =>
                                                    setClientNotes(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        {receivedOrder &&
                                            receivedOrder.related_orders?.map(
                                                (order) => (
                                                    <div className="flex flex-col mt-2">
                                                        <a
                                                            href={
                                                                isAdmin()
                                                                    ? `/admin/${
                                                                          order.status ===
                                                                          "outbound"
                                                                              ? "outbound"
                                                                              : "inventory"
                                                                      }?search=${
                                                                          order.order_id
                                                                      }&user_id=${
                                                                          receivedOrder?.user_id
                                                                      }`
                                                                    : `/${
                                                                          order.status ===
                                                                          "outbound"
                                                                              ? "outbound/shipped"
                                                                              : "inventory/units"
                                                                      }?search=${
                                                                          order.order_id
                                                                      }`
                                                            }
                                                            className="text-sm text-accent"
                                                        >
                                                            View Linked Inbound
                                                            Shipment
                                                        </a>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                    {!isAdmin() && (
                                        <div className="flex flex-row-reverse">
                                            <ActionButton
                                                label="Save"
                                                handleClick={() =>
                                                    handleUpdateClientNotes()
                                                }
                                                loading={clientNotesloading}
                                            />
                                        </div>
                                    )}
                                    <div className="pt-2 flex flex-col">
                                        <span className="text-sm font-semibold">
                                            Order History Log
                                        </span>
                                        {!logsLoading && logs.length > 0 ? (
                                            <>
                                                {logs.map((log) => (
                                                    <div className="text-sm pt-2">
                                                        <h3 className="">
                                                            {log.log}
                                                        </h3>
                                                        <p className="text-xs text-gray-800">
                                                            By{" "}
                                                            {log.employee_name}
                                                        </p>
                                                        <p className="text-xs text-gray-800">
                                                            At{" "}
                                                            {convertToESTV2(
                                                                log.created_at,
                                                                true,
                                                                false
                                                            )}
                                                        </p>
                                                        <div className="pt-2"></div>
                                                        <div className="w-full h-px bg-gray-300"></div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <div className="pt-2">
                                                {logsLoading ? (
                                                    <LoadingWheel />
                                                ) : (
                                                    <p className="text-sm text-gray-800">
                                                        No logs recorded
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isAdmin() && !bulkEdit && (
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-x-4 sm:gap-x-4">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() =>
                                        setOpenEditReceivedOrderModal(false)
                                    }
                                    className="mt-3 inline-flex w-full items-center text-center justify-center rounded-md bg-white px-3 h-8 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
