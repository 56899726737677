/** @format */

import { useSearchParams } from "react-router-dom";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { DisposalRequest, PictureRequest } from "../models/clients";
import { CompletedStatusTag } from "../components/statusTags";
import PictureRequestModal from "../pages/admin/worklist/picture-requests/pictureRequestModal";
import { useState } from "react";
import DisposalRequestModal from "../pages/admin/worklist/disposal-requests/disposalRequestModal";

export const initialDisposalRequestsColumnOrder = [
    {
        key: "status",
        label: "Status",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "created_at",
        label: "Requested at",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "full_name",
        label: "Client",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "client_notes",
        label: "Notes",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "total_quantity",
        label: "Quantity",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
];

interface DisposalRequestsTableProps {
    disposalRequests: DisposalRequest[];
    results: Results | null;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
    handleDelete?: (shipping_name: string) => Promise<void>;
}

function DisposalRequestsTable({
    disposalRequests,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    handleDelete,
}: DisposalRequestsTableProps) {
    const [searchParams] = useSearchParams();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const [selectedDisposalRequest, setSelectedDisposalRequest] =
        useState<DisposalRequest | null>(null);

    const isAdmin = () => window.location.href.includes("admin");

    const displayedHeaders =
        columnOrder &&
        columnOrder.filter((header) => header.key !== "full_name" || isAdmin());

    return (
        <div className="flex flex-col pt-3">
            {disposalRequests && disposalRequests.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {disposalRequests.map(
                                    (
                                        request: DisposalRequest,
                                        index: number
                                    ) => (
                                        <tr
                                            onClick={() =>
                                                setSelectedDisposalRequest(
                                                    request
                                                )
                                            }
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {[
                                                                "updated_at",
                                                                "created_at",
                                                            ].includes(
                                                                header.key
                                                            ) ? (
                                                                <td className="py-2.5 text-sm">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            request[
                                                                                header.key as keyof DisposalRequest
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "status" ? (
                                                                <td>
                                                                    <CompletedStatusTag
                                                                        completed={
                                                                            request.confirmed
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm"
                                                                >
                                                                    {
                                                                        request[
                                                                            header.key as keyof DisposalRequest
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    {results && (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                        >
                            <div className="hidden sm:block">
                                <div className="flex flex-row items-center space-x-3">
                                    <PaginationResults results={results} />
                                    <LimitSelector />
                                </div>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the previous page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "back"
                                            ).toString()
                                        );
                                        return `/inbound/shipping-names?${params.toString()}`;
                                    })()}
                                    disabled={getOffset(searchParams) === 0}
                                    text="Previous"
                                />
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the next page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "forward"
                                            ).toString()
                                        );
                                        return `/inbound/shipping-names?${params.toString()}`;
                                    })()}
                                    disabled={
                                        results.showing_to >=
                                        results.total_results
                                    }
                                    text="Next"
                                    className="ml-3"
                                />
                            </div>
                        </nav>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}

            <DisposalRequestModal
                disposalRequest={selectedDisposalRequest}
                setDisposalRequest={setSelectedDisposalRequest}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default DisposalRequestsTable;
