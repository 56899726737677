import Cookies from "js-cookie";
import { Order, ShelfLocations, ShipmentQuantities } from "../../../models/order";
import { buildUrl } from "../../../utils/url/buildUrl";
import { SortConfig } from "../../../hooks/useSort";
import { ShipmentPageSelector } from "../../../models/pageSelectors";
import { apiRequest } from "../../../utils/api/apiRequest";

export const fetchClientOrders = async (
    user_id: string | null | undefined,
    selectedOrderType: string,
    shipmentId?: string,
    offset?: number,
    limit?: number | null,
    sortConfig?: SortConfig,
    shipmentType?: string,
    signal?: AbortSignal,
    employee_id?: string
) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/orders`;
        const params = {
            user_id: user_id ? user_id : null,
            order_type: selectedOrderType.toLowerCase(),
            shipment_id: shipmentId ? shipmentId : "",
            offset: offset ? String(offset) : "",
            limit: limit && limit != 0 ? String(limit) : "-1",
            sort_key: sortConfig ? sortConfig.key : "created_at",
            sort_order: sortConfig ? sortConfig.order : "desc",
            shipment_type: shipmentType ? shipmentType : "",
            employee_id: employee_id ? employee_id : null
        };

        const url = buildUrl(baseUrl, params);

        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                signal
            }
        );

        const data = await response.json();
        return data;
    } catch (error) {
        if (error instanceof Error) {
            if (error.name === 'AbortError') {
                return {data: {status: "failed", message: "Request cancelled"}};
            } else {
                return {data: {status: "failed", message: error.message}};
            }
        } else {
            return {data: {status: "failed", message: "An unknown error occurred"}};
        }
    }
};  

export const modifyInboundOrders = async (
    user_id: string | undefined,
    selectedInboundOrders: Order[],
    admin_notes: string,
    shipmentQuantities: ShipmentQuantities, // Pass the image files here
    filesByOrder: {
        [orderId: string]: File[];
    },
    shelfs: ShelfLocations,
    received_order_id: string[] | null | undefined = null,
    quantityOverRide: boolean = false,
    upcOverride: boolean = false
) => {
    try {
        const inboundIdList = selectedInboundOrders.map(order => order.order_id);

        // Create FormData to handle file uploads and other fields
        const formData = new FormData();
        
        // Append the other data to the FormData object
        formData.append("inbound_ids", JSON.stringify(inboundIdList)); // Needs to be stringified
        formData.append("quantities_received", JSON.stringify(shipmentQuantities));
        formData.append("admin_notes", admin_notes);
        formData.append("shelfs", JSON.stringify(shelfs))
        formData.append("quantity_override", JSON.stringify(quantityOverRide))
        formData.append("upc_override", JSON.stringify(upcOverride))

        if (received_order_id) formData.append("received_order_ids", JSON.stringify(received_order_id))

        Object.keys(filesByOrder).forEach((orderId) => {
            filesByOrder[orderId].forEach((file) => {
                formData.append(`files[${orderId}][]`, file); // Append each file for the specific order_id
            });
        });

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/inbound/modify/` + user_id,
            {
                method: "POST",
                headers: {
                    // You don't need "Content-Type" for FormData. The browser will set it correctly.
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: formData // Send FormData as the request body
            }
        );

        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { success: false, message: String(error) } };
    }
};

export const deleteClientOrders = async (
    user_id: string | undefined,
    selectedOrders: Order[] | undefined
) => {
    try {
        if (!selectedOrders) {
            return
        }
        const order_ids = selectedOrders.map(order => order.order_id);

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/orders`,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({order_ids})
            }
        );

        const data = await response.json();
        return data;
    } catch (error) {
        return {data : {success: false, message: String(error)}}
    }
};  

export const addInventoryOrder = async (
    user_id: string | undefined,
    fulfilledBy: string | null,
    date: string,
    retailer: string,
    orderNumber: string,
    itemName: string,
    color: string,
    brandSKU: string,
    size: string,
    quantity: string,
    asin: string,
    amazonSKU: string,
    cogs: string,
    listPrice: string,
    notes: string,
    trackingNumbers: string[],
) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/inventory/${user_id}`;

        const response = await fetch(
            url,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({
                    fulfilledBy,
                    date,
                    retailer,
                    orderNumber,
                    itemName,
                    color,
                    brandSKU,
                    size,
                    quantity,
                    asin,
                    amazonSKU,
                    cogs,
                    listPrice,
                    notes,
                    trackingNumbers
                })
            }
        );

        const data = await response.json();
        return data;
    } catch (error) {
        return {data : {success: false, message: String(error)}}
    }
};  

export const returnInboundUnits = async (check_in_id: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/inbound/return`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({check_in_id})
            }
        );

        const data = await response.json();
        return data;
    } catch (error) {
        return {data : {success: false, message: String(error)}}
    }
};  

export const removeOrderUnits = async (order_id: string, quantity_to_remove: string) => {
    return apiRequest(`/admin/inventory/units/${order_id}`, "DELETE", {quantity_to_remove})
}

export const fetchOrderHistoryLog = async (order_id: string) => {
    return apiRequest(`/admin/orders/history-log?order_id=${order_id}`, "GET")
}

export const fetchReceivedOrderHistoryLog = async (received_order_id: string) => {
    return apiRequest(`/admin/received/history-log?received_order_id=${received_order_id}`, "GET")
}