/** @format */

import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { ShippingDetailsRequest } from "../models/shippingDetailsRequest";
import ShippingDetailsOrdersModal from "../pages/client/outbound/shipping-details/shippingDetailsOrdersModal";
import { CompletedStatusTag } from "../components/statusTags";

export const initialShippingDetailsColumnOrder = [
    {
        key: "updated_at",
        label: "Status",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "created_at",
        label: "Created at",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "full_name",
        label: "Client",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "client_notes",
        label: "Client Notes",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "admin_notes",
        label: "Admin Notes",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
];

interface ShippingDetailsTableProps {
    shippingDetails: ShippingDetailsRequest[];
    results: Results | null;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
    handleDelete?: (shipping_name: string) => Promise<void>;
}

function ShippingDetailsTable({
    shippingDetails,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    handleDelete,
}: ShippingDetailsTableProps) {
    const [searchParams] = useSearchParams();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const [selectedShippingDetails, setSelectedShippingDetails] =
        useState<ShippingDetailsRequest | null>(null);

    const isAdmin = () => window.location.href.includes("admin");

    const displayedHeaders =
        columnOrder &&
        columnOrder.filter((header) => header.key !== "full_name" || isAdmin());

    return (
        <div className="flex flex-col pt-3">
            {shippingDetails && results && shippingDetails.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {shippingDetails.map(
                                    (
                                        detail: ShippingDetailsRequest,
                                        index: number
                                    ) => (
                                        <tr
                                            onClick={() =>
                                                setSelectedShippingDetails(
                                                    detail
                                                )
                                            }
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {header.key ===
                                                            "created_at" ? (
                                                                <td className="py-2.5 text-sm pl-4 whitespace-nowrap">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            detail[
                                                                                header.key as keyof ShippingDetailsRequest
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "updated_at" ? (
                                                                <td>
                                                                    <CompletedStatusTag
                                                                        completed={
                                                                            detail.updated_at &&
                                                                            detail.admin_notes
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm pl-4 whitespace-nowrap"
                                                                >
                                                                    {
                                                                        detail[
                                                                            header.key as keyof ShippingDetailsRequest
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/inbound/shipping-names?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/inbound/shipping-names?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}

            <ShippingDetailsOrdersModal
                shippingDetails={selectedShippingDetails}
                setShippingDetails={setSelectedShippingDetails}
                userId={selectedShippingDetails?.user_id}
                handleClientRefetch={handleRefetch}
            />
        </div>
    );
}

export default ShippingDetailsTable;
