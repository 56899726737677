/** @format */

import { useNavigate, useSearchParams } from "react-router-dom";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { FbaShipmentRequest } from "../models/clients";
import { CompletedStatusTag } from "../components/statusTags";
import { ActionButton } from "../components/buttons";
import { useClient } from "../utils/client/clientContext";
import { useState } from "react";
import { markFbaShipmentsRequestedDone } from "../pages/admin/worklist/api/worklistApi";
import { useNotification } from "../utils/notification/notificationContext";

export const initialFbaShipmentRequestsColumnOrder = [
    {
        key: "status",
        label: "Status",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "full_name",
        label: "Client",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 whitespace-nowrap pl-4",
    },
    {
        key: "requested_at",
        label: "Requested at",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 whitespace-nowrap pl-4",
    },
    {
        key: "fba_unit_count",
        label: "Units",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 whitespace-nowrap pl-4",
    },
    {
        key: "notes",
        label: "Notes",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 whitespace-nowrap pl-4",
    },
    {
        key: "completed_at",
        label: "Completed at",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 whitespace-nowrap pl-4",
    },
    {
        key: "actions",
        label: "",
        className: "text-right",
        isSortable: false,
        nonMovable: true,
    },
];

interface FbaShipmentRequestsTableProps {
    fbaShipmentRequests: FbaShipmentRequest[];
    setFbaShipmentRequests: React.Dispatch<
        React.SetStateAction<FbaShipmentRequest[]>
    >;
    results: Results | null;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
    handleDelete?: (shipping_name: string) => Promise<void>;
}

function FbaShipmentRequestsTable({
    fbaShipmentRequests,
    setFbaShipmentRequests,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    handleDelete,
}: FbaShipmentRequestsTableProps) {
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();
    const { setUserId } = useClient();
    const navigate = useNavigate();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const isAdmin = () => window.location.href.includes("admin");

    const displayedHeaders =
        columnOrder &&
        columnOrder
            .filter((header) => header.key !== "full_name" || isAdmin())
            .filter((header) => header.key !== "fba_unit_count" || isAdmin());

    const [fbaDoneLoadings, setFbaDoneLoadings] = useState<number[]>([]);
    const handleFbaDone = async (request_id: number) => {
        setFbaDoneLoadings((prev) => [...prev, request_id]);
        const data = await markFbaShipmentsRequestedDone(request_id);
        if (data.status === "success") {
            if (!fbaShipmentRequests) return;

            const updatedData = fbaShipmentRequests.filter(
                (shipment) => shipment.id !== request_id
            );
            setFbaShipmentRequests(updatedData);
            handleRefetch();
            showNotification("Marked FBA request as done", "", "success");
        } else {
            showNotification("Failed to mark FBA request as done", "", "error");
        }
        setFbaDoneLoadings((prev) => prev.filter((id) => id !== request_id));
    };

    return (
        <div className="flex flex-col pt-3">
            {fbaShipmentRequests &&
            results &&
            fbaShipmentRequests.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {fbaShipmentRequests.map(
                                    (
                                        request: FbaShipmentRequest,
                                        index: number
                                    ) => (
                                        <tr
                                            onClick={
                                                isAdmin()
                                                    ? () => {
                                                          setUserId(
                                                              request.user_id
                                                          );
                                                          navigate(
                                                              `/admin/inventory?user_id=${request.user_id}`
                                                          );
                                                      }
                                                    : () => {}
                                            }
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {[
                                                                "requested_at",
                                                                "completed_at",
                                                            ].includes(
                                                                header.key
                                                            ) ? (
                                                                <td className="py-2.5 text-sm whitespace-nowrap pl-4">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            request[
                                                                                header.key as keyof FbaShipmentRequest
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "status" ? (
                                                                <td>
                                                                    <CompletedStatusTag
                                                                        completed={
                                                                            request.completed_at
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "actions" ? (
                                                                <>
                                                                    {!request.completed_at ? (
                                                                        <td className="sticky right-0 text-right cursor-pointer">
                                                                            <div className="text-right items-end flex justify-end flex-row space-x-2">
                                                                                <ActionButton
                                                                                    label="Done"
                                                                                    handleClick={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleFbaDone(
                                                                                            request.id
                                                                                        );
                                                                                    }}
                                                                                    loading={fbaDoneLoadings.includes(
                                                                                        request.id
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    ) : (
                                                                        <td></td>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm whitespace-nowrap pl-4"
                                                                >
                                                                    {
                                                                        request[
                                                                            header.key as keyof FbaShipmentRequest
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/inbound/shipping-names?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/inbound/shipping-names?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}
        </div>
    );
}

export default FbaShipmentRequestsTable;
