import { SortConfig } from "../../../../hooks/useSort";
import { apiRequest } from "../../../../utils/api/apiRequest"
import { buildUrl } from "../../../../utils/url/buildUrl";


export const fetchEmployeesKpis = async (date_filter: string, start_date: string | null, end_date: string | null) => {
    const baseUrl = `/admin/kpis`;
    const params = {
        date_filter,
        start_date: start_date ? start_date : null,
        end_date: end_date ? end_date : null
    };

    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const fetchClientsKpis = async (date_filter: string, start_date: string | null, end_date: string | null) => {
    const baseUrl = `/admin/kpis/clients`;
    const params = {
        date_filter,
        start_date: start_date ? start_date : null,
        end_date: end_date ? end_date : null
    };

    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const fetchGeneralKpis = async (date_filter: string, start_date: string | null, end_date: string | null) => {
    const baseUrl = `/admin/kpis/general`;
    const params = {
        date_filter,
        start_date: start_date ? start_date : null,
        end_date: end_date ? end_date : null
    };

    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const fetchKpiMetrics = async (searchParams: URLSearchParams, sortConfig: SortConfig, employee_id: string) => {
    const baseUrl = `/admin/kpis/metrics`;
    const params = {
        offset: searchParams.get("offset"),
        limit: searchParams.get("limit"),
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc",
        employee_id: employee_id === "null" ? null : employee_id
    };
    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const fetchKpiAudits = async (row_id: string, table_name: string) => {
    const baseUrl = `/admin/kpis/metrics/audits`;
    const params = {
        table_name,
        row_id
    };
    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const createEmployeeError = async (employee_id: string, action_type: string, quantity: string, notes: string) => {
    return apiRequest("/admin/kpis/employees/errors", "POST", {employee_id, action_type, quantity, notes})
}