import Cookies from "js-cookie";
import { NavigateFunction } from "react-router-dom";
import { apiRequest } from "../api/apiRequest";


export function handleLogout(navigate: NavigateFunction) {
    apiRequest("/logout", "POST")
    Cookies.remove("jwt")
    localStorage.clear();
    navigate("/login")
}

export function clearAllData() {
    Cookies.remove("jwt")
    localStorage.clear();
}