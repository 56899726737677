/** @format */

import { useEffect, useRef, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";
import { createPortal } from "react-dom";
import { serviceOptions, ShipmentService } from "../../../../models/shipments";

interface ServicesPopupProps {
    selectedServices: ShipmentService[];
    setSelectedServices: React.Dispatch<
        React.SetStateAction<ShipmentService[]>
    >;
    totalUnits: number | null;
    widthFull?: boolean;
}

export default function ServicesPopup({
    selectedServices,
    setSelectedServices,
    totalUnits,
    widthFull = false,
}: ServicesPopupProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        if (buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            // Calculate available space
            const spaceBelow = viewportHeight - buttonRect.bottom;
            const spaceAbove = buttonRect.top;

            // Determine dropdown placement
            if (spaceBelow < 200 && spaceAbove > spaceBelow) {
                // Position above the button
                setDropdownStyle({
                    bottom: viewportHeight - buttonRect.top,
                    left: buttonRect.left,
                });
            } else {
                // Position below the button
                setDropdownStyle({
                    top: buttonRect.bottom,
                    left: buttonRect.left,
                });
            }
        }

        setIsOpen((prevState) => !prevState);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // const handleServiceClick = (service: ShipmentService) => {
    //     setSelectedServices((prev) => {
    //         const existingService = prev.find((b) => b.name === service.name);

    //         if (existingService) {
    //             return prev.map((s) =>
    //                 s.name === service.name
    //                     ? { ...s, quantity: s.quantity || 0 + 1 }
    //                     : s
    //             );
    //         } else {
    //             return [...prev, { ...service, quantity: null }];
    //         }
    //     });
    //     setIsOpen(false);
    // };
    const handleServiceClick = (service: ShipmentService) => {
        console.log(totalUnits);
        setSelectedServices((prev) => {
            const existingService = prev.find((b) => b.name === service.name);

            if (existingService) {
                return prev.map((s) =>
                    s.name === service.name
                        ? { ...s, quantity: (s.quantity || 0) + 1 }
                        : s
                );
            } else {
                return [
                    ...prev,
                    {
                        ...service,
                        quantity:
                            totalUnits && totalUnits > 0 ? totalUnits : null,
                    },
                ];
            }
        });
        setIsOpen(false);
    };

    const handleBoxQuantityChange = (name: string, quantity: number) => {
        if (quantity < 1) return;
        setSelectedServices((prev) =>
            prev.map((b) => (b.name === name ? { ...b, quantity } : b))
        );
    };

    const handleRemoveService = (name: string) => {
        setSelectedServices(
            selectedServices.filter((service) => service.name !== name)
        );
    };

    const availableServices = serviceOptions.filter(
        (box) =>
            !selectedServices.some(
                (selectedService) => selectedService.name === box.name
            )
    );

    return (
        <div className="relative">
            <div
                ref={buttonRef}
                onClick={toggleDropdown}
                className="flex items-center cursor-pointer"
            >
                <span className="font-medium text-sm">Add services</span>
                <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
            </div>
            {isOpen &&
                createPortal(
                    <div
                        ref={dropdownRef}
                        className={`
                        w-48 absolute max-h-80 overflow-auto pt-1 bg-white border border-gray-300 shadow-lg rounded-md z-50`}
                        style={{
                            ...dropdownStyle,
                            position: "fixed", // Use fixed positioning for proper viewport alignment
                        }}
                    >
                        {availableServices.map((service) => (
                            <div
                                key={service.name}
                                onClick={() => handleServiceClick(service)}
                                className="text-sm flex flex-col py-2 space-y-0.5 pl-2 hover:bg-gray-100 transition-all cursor-pointer"
                            >
                                <span className="font-medium">
                                    {service.name}
                                </span>
                            </div>
                        ))}
                    </div>,
                    document.body
                )}
            <div className="w-full pt-2">
                <div className="w-full pt-2">
                    <div className="flex flex-col space-y-3 w-full">
                        {selectedServices.length > 0 &&
                            selectedServices.map((service) => (
                                <div
                                    key={service.name}
                                    className={`flex items-center space-x-2 flex-row ${
                                        widthFull ? "justify-between" : ""
                                    }`}
                                >
                                    <div className="flex flex-col text-sm">
                                        <span className="font-medium">
                                            {service.name}
                                        </span>
                                    </div>
                                    <div className="flex flex-row items-center space-x-3">
                                        {service.name !== "Returns" && (
                                            <input
                                                type="number"
                                                className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                min="0"
                                                value={service.quantity || ""}
                                                onChange={(e) =>
                                                    handleBoxQuantityChange(
                                                        service.name,
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                                onWheel={(e) => {
                                                    const target =
                                                        e.target as HTMLInputElement;
                                                    target.blur();
                                                    setTimeout(
                                                        () => target.focus(),
                                                        0
                                                    );
                                                }}
                                            />
                                        )}
                                        <TrashIcon
                                            onClick={() => {
                                                handleRemoveService(
                                                    service.name
                                                );
                                            }}
                                            className="w-5 h-5 text-red-600 hover:text-red-500 cursor-pointer"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
