/** @format */

import { useSearchParams } from "react-router-dom";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { CompletedStatusTag } from "../components/statusTags";
import { useEffect, useState } from "react";
import { WarehouseClarificationTask } from "../pages/admin/warehouse-clarification/tasks/page";
import WarehouseClarificationModal from "../pages/admin/warehouse-clarification/warehouseClarificationModal";
import { ReceivedOrder } from "../models/order";

export const initialWarehouseClarificationColumnOrder = [
    {
        key: "status",
        label: "Status",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "created_at",
        label: "Created at",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "question",
        label: "Question",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "response",
        label: "Response",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "updated_at",
        label: "Answered At",
        isSortable: true,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
];

interface WarehouseClarificationsTableProps {
    clarifications: WarehouseClarificationTask[];
    results: Results | null;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
    handleDelete?: (shipping_name: string) => Promise<void>;
}

function WarehouseClarificationsTable({
    clarifications,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    handleDelete,
}: WarehouseClarificationsTableProps) {
    const [searchParams] = useSearchParams();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const [orders, setOrders] = useState<ReceivedOrder[]>([]);
    const [
        openWarehouseClarificationModal,
        setOpenWarehouseClarificationModal,
    ] = useState(false);

    const [selectedWarehouseClarification, setSelectedWarehouseClarification] =
        useState<WarehouseClarificationTask | null>(null);

    useEffect(() => {
        if (selectedWarehouseClarification)
            setOpenWarehouseClarificationModal(true);
    }, [selectedWarehouseClarification]);

    useEffect(() => {
        if (!openWarehouseClarificationModal)
            setSelectedWarehouseClarification(null);
    }, [openWarehouseClarificationModal]);

    return (
        <div className="flex flex-col pt-3">
            {clarifications && clarifications.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {columnOrder &&
                                        columnOrder.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {clarifications.map(
                                    (
                                        clarification: WarehouseClarificationTask,
                                        index: number
                                    ) => (
                                        <tr
                                            onClick={() =>
                                                setSelectedWarehouseClarification(
                                                    clarification
                                                )
                                            }
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {columnOrder &&
                                                columnOrder.map(
                                                    (header: Column) => (
                                                        <>
                                                            {[
                                                                "updated_at",
                                                                "created_at",
                                                            ].includes(
                                                                header.key
                                                            ) ? (
                                                                <td className="py-2.5 text-sm pl-4 whitespace-nowrap">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            clarification[
                                                                                header.key as keyof WarehouseClarificationTask
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "status" ? (
                                                                <td>
                                                                    <CompletedStatusTag
                                                                        completed={
                                                                            clarification.updated_at
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm pl-4 whitespace-nowrap"
                                                                >
                                                                    {
                                                                        clarification[
                                                                            header.key as keyof WarehouseClarificationTask
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    {results && (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                        >
                            <div className="hidden sm:block">
                                <div className="flex flex-row items-center space-x-3">
                                    <PaginationResults results={results} />
                                    <LimitSelector />
                                </div>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the previous page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "back"
                                            ).toString()
                                        );
                                        return `/inbound/shipping-names?${params.toString()}`;
                                    })()}
                                    disabled={getOffset(searchParams) === 0}
                                    text="Previous"
                                />
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the next page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "forward"
                                            ).toString()
                                        );
                                        return `/inbound/shipping-names?${params.toString()}`;
                                    })()}
                                    disabled={
                                        results.showing_to >=
                                        results.total_results
                                    }
                                    text="Next"
                                    className="ml-3"
                                />
                            </div>
                        </nav>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}
            {selectedWarehouseClarification && (
                <WarehouseClarificationModal
                    orderType={selectedWarehouseClarification?.order_type}
                    modalType="view"
                    orders={orders}
                    setOrders={setOrders}
                    openWarehouseClarificationModal={
                        openWarehouseClarificationModal
                    }
                    setOpenWarehouseClarificationModal={
                        setOpenWarehouseClarificationModal
                    }
                    handleRefetch={handleRefetch}
                    selectedWarehouseClarification={
                        selectedWarehouseClarification
                    }
                />
            )}
        </div>
    );
}

export default WarehouseClarificationsTable;
