/** @format */

import { useState } from "react";
import { Order } from "../models/order";
import Shipment from "../models/shipments";

export const sortableColumns = [
    "created_at",
    "size",
    "brand_sku",
    "item_name",
    "order_number",
    "retailer",
    "fulfilled_by",
    "status",
    "quantity",
    "color",
    "asin",
    "amazon_sku",
    "unit_cost",
    "list_price",
    "quantity_remaining",
    "inbound",
    "inventory",
    "outbound",
    "date_shipped",
    "carrier",
    "date",
    "location",
    "tracking_number",
    "upc",
    "order_name",
    "payment_status",
    "invoice_date",
    "total",
    "total_saved",
    "full_name",
];

export interface SortConfig {
    key: string;
    order: "asc" | "desc";
}

const isOrderKey = (key: string, obj: Partial<Order>): key is keyof Order => {
    return key in obj;
};

const isShipmentKey = (
    key: string,
    obj: Partial<Shipment>
): key is keyof Shipment => {
    return key in obj;
};

export const useSort = (
    initialSort: SortConfig,
    orders?: Order[],
    setOrders?: React.Dispatch<React.SetStateAction<Order[]>>,
    shipments?: Shipment[],
    setShipments?: React.Dispatch<React.SetStateAction<Shipment[]>>,
    keywords?: string[]
) => {
    const [sortConfig, setSortConfig] = useState<SortConfig>(initialSort);
    const [sortLoading, setSortLoading] = useState(false);

    const [manualSortConfig, setManualSortConfig] =
        useState<SortConfig>(initialSort);

    const toggleSort = (key: string) => {
        if (keywords && orders && setOrders && keywords.length > 0) {
            const newOrder = manualSortConfig.order === "asc" ? "desc" : "asc";
            setOrders((prevOrders) => {
                return [...prevOrders].sort((a, b) => {
                    if (!isOrderKey(key, a) || !isOrderKey(key, b)) {
                        console.log(`Invalid key for sorting: ${key}`);
                        return 0;
                    }

                    let aValue = a[key];
                    let bValue = b[key];

                    // Handle undefined or null values
                    if (aValue == null && bValue == null) return 0;
                    if (aValue == null) return 1;
                    if (bValue == null) return -1;

                    // Handle numbers
                    if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                    ) {
                        return newOrder === "asc"
                            ? aValue - bValue
                            : bValue - aValue;
                    }

                    // Handle dates
                    if (aValue instanceof Date && bValue instanceof Date) {
                        return newOrder === "asc"
                            ? aValue.getTime() - bValue.getTime()
                            : bValue.getTime() - aValue.getTime();
                    }

                    // Handle strings
                    if (
                        typeof aValue === "string" &&
                        typeof bValue === "string"
                    ) {
                        return newOrder === "asc"
                            ? aValue.localeCompare(bValue)
                            : bValue.localeCompare(aValue);
                    }

                    return 0;
                });
            });
            setManualSortConfig((prev) => {
                const newOrder =
                    prev.key === key
                        ? prev.order === "asc"
                            ? "desc"
                            : "asc"
                        : "asc";
                return { key, order: newOrder };
            });
        } else if (
            keywords &&
            shipments &&
            setShipments &&
            keywords.length > 0
        ) {
            const newOrder = manualSortConfig.order === "asc" ? "desc" : "asc";
            setShipments((prevShipments) => {
                return [...prevShipments].sort((a, b) => {
                    if (!isShipmentKey(key, a) || !isShipmentKey(key, b)) {
                        return 0;
                    }

                    let aValue = a[key];
                    let bValue = b[key];

                    // Handle undefined or null values
                    if (aValue == null && bValue == null) return 0;
                    if (aValue == null) return 1;
                    if (bValue == null) return -1;

                    // Handle numbers
                    if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                    ) {
                        return newOrder === "asc"
                            ? aValue - bValue
                            : bValue - aValue;
                    }

                    // Handle dates
                    if (aValue instanceof Date && bValue instanceof Date) {
                        return newOrder === "asc"
                            ? aValue.getTime() - bValue.getTime()
                            : bValue.getTime() - aValue.getTime();
                    }

                    // Handle strings
                    if (
                        typeof aValue === "string" &&
                        typeof bValue === "string"
                    ) {
                        return newOrder === "asc"
                            ? aValue.localeCompare(bValue)
                            : bValue.localeCompare(aValue);
                    }

                    return 0;
                });
            });
            setManualSortConfig((prev) => {
                const newOrder =
                    prev.key === key
                        ? prev.order === "asc"
                            ? "desc"
                            : "asc"
                        : "asc";
                return { key, order: newOrder };
            });
        } else {
            setSortLoading(true);
            setSortConfig((prev) => {
                const newOrder =
                    prev.key === key
                        ? prev.order === "asc"
                            ? "desc"
                            : "asc"
                        : "asc";
                return { key, order: newOrder };
            });
        }
    };

    return {
        sortConfig:
            keywords && keywords.length > 0 ? manualSortConfig : sortConfig,
        toggleSort,
        sortLoading,
        setSortLoading,
    };
};
