/** @format */

import { useState } from "react";
import { useSort } from "../../../../hooks/useSort";
import { Order, ReceivedOrder } from "../../../../models/order";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { Column } from "../../../../tables/models";
import { ActionButton } from "../../../../components/buttons";
import { requestPictures } from "../api/inventoryApi";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../../tables/receivedOrdersTable";

interface RequestPicturesSectionProps {
    selectedOrders?: Order[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    selectedReceivedOrders?: ReceivedOrder[];
    setSelectedReceivedOrders?: React.Dispatch<
        React.SetStateAction<ReceivedOrder[]>
    >;
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RequestPicturesSection({
    selectedOrders,
    setSelectedOrders,
    selectedReceivedOrders,
    setSelectedReceivedOrders,
    setOpenRequestActionsModal,
}: RequestPicturesSectionProps) {
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [notes, setNotes] = useState("");

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );
    const hiddenHeaders: string[] = [
        "select",
        "location",
        "qa",
        "input_shelf_location",
        "input_shipping_quantity",
        "quantity_to_dispose",
    ];

    const [loading, setLoading] = useState(false);

    const handleCreate = async () => {
        setLoading(true);
        const order_ids = selectedOrders
            ? selectedOrders.map((o) => o.order_id)
            : selectedReceivedOrders
            ? selectedReceivedOrders.map((o) => o.received_order_id)
            : [];
        const table_name = selectedOrders ? "orders" : "received_orders";
        const data = await requestPictures(order_ids, notes, table_name);
        if (data.status === "success") {
            setOpenRequestActionsModal(false);
            showNotification("Requested pictures", "", "success");
        } else {
            showNotification("Failed to request pictures.", "", "error");
        }
        setLoading(false);
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                <p className="text-sm text-gray-800 inline-flex gap-x-2">
                    <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    We'll take and upload pictures of the selected units to your
                    dashboard.
                </p>
                {selectedOrders && setSelectedOrders ? (
                    <OrdersTable
                        orderStatus="inbound"
                        orders={selectedOrders}
                        results={null}
                        selectedOrders={[]}
                        setSelectedOrders={setSelectedOrders}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={() => {}}
                        client_type="admin"
                        setTotalSum={setTotalSum}
                        columnOrder={unitsColumnOrder}
                        hiddenHeaders={hiddenHeaders}
                        shipmentInputName={"Quantity Ordered"}
                    />
                ) : selectedReceivedOrders && setSelectedReceivedOrders ? (
                    <ReceivedOrdersTable
                        orders={selectedReceivedOrders}
                        setOrders={setSelectedReceivedOrders}
                        results={null}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={() => {}}
                        setTotalSum={setTotalSum}
                        columnOrder={initialReceivedOrdersColumnOrder}
                        hiddenHeaders={[
                            "select",
                            "order_name",
                            "quantity_to_dispose",
                            "input_shipping_quantity",
                        ]}
                        onRowClick={() => {}}
                        client_type="admin"
                    />
                ) : (
                    <></>
                )}

                <div className="mx-1">
                    <label
                        htmlFor="comment"
                        className="block text-sm/6 font-medium text-gray-900 mx-1"
                    >
                        Notes
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="comment"
                            name="comment"
                            rows={2}
                            className="block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-row-reverse">
                <ActionButton
                    label="Request pictures"
                    handleClick={() => handleCreate()}
                    height={9}
                    loading={loading}
                />
            </div>
        </>
    );
}
