import { apiRequest } from "../../../../utils/api/apiRequest"

export const createWarehouseClarification = async (order_ids: string[], question: string, orderType: "inventory" | "received") => {
    return apiRequest("/admin/worklist/warehouse-clarification", "POST", {order_ids, question, order_type: orderType})
}

export const fetchOrdersFromWarehouseClarification = async (id: number) => {
    return apiRequest(`/admin/worklist/warehouse-clarification/${id}`, "GET")
}

export const updateWarehouseClarification = async (id: number, answer: string, images: File[]) => {
    return apiRequest(`/admin/worklist/warehouse-clarification/${id}`, "PUT", images, true, {answer})
}


export const fetchWarehouseClarifications = async (status: "completed" | "answered" | "not-answered") => {
    return apiRequest(`/admin/worklist/warehouse-clarification?status=${status}`, "GET")
}

export const deleteWarehouseClarification = async (id: number) => {
    return apiRequest(`/admin/worklist/warehouse-clarification/${id}`, "DELETE")
}

export const markWarehouseClarificationTaskComplete = async (id: number) => {
    return apiRequest(`/admin/worklist/warehouse-clarification/completed/${id}`, "PUT")
}