/** @format */

import { useEffect, useState } from "react";
import { useNotification } from "../../../utils/notification/notificationContext";
import ModalLayout from "../../../components/modalLayout";
import { fetchEmployeeErrorLogs } from "./api/employeesApi";
import LoadingWheel from "../../../components/loadingWheel";
import { convertToESTV2 } from "../../../utils/date/date_formatter";

interface EmployeeErrorLog {
    id: number;
    created_at: string;
    employee_id: string;
    action_type: string;
    quantity: number;
    notes: string;
}

interface EmployeeErrorsModalProps {
    openEmployeeErrorsModal: boolean;
    setOpenEmployeeErrorsModal: React.Dispatch<React.SetStateAction<boolean>>;
    actionType: string;
}

function EmployeeErrorsModal({
    openEmployeeErrorsModal,
    setOpenEmployeeErrorsModal,
    actionType,
}: EmployeeErrorsModalProps) {
    const { showNotification } = useNotification();

    const [logs, setLogs] = useState<EmployeeErrorLog[]>([]);

    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        const data = await fetchEmployeeErrorLogs(actionType);
        if (data.status === "success") {
            setLogs(data.data.logs);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (openEmployeeErrorsModal) {
            fetchData();
        }
    }, [openEmployeeErrorsModal]);

    return (
        <ModalLayout
            title="Employee Error Logs"
            height="h-4/5"
            width="sm:max-w-3xl"
            openModal={openEmployeeErrorsModal}
            setOpenModal={setOpenEmployeeErrorsModal}
        >
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                {!loading ? (
                    <div>
                        {logs.map((log, index) => (
                            <div
                                className={`flex flex-col ${
                                    index !== 0 && "pt-3"
                                }`}
                            >
                                <p className="text-sm font-medium">
                                    {log.quantity} units
                                </p>
                                <p className="text-sm text-gray-800">
                                    Note: {log.notes !== "" ? log.notes : "N/A"}
                                </p>
                                <p className="text-sm text-gray-800">
                                    {convertToESTV2(
                                        log.created_at,
                                        true,
                                        false
                                    )}
                                </p>
                                <div className="pt-3">
                                    <div className="w-full h-px bg-gray-200"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-row items-center justify-center h-full w-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </ModalLayout>
    );
}

export default EmployeeErrorsModal;
