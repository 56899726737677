import Cookies from "js-cookie"
import { buildUrl } from "../../../../utils/url/buildUrl";
import { SortConfig } from "../../../../hooks/useSort";
import { apiRequest } from "../../../../utils/api/apiRequest";


export const fetchShipments = async (searchParams: URLSearchParams, sortConfig: SortConfig, shipment_status: string) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/outbound`;
        const params = {
            offset: searchParams.get('offset'),
            limit: searchParams.get('limit'),
            status: shipment_status,
            sort_key: sortConfig.key,
            sort_order: sortConfig.order,
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const fetchOutboundOrders = async (
    shipmentId: string,
    offset: number
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/v1/outbound/${shipmentId}?offset=${offset}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const fetchShipmentAndOrders = async (
    shipmentId: string,
    offset: number,
    user_id?: string | undefined,
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/v1/shipments/${shipmentId}?offset=${offset}${user_id ? `&user_id=${encodeURIComponent(user_id)}` : ""}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const fetchAllShipmentTrackings = async (searchParams: URLSearchParams, sortConfig: SortConfig) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/outbound/shipments/trackings`;
        const params = {
            offset: searchParams.get('offset'),
            limit: searchParams.get('limit'),
            sort_key: sortConfig ? sortConfig.key : "created_at",
            sort_order: sortConfig ? sortConfig.order : "desc"
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const unrequestOutboundShipment = async (
    shipment_id: string
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/outbound/unrequest/" + shipment_id,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}

export const fetchShippingDetailRequests = async () => {
    return apiRequest("/shipping-details", "GET")
}

export const fetchShippingDetailOrders = async (requested_shipping_details_id: string, user_id: string | null | undefined) => {
    return apiRequest(`/shipping-details/${requested_shipping_details_id}${user_id ? `?user_id=${user_id}` : ""}`, "GET")
}

export const fetchFbaShipmentRequests = async () => {
    return apiRequest("/outbound/fba-shipment-requests", "GET")
}


export const fetchPictureRequests = async () => {
    return apiRequest("/outbound/picture-requests", "GET")
}