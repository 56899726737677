/** @format */

"use client";

import { useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { requestFBAShipments } from "../api/inventoryApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import LoadingWheel from "../../../../components/loadingWheel";
import { ActionButton } from "../../../../components/buttons";
import { InputTextField } from "../../../../components/inputField";
import { Order } from "../../../../models/order";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

interface RequestFBAShipmentsProps {
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrders: Order[];
}

export default function RequestFBAShipments({
    setOpenRequestActionsModal,
    selectedOrders,
}: RequestFBAShipmentsProps) {
    const { showNotification } = useNotification();

    const [notes, setNotes] = useState("");

    const [loading, setLoading] = useState(false);
    const handleRequest = async () => {
        setLoading(true);
        const data = await requestFBAShipments(notes);
        if (data.status === "success") {
            setOpenRequestActionsModal(false);
            showNotification("Requested FBA shipments.", "", "success");
        } else {
            showNotification(
                "Failed to request shipments.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                {selectedOrders.every((o) => o.fulfilled_by === "amazon") ? (
                    <>
                        <p className="text-sm text-gray-800 inline-flex gap-x-2">
                            <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                            We'll create an FBA shipment for the selected
                            inventory using your InventoryLabs or Boxem
                            credentials.
                        </p>
                        <div className="sm:flex sm:items-start pt-1">
                            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                                <CheckCircleIcon
                                    aria-hidden="true"
                                    className="size-6 text-green-600"
                                />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold text-gray-900"
                                >
                                    Request FBA Shipments
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Confirm you'd like the warehouse to
                                        package and ship out your current
                                        inventory.
                                    </p>
                                </div>
                                <div className="pt-4">
                                    <InputTextField
                                        label="Notes"
                                        value={notes}
                                        onChange={(e) =>
                                            setNotes(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <span className="text-sm text-gray-700 pt-6 flex flex-row items-center gap-x-2 pl-1">
                        <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                        You cannot make an fba shipment request unless all
                        selected orders are fulfilled by amazon.
                    </span>
                )}
            </div>

            <div className="flex flex-row-reverse">
                <ActionButton
                    label="Request shipments"
                    handleClick={() => handleRequest()}
                    height={9}
                    loading={loading}
                    viewDisabled={
                        !selectedOrders.every(
                            (o) => o.fulfilled_by === "amazon"
                        )
                    }
                />
            </div>
        </>
    );
}
