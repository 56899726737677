/** @format */

import { useState } from "react";
import { Switch } from "@headlessui/react";

interface ToggleButtonWithIconProps {
    enabled: boolean;
    setEnabled: (value: boolean) => void;
}

export default function ToggleButtonWithIcon({
    enabled,
    setEnabled,
}: ToggleButtonWithIconProps) {
    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2 data-[checked]:bg-accent"
        >
            <span className="sr-only">Use setting</span>
            <span className="pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
                <span
                    aria-hidden="true"
                    className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                >
                    <svg
                        fill="none"
                        viewBox="0 0 12 12"
                        className="size-3 text-gray-400"
                    >
                        <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
                <span
                    aria-hidden="true"
                    className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                >
                    <svg
                        fill="currentColor"
                        viewBox="0 0 12 12"
                        className="size-3 text-accent"
                    >
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                </span>
            </span>
        </Switch>
    );
}
