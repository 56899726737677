/** @format */

import { useEffect, useState } from "react";
import AdminDashboardLayout from "../../../../components/admin/dashboardLayout";
import FbaShipmentRequestsTable, {
    initialFbaShipmentRequestsColumnOrder,
} from "../../../../tables/fbaShipmentRequestsTable";
import { useClient } from "../../../../utils/client/clientContext";
import LoadingWheel from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import { FbaShipmentRequest } from "../../../../models/clients";
import { fetchWorklist } from "../api/worklistApi";
import { useSort } from "../../../../hooks/useSort";

function AdminFbaShipmentRequestsPage() {
    const { user_id, orderCount } = useClient();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(false);

    const [fbaShipmentRequests, setFbaShipmentRequests] = useState<
        FbaShipmentRequest[]
    >([]);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchWorklist("fba_requested_shipments", true);
            if (data.status === "success") {
                setFbaShipmentRequests(data.data.fba_requested_shipments.data);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <AdminDashboardLayout user_id={user_id} orderCount={orderCount}>
            <div className="flex flex-row items-center justify-between">
                <span className="text-2xl font-bold">
                    FBA Shipment Requests
                </span>
            </div>
            {!loading ? (
                <FbaShipmentRequestsTable
                    fbaShipmentRequests={fbaShipmentRequests}
                    setFbaShipmentRequests={setFbaShipmentRequests}
                    results={results}
                    sortLoading={sortLoading}
                    sortConfig={sortConfig}
                    toggleSort={toggleSort}
                    handleRefetch={handleRefetch}
                    emptyTableText="You have no fba shipment requests."
                    columnOrder={initialFbaShipmentRequestsColumnOrder}
                />
            ) : (
                <div className="flex flex-grow items-center pb-44 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            No fba shipment requests found.
                        </span>
                    )}
                </div>
            )}
        </AdminDashboardLayout>
    );
}

export default AdminFbaShipmentRequestsPage;
