/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import EmployeeClockEventsTable from "../../../tables/employeeClockEventsTable";
import { Employee, EmployeeClockEvent } from "../../../models/employees";
import Results from "../../../models/results";
import { useSort } from "../../../hooks/useSort";
import {
    clockInEmployee,
    clockOutEmployee,
    deleteClockEvent,
    fetchClockEvents,
    fetchEmployees,
    updateClockEventPaymentStatus,
} from "./api/employeesApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import MediumSelector from "../../../components/dropdowns/mediumSelector";
import LoadingWheel from "../../../components/loadingWheel";
import TimeSelector, {
    formatSelectorTimeToUTC,
} from "../../../components/dropdowns/timeSelector";
import SingleCalendar from "../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useClient } from "../../../utils/client/clientContext";
import { ActionButton, SecondaryButton } from "../../../components/buttons";
import { getAdminType } from "../../../utils/adminTypes/actions";
import { getClientType } from "../../../utils/clientTypes/actions";
import { capitalizeFirstLetter } from "../../../utils/strings/string";
import LogOutModal from "./logOutModal";
import { initialEmployeesKpisColumnOrder } from "../../../tables/employeesKpisTable";
import { fetchEmployeesKpis } from "../audits/api/kpisApi";
import { EmployeeKpi } from "../audits/kpis/employees/page";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import EmployeeErrorsModal from "./employeeErrorsModal";

function AdminEmployeesPage() {
    const { orderCount } = useClient();
    const isAdmin =
        ["owner"].includes(getClientType() || "") ||
        ["owner"].includes(getAdminType() || "");

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [clockEvents, setClockEvents] = useState<EmployeeClockEvent[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [selectedClockEvents, setSelectedClockEvents] = useState<
        EmployeeClockEvent[]
    >([]);

    const [refetchData, setRefetchData] = useState(false);
    const handleRefetch = () => {
        setRefetchData((prev) => !prev);
    };

    const [employees, setEmployees] = useState<Employee[]>([]);

    const [totalHoursWorked, setTotalHoursWorked] = useState("");
    const [totalPaidHours, setTotalPaidHours] = useState("");
    const [totalDaysWorked, setTotalDaysWorked] = useState("");
    const [totalDaysPaid, setTotalDaysPaid] = useState("");

    const selectorData = [
        { id: "null", name: "All", value: "all" },
        ...employees.map((employee) => ({
            id: employee.employee_id,
            name: employee.name,
            value: employee.email,
        })),
    ];

    const [tableLoading, setTableLoading] = useState(false);
    const [cardsLoading, setCardsLoading] = useState(false);
    const [clockLoading, setClockLoading] = useState(false);

    const fetchData = async () => {
        setTableLoading(true);
        const data = await fetchClockEvents(
            selectedEmployeeDetails?.employee_id || "all",
            searchParams
        );
        if (data.status === "success") {
            setClockEvents(data.data.clock_events);
            setResults(data.data.results);

            if (data.data.clock_events.length > 0) {
                console.log(data.data.clock_events[0]);
            }
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setTableLoading(false);
    };

    const fetchEmployeesData = async () => {
        setCardsLoading(true);
        const data = await fetchEmployees();
        if (data.status === "success") {
            setEmployees(data.data.employees);
            setTotalHoursWorked(data.data.total_hours);
            setTotalPaidHours(data.data.total_paid_hours);
            setTotalDaysWorked(data.data.total_days_worked);
            setTotalDaysPaid(data.data.total_days_paid);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setCardsLoading(false);
    };

    const [selectedEmployee, setSelectedEmployee] = useState<{
        id: string;
        name: string;
        value: string;
    }>({
        id: "0",
        name: "Select an employee",
        value: "",
    });
    const selectedEmployeeDetails = employees.find(
        (employee) => employee.employee_id === selectedEmployee.id
    );

    useEffect(() => {
        if (selectedEmployee.id !== "0") {
            fetchData();
        }
    }, [selectedEmployee]);

    useEffect(() => {
        fetchData();
        fetchEmployeesData();
    }, [refetchData]);

    useEffect(() => {
        if (!isAdmin) {
            fetchDataEmployeeKpis();
        }
    }, []);

    const handleClockChange = async (employee_id: string) => {
        if (!selectedEmployeeDetails) {
            setClockLoading(true);
            if (employees.length > 0 && employees[0].is_clocked_in) {
                const data = await clockOutEmployee(
                    formatSelectorTimeToUTC(
                        selectedHour + " " + selectedPeriod,
                        selectedMinute,
                        selectedDate
                    )
                );
                if (data.status === "success") {
                    handleRefetch();
                    showNotification("Clocked out successfully", "", "success");
                    setOpenLogOutModal(true);
                } else {
                    showNotification(
                        "An error occurred",
                        data.message,
                        "error"
                    );
                }
            } else if (employees.length > 0) {
                const data = await clockInEmployee(
                    formatSelectorTimeToUTC(
                        selectedHour + " " + selectedPeriod,
                        selectedMinute,
                        selectedDate
                    )
                );
                if (data.status === "success") {
                    handleRefetch();
                    showNotification("Clocked in successfully", "", "success");
                } else {
                    showNotification(
                        "An error occurred",
                        data.message,
                        "error"
                    );
                }
            }
            setClockLoading(false);
        }
    };

    const handleDelete = async (event_id: string) => {
        const data = await deleteClockEvent(event_id);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Deleted event", "", "success");
        } else {
            showNotification("Action failed.", data.message, "error");
        }
    };

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [selectedHour, setSelectedHour] = useState("");
    const [selectedPeriod, setSelectedPeriod] = useState("");
    const [selectedMinute, setSelectedMinute] = useState("");

    const stats = [
        {
            name: "Total hours worked",
            stat:
                !isAdmin && employees.length > 0
                    ? employees[0].total_hours_worked
                    : selectedEmployeeDetails
                    ? selectedEmployeeDetails.total_hours_worked
                    : totalHoursWorked,
            note: `${
                !isAdmin && employees.length > 0
                    ? employees[0].total_paid_hours
                    : selectedEmployeeDetails
                    ? selectedEmployeeDetails.total_paid_hours
                    : totalPaidHours
            } paid hours`,
            note2: `${
                !isAdmin && employees.length > 0
                    ? employees[0].total_unpaid_hours
                    : selectedEmployeeDetails
                    ? selectedEmployeeDetails.total_unpaid_hours
                    : Number(
                          Number(totalHoursWorked) - Number(totalPaidHours)
                      ).toFixed(2)
            } unpaid hours`,
        },
        {
            name: "Is clocked in",
            stat:
                !isAdmin && employees.length > 0
                    ? capitalizeFirstLetter(String(employees[0].is_clocked_in))
                    : selectedEmployeeDetails?.is_clocked_in
                    ? "True"
                    : "False",
        },
        {
            name: "Total days worked",
            stat:
                !isAdmin && employees.length > 0
                    ? employees[0].total_days_worked
                    : selectedEmployeeDetails
                    ? selectedEmployeeDetails.total_days_worked
                    : totalDaysWorked,
            note: `${
                !isAdmin && employees.length > 0
                    ? employees[0].total_days_paid
                    : selectedEmployeeDetails
                    ? selectedEmployeeDetails.total_days_paid
                    : totalDaysPaid
            } paid days`,
            note2: `${
                !isAdmin && employees.length > 0
                    ? Number(
                          Number(employees[0].total_days_worked) -
                              Number(employees[0].total_days_paid)
                      )
                    : selectedEmployeeDetails
                    ? Number(
                          Number(selectedEmployeeDetails.total_days_worked) -
                              Number(selectedEmployeeDetails.total_days_paid)
                      )
                    : Number(
                          Number(totalDaysWorked) - Number(totalDaysPaid)
                      ).toFixed(0)
            } unpaid days`,
        },
    ];

    const [totalSum, setTotalSum] = useState("");

    const handleUpdatePaymentStatus = async (is_paid: boolean) => {
        const eventIds = selectedClockEvents.map((e) => e.event_id);
        const data = await updateClockEventPaymentStatus(eventIds, is_paid);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Updated paid status.", "", "success");
        } else {
            showNotification(
                "Failed to update paid status.",
                data.message,
                "error"
            );
        }
    };

    const [openLogOutModal, setOpenLogOutModal] = useState(false);

    const [employeeKpis, setEmployeeKpis] = useState<EmployeeKpi | null>(null);
    const fetchDataEmployeeKpis = async () => {
        const data = await fetchEmployeesKpis("all", null, null);
        if (data.status === "success") {
            if (data.data.kpis.length > 0) {
                setEmployeeKpis(data.data.kpis[0]);
            }
        }
    };

    const [openEmployeeErrorsModal, setOpenEmployeeErrorsModal] =
        useState(false);
    const [selectedActionType, setSelectedActionType] = useState<string | null>(
        null
    );
    useEffect(() => {
        if (selectedActionType) {
            setOpenEmployeeErrorsModal(true);
        }
    }, [selectedActionType]);

    useEffect(() => {
        if (!openEmployeeErrorsModal) {
            setSelectedActionType(null);
        }
    }, [openEmployeeErrorsModal]);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header />

                <div className="flex flex-row items-center justify-between">
                    <span className="text-2xl font-bold">Employees</span>
                </div>

                {!tableLoading && !cardsLoading ? (
                    <>
                        <div className="pb-4">
                            <div className="flex flex-row items-center justify-between pt-10">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">
                                    Statistics for{" "}
                                    {selectedEmployeeDetails
                                        ? selectedEmployeeDetails.name
                                        : "employees"}
                                </h3>
                                <div className="flex flex-row items-center space-x-4">
                                    {selectedClockEvents.length > 0 &&
                                        isAdmin && (
                                            <SecondaryButton
                                                label="Mark unpaid"
                                                handleClick={() =>
                                                    handleUpdatePaymentStatus(
                                                        false
                                                    )
                                                }
                                                height={8}
                                            />
                                        )}
                                    {selectedClockEvents.length > 0 &&
                                        isAdmin && (
                                            <ActionButton
                                                label="Mark paid"
                                                handleClick={() =>
                                                    handleUpdatePaymentStatus(
                                                        true
                                                    )
                                                }
                                                height={8}
                                            />
                                        )}
                                </div>
                            </div>

                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                                {employeeKpis &&
                                    initialEmployeesKpisColumnOrder
                                        .filter(
                                            (item) =>
                                                item.key.includes("avg") &&
                                                item.key !== "employee_name"
                                        )
                                        .map((header) => (
                                            <div
                                                key={header.key}
                                                className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 border border-gray-200"
                                            >
                                                <div className="flex flex-col items-start">
                                                    <p className="text-xs font-medium text-gray-800">
                                                        {header.label}
                                                    </p>

                                                    {header.key ===
                                                    "avg_fba_shipment_time" ? (
                                                        <td className="text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="font-semibold">
                                                                    {
                                                                        employeeKpis.avg_fba_shipment_time
                                                                    }
                                                                </p>
                                                                {employeeKpis.total_outbound_fba_units && (
                                                                    <p>
                                                                        {employeeKpis.total_outbound_fba_units.toLocaleString()}{" "}
                                                                        units
                                                                        processed
                                                                    </p>
                                                                )}
                                                                {employeeKpis.total_outbound_fba_units_errors &&
                                                                employeeKpis.total_outbound_fba_units_errors >
                                                                    0 ? (
                                                                    <p className="text-sm text-red-500 inline-flex items-center gap-x-3">
                                                                        {employeeKpis.total_outbound_fba_units_errors.toLocaleString()}{" "}
                                                                        errors •{" "}
                                                                        {(
                                                                            (employeeKpis.total_outbound_fba_units_errors /
                                                                                employeeKpis.total_outbound_fba_units) *
                                                                            100
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        %
                                                                        <InformationCircleIcon
                                                                            onClick={() =>
                                                                                setSelectedActionType(
                                                                                    "fba"
                                                                                )
                                                                            }
                                                                            className="h-4 w-4 hover:text-red-400 cursor-pointer"
                                                                        />
                                                                    </p>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </td>
                                                    ) : header.key ===
                                                      "avg_merchant_order_time" ? (
                                                        <td className="text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="font-semibold">
                                                                    {
                                                                        employeeKpis.avg_merchant_order_time
                                                                    }
                                                                </p>
                                                                {employeeKpis.total_outbound_merchant_units && (
                                                                    <p>
                                                                        {employeeKpis.total_outbound_merchant_units.toLocaleString()}{" "}
                                                                        units
                                                                        processed
                                                                    </p>
                                                                )}
                                                                {employeeKpis.total_outbound_merchant_units_errors &&
                                                                employeeKpis.total_outbound_merchant_units_errors >
                                                                    0 ? (
                                                                    <p className="text-sm text-red-500 inline-flex items-center gap-x-3">
                                                                        {employeeKpis.total_outbound_merchant_units_errors.toLocaleString()}{" "}
                                                                        errors •{" "}
                                                                        {(
                                                                            (employeeKpis.total_outbound_merchant_units_errors /
                                                                                employeeKpis.total_outbound_merchant_units) *
                                                                            100
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        %
                                                                        <InformationCircleIcon
                                                                            onClick={() =>
                                                                                setSelectedActionType(
                                                                                    "merchant"
                                                                                )
                                                                            }
                                                                            className="h-4 w-4 hover:text-red-400 cursor-pointer"
                                                                        />
                                                                    </p>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </td>
                                                    ) : header.key ===
                                                      "avg_received_time" ? (
                                                        <td className="text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="font-semibold">
                                                                    {
                                                                        employeeKpis.avg_received_time
                                                                    }
                                                                </p>
                                                                {employeeKpis.total_received_units_processed && (
                                                                    <p>
                                                                        {employeeKpis.total_received_units_processed.toLocaleString()}{" "}
                                                                        units
                                                                        processed
                                                                    </p>
                                                                )}
                                                                {employeeKpis.total_received_units_processed_errors &&
                                                                employeeKpis.total_received_units_processed_errors >
                                                                    0 ? (
                                                                    <p className="text-sm text-red-500 inline-flex items-center gap-x-3">
                                                                        {employeeKpis.total_received_units_processed_errors.toLocaleString()}{" "}
                                                                        errors •{" "}
                                                                        {(
                                                                            (employeeKpis.total_received_units_processed_errors /
                                                                                employeeKpis.total_received_units_processed) *
                                                                            100
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        %
                                                                        <InformationCircleIcon
                                                                            onClick={() =>
                                                                                setSelectedActionType(
                                                                                    "receiving"
                                                                                )
                                                                            }
                                                                            className="h-4 w-4 hover:text-red-400 cursor-pointer"
                                                                        />
                                                                    </p>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </td>
                                                    ) : header.key ===
                                                      "avg_reshipping_time_from_received" ? (
                                                        <td className="text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="font-semibold">
                                                                    {
                                                                        employeeKpis.avg_reshipping_time_from_received
                                                                    }
                                                                </p>
                                                                {employeeKpis.total_outbound_parcel_forwarding_units && (
                                                                    <p>
                                                                        {employeeKpis.total_outbound_parcel_forwarding_units.toLocaleString()}{" "}
                                                                        units
                                                                        processed
                                                                    </p>
                                                                )}
                                                                {employeeKpis.total_outbound_parcel_forwarding_units_errors &&
                                                                employeeKpis.total_outbound_parcel_forwarding_units_errors >
                                                                    0 ? (
                                                                    <p className="text-sm text-red-500 inline-flex items-center gap-x-3">
                                                                        {employeeKpis.total_outbound_parcel_forwarding_units_errors.toLocaleString()}{" "}
                                                                        errors •{" "}
                                                                        {(
                                                                            (employeeKpis.total_outbound_parcel_forwarding_units_errors /
                                                                                employeeKpis.total_outbound_parcel_forwarding_units) *
                                                                            100
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        %
                                                                        <InformationCircleIcon
                                                                            onClick={() =>
                                                                                setSelectedActionType(
                                                                                    "parcel-forwarding"
                                                                                )
                                                                            }
                                                                            className="h-4 w-4 hover:text-red-400 cursor-pointer"
                                                                        />
                                                                    </p>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                            </dl>
                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                {stats.map((item) => (
                                    <div
                                        key={item.name}
                                        className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border border-gray-200"
                                    >
                                        <dt className="truncate text-sm font-medium text-gray-500">
                                            {item.name}
                                        </dt>
                                        <div className="flex flex-row items-end justify-between">
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                                {item.stat}
                                            </dd>
                                            {item.note && item.note2 && (
                                                <div>
                                                    <dd className="text-sm tracking-tight text-gray-500">
                                                        {item.note}
                                                    </dd>
                                                    <dd className="text-sm tracking-tight text-gray-500">
                                                        {item.note2}
                                                    </dd>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </dl>
                            <div className="flex flex-col pt-8">
                                <h1 className="font-semibold">
                                    Record your clock in/out
                                </h1>
                                <div className="flex flex-row pt-4 space-x-5">
                                    {isAdmin && (
                                        <MediumSelector
                                            selected={selectedEmployee}
                                            setSelected={setSelectedEmployee}
                                            selectorData={selectorData}
                                            maxHeight="max-h-120"
                                        />
                                    )}
                                    {(selectedEmployeeDetails || !isAdmin) && (
                                        <>
                                            <div className="w-44">
                                                <SingleCalendar
                                                    selectedDate={selectedDate}
                                                    setSelectedDate={
                                                        setSelectedDate
                                                    }
                                                    label={""}
                                                />
                                            </div>
                                            <TimeSelector
                                                selectedHour={selectedHour}
                                                setSelectedHour={
                                                    setSelectedHour
                                                }
                                                selectedMinute={selectedMinute}
                                                setSelectedMinute={
                                                    setSelectedMinute
                                                }
                                                selectedPeriod={selectedPeriod}
                                                setSelectedPeriod={
                                                    setSelectedPeriod
                                                }
                                            />
                                            <button
                                                onClick={() =>
                                                    handleClockChange(
                                                        selectedEmployeeDetails?.employee_id ||
                                                            ""
                                                    )
                                                }
                                                className="py-2 w-24 text-sm text-white bg-accent hover:bg-accenthighlight px-2 rounded-md"
                                            >
                                                {!clockLoading ? (
                                                    <>
                                                        {employees.length > 0 &&
                                                        employees[0]
                                                            .is_clocked_in
                                                            ? "Clock out"
                                                            : "Clock in"}
                                                    </>
                                                ) : (
                                                    <LoadingWheel color="white" />
                                                )}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row-reverse items-center">
                            {totalSum !== "" && (
                                <span className="text-gray-700 text-sm">
                                    Sum: {totalSum}
                                </span>
                            )}
                        </div>
                        {!tableLoading ? (
                            <EmployeeClockEventsTable
                                clockEvents={clockEvents}
                                results={results}
                                selectedEvents={selectedClockEvents}
                                setSelectedEvents={setSelectedClockEvents}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={() => {}}
                                handleDelete={handleDelete}
                                setTotalSum={setTotalSum}
                            />
                        ) : (
                            <div className="flex flex-row items-center justify-center pt-44">
                                <LoadingWheel />
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex flex-row items-center justify-center pt-44">
                        <LoadingWheel />
                    </div>
                )}
            </div>

            <LogOutModal
                openLogOutModal={openLogOutModal}
                setOpenLogOutModal={setOpenLogOutModal}
            />
            <EmployeeErrorsModal
                openEmployeeErrorsModal={openEmployeeErrorsModal}
                setOpenEmployeeErrorsModal={setOpenEmployeeErrorsModal}
                actionType={selectedActionType || ""}
            />
        </div>
    );
}

export default AdminEmployeesPage;
