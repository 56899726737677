/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../../../components/admin/sideBar";
import Header from "../../../../../components/header";
import { useClient } from "../../../../../utils/client/clientContext";
import LoadingWheel from "../../../../../components/loadingWheel";
import EmployeesKpisTable, {
    initialEmployeesKpisColumnOrder,
} from "../../../../../tables/employeesKpisTable";
import Results from "../../../../../models/results";
import { useSort } from "../../../../../hooks/useSort";
import { fetchEmployeesKpis, fetchKpiMetrics } from "../../api/kpisApi";
import TabSelector from "../../../../../components/tabSelector";
import { kpiTabs } from "../tabs";
import MediumSelector from "../../../../../components/dropdowns/mediumSelector";
import CustomWeekRangeCalendar from "../../../employees/schedule/customWeekRangeCalendar";
import dayjs, { Dayjs } from "dayjs";
import { KpiMetric } from "../../../../../models/kpi";
import KpisTable, {
    initialKpisColumnOrder,
} from "../../../../../tables/kpisTable";
import { useSearchParams } from "react-router-dom";
import { Employee } from "../../../../../models/employees";
import { fetchEmployees } from "../../../employees/api/employeesApi";
import { useNotification } from "../../../../../utils/notification/notificationContext";

function AdminKpiMetricsPage() {
    const { user_id, orderCount } = useClient();
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const [kpis, setKpis] = useState<KpiMetric[]>([]);
    const [selectedKpi, setSelectedKpi] = useState<KpiMetric | null>(null);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<{
        id: string;
        name: string;
        value: string;
    }>({ id: "null", name: "All employees", value: "all" });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchKpiMetrics(
                searchParams,
                sortConfig,
                selectedEmployee.id
            );
            if (data.status === "success") {
                setKpis(data.data.kpis);
                setResults(data.data.results);
            }
            setLoading(false);
        };

        fetchData();
    }, [refetchData, selectedEmployee]);

    const selectorData = [
        { id: "null", name: "All employees", value: "all" },
        ...employees.map((employee) => ({
            id: employee.employee_id,
            name: employee.name,
            value: employee.email,
        })),
    ];

    const fetchEmployeesData = async () => {
        const data = await fetchEmployees();
        if (data.status === "success") {
            setEmployees(data.data.employees);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
    };

    useEffect(() => {
        fetchEmployeesData();
    }, []);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">KPI Metrics</span>
                    </div>
                </div>

                <div className="sm:w-64 w-full pt-4">
                    <MediumSelector
                        selected={selectedEmployee}
                        setSelected={setSelectedEmployee}
                        selectorData={selectorData}
                    />
                </div>

                {!loading ? (
                    <KpisTable
                        kpis={kpis}
                        results={results}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={handleRefetch}
                        emptyTableText="You have no kpi metrics."
                        columnOrder={initialKpisColumnOrder}
                    />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminKpiMetricsPage;
