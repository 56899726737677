import { Box } from "./adminDashboard";


interface Shipment {
    created_at: string,
    shipment_id: string,
    user_id: string,
    full_name: string,
    name: string,
    date: string,
    notes: string,
    payment_status: number,
    status: string,
    trackings: ShipmentTracking[]
    admin_status: string
    boxes: Box[]
    total_quantity_shipped: number
    shipment_value: number
    services: ShipmentService[]
    date_shipped: string
    date_requested: string
    fulfilled_by: string
}

export default Shipment;

export type ShipmentType = "shipped" | "requested"

export interface ShipmentTracking {
    tracking_id: string
    carrier: string
    tracking: string
    tracking_url: string
}

export interface ShipmentService {
    label: string
    name: string,
    price_per_unit: number
    quantity: number | null
}

export const serviceOptions: ShipmentService[] = [
    {
        label: "Sneakers - $0.50/unit",
        name: "Sneakers",
        price_per_unit: 50, // in cents
        quantity: 0
    },
    {
        label: "Fragile (bubble wrap) - $0.25/unit",
        name: "Bubble Wrap",
        price_per_unit: 25, // in cents
        quantity: 0
    },
    {
        label: "Returns",
        name: "Returns",
        price_per_unit: 0, // in cents
        quantity: 0
    },
    {
        label: "Palletization - $30/unit",
        name: "Palletization",
        price_per_unit: 3000, // in cents
        quantity: 0
    },
    {
        label: "Multi Pack - $0.25/unit",
        name: "Multi-pack",
        price_per_unit: 25, // in cents
        quantity: 0
    },
    {
        label: "Bundle - $0.25/unit",
        name: "Bundle",
        price_per_unit: 15, // in cents
        quantity: 0
    },
    {
        label: "Oversized Items - $0.50/unit",
        name: "Oversized Items",
        price_per_unit: 50, // in cents
        quantity: 0
    },
];