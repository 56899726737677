/** @format */

import Header from "../header";
import ClientSideBar from "./sideBar";
import AdminSideBar from "./sideBar";

interface ClientDashboardLayoutProps {
    children: React.ReactNode;
}

const ClientDashboardLayout: React.FC<ClientDashboardLayoutProps> = ({
    children,
}) => {
    return (
        <div className="flex flex-row h-screen">
            <ClientSideBar />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header />
                {children}
            </div>
        </div>
    );
};

export default ClientDashboardLayout;
