/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../../../components/admin/sideBar";
import Header from "../../../../../components/header";
import { useClient } from "../../../../../utils/client/clientContext";
import LoadingWheel from "../../../../../components/loadingWheel";
import Results from "../../../../../models/results";
import { useSort } from "../../../../../hooks/useSort";
import { fetchGeneralKpis } from "../../api/kpisApi";
import TabSelector from "../../../../../components/tabSelector";
import { kpiTabs } from "../tabs";
import GeneralKpiMetrics from "./generalKpiMetrics";
import MediumSelector from "../../../../../components/dropdowns/mediumSelector";
import CustomWeekRangeCalendar from "../../../employees/schedule/customWeekRangeCalendar";
import dayjs, { Dayjs } from "dayjs";

export interface GeneralKpis {
    avg_order_received_to_checked_in: string;
    avg_requested_details_response_time: string;
    avg_shipment_request_to_confirmation: string;
}

function AdminGeneralKpisPage() {
    const { user_id, orderCount } = useClient();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(false);

    const [generalKpis, setGeneralKpis] = useState<GeneralKpis | null>(null);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [selectedDateRange, setSelectedDateRange] = useState<{
        id: string;
        name: string;
        value: string;
    }>({
        id: "1",
        name: "All",
        value: "all",
    });
    const [selectedRange, setSelectedRange] = useState<{
        start: Dayjs | null;
        end: Dayjs | null;
    }>({
        start: null,
        end: null,
    });

    const selectorData = [
        { id: "1", name: "All", value: "all" },
        { id: "2", name: "Last 30 days", value: "last-30-days" },
        { id: "3", name: "Last 7 days", value: "last-7-days" },
        { id: "4", name: "Today", value: "today" },
        { id: "5", name: "Custom", value: "custom" },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const formattedStart = selectedRange.start
                ? selectedRange.start.format("YYYY-MM-DD")
                : null;
            const formattedEnd = selectedRange.end
                ? selectedRange.end.format("YYYY-MM-DD")
                : null;
            const data = await fetchGeneralKpis(
                selectedDateRange.value,
                formattedStart,
                formattedEnd
            );
            if (data.status === "success") {
                setGeneralKpis(data.data.kpis);
            }
            setLoading(false);
        };

        if (selectedDateRange.value === "custom" && !selectedRange.end) {
            return;
        }

        fetchData();
    }, [selectedDateRange, selectedRange]);

    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">KPI's</span>
                    </div>
                </div>

                <TabSelector tabs={kpiTabs(orderCount)} />

                <div className="flex flex-row items-center space-x-4 pt-4 pb-2">
                    <div className="w-44">
                        <MediumSelector
                            selected={selectedDateRange}
                            setSelected={setSelectedDateRange}
                            selectorData={selectorData}
                        />
                    </div>
                    {selectedDateRange.value === "custom" && (
                        <CustomWeekRangeCalendar
                            selectedRange={selectedRange}
                            setSelectedRange={setSelectedRange}
                            currentDate={currentDate}
                            setCurrentDate={setCurrentDate}
                            isCalendarVisible={isCalendarVisible}
                            setIsCalendarVisible={setIsCalendarVisible}
                            includeBorder={true}
                        />
                    )}
                </div>

                {!loading ? (
                    <GeneralKpiMetrics generalKpis={generalKpis} />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminGeneralKpisPage;
