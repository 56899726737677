/** @format */

import { useEffect } from "react";
import { GeneralKpis } from "./page";

export const generalMetrics = [
    {
        label: "Average Shipment Requested to Shipped",
        key: "avg_shipment_request_to_confirmation",
    },
    {
        label: "Average Requested Details Response Time",
        key: "avg_requested_details_response_time",
    },
    {
        label: "Average Shipment Received to Checked In",
        key: "avg_order_received_to_checked_in",
    },
];

export default function GeneralKpiMetrics({
    generalKpis,
}: {
    generalKpis: GeneralKpis | null;
}) {
    return (
        <div className="pb-10">
            <div className="grid grid-cols-1 gap-px bg-gray-300 sm:grid-cols-2 md:grid-cols-3">
                {generalKpis &&
                    generalMetrics.map((item) => (
                        <div className="bg-white px-4 py-6 sm:px-6 lg:px-8">
                            <p className="text-sm font-medium leading-6 text-gray-600">
                                {item.label}
                            </p>
                            <p className="mt-2 flex items-baseline gap-x-2">
                                <span className="text-2xl font-semibold tracking-tight text-black">
                                    {generalKpis[item.key as keyof GeneralKpis]}
                                </span>
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    );
}
