/** @format */

import { useState } from "react";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import {
    Order,
    ReceivedOrder,
    ShipmentQuantities,
} from "../../../../models/order";
import { useSort } from "../../../../hooks/useSort";
import { Column } from "../../../../tables/models";
import { ActionButton } from "../../../../components/buttons";
import { createShippingDetailsRequest } from "../api/shippingDetailsApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { requestDisposal } from "../api/inventoryApi";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../../tables/receivedOrdersTable";

interface RequestDisposalProps {
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrders?: Order[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    selectedReceivedOrders?: ReceivedOrder[];
    setSelectedReceivedOrders?: React.Dispatch<
        React.SetStateAction<ReceivedOrder[]>
    >;
}

function RequestDisposal({
    setOpenRequestActionsModal,
    selectedOrders,
    setSelectedOrders,
    selectedReceivedOrders,
    setSelectedReceivedOrders,
}: RequestDisposalProps) {
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [notes, setNotes] = useState("");

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );
    const hiddenHeaders: string[] = [
        "select",
        "location",
        "quick_actions",
        "input_shelf_location",
        "quantity_to_dispose",
    ];

    const [loading, setLoading] = useState(false);
    const handleCreate = async () => {
        setLoading(true);
        const table_name =
            selectedOrders && setSelectedOrders ? "orders" : "received_orders";
        const data = await requestDisposal(
            shipmentQuantities,
            notes,
            table_name
        );
        if (data.status === "success") {
            setOpenRequestActionsModal(false);
            if (setSelectedOrders) setSelectedOrders([]);
            if (setSelectedReceivedOrders) setSelectedReceivedOrders([]);
            setNotes("");
            setShipmentQuantities({});
            showNotification(data.message, "", "success");
        } else {
            showNotification(data.message, data.errors[0], "error");
        }
        setLoading(false);
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                <p className="text-sm text-gray-800 inline-flex gap-x-2">
                    <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    We'll remove the selected units from your inventory.
                </p>
                {selectedOrders && setSelectedOrders ? (
                    <OrdersTable
                        orderStatus="inbound"
                        orders={selectedOrders}
                        results={null}
                        selectedOrders={[]}
                        setSelectedOrders={setSelectedOrders}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={() => {}}
                        client_type="admin"
                        setTotalSum={setTotalSum}
                        columnOrder={unitsColumnOrder}
                        hiddenHeaders={hiddenHeaders}
                        shipmentQuantities={shipmentQuantities}
                        handleQuantityChange={handleQuantityChange}
                        shipmentInputName={"Quantity Requested"}
                    />
                ) : selectedReceivedOrders && setSelectedReceivedOrders ? (
                    <ReceivedOrdersTable
                        orders={selectedReceivedOrders}
                        setOrders={setSelectedReceivedOrders}
                        results={null}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={() => {}}
                        setTotalSum={setTotalSum}
                        columnOrder={initialReceivedOrdersColumnOrder}
                        hiddenHeaders={[
                            "select",
                            "order_name",
                            "quantity_to_dispose",
                        ]}
                        onRowClick={() => {}}
                        client_type="admin"
                        shipmentQuantities={shipmentQuantities}
                        handleQuantityChange={handleQuantityChange}
                    />
                ) : (
                    <></>
                )}

                <div className="mx-1">
                    <label
                        htmlFor="comment"
                        className="block text-sm/6 font-medium text-gray-900 mx-1"
                    >
                        Notes
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="comment"
                            name="comment"
                            rows={2}
                            className="block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-row-reverse">
                <ActionButton
                    label="Request disposal"
                    handleClick={() => handleCreate()}
                    height={9}
                    loading={loading}
                />
            </div>
        </>
    );
}

export default RequestDisposal;
