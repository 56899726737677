/** @format */

import { useSearchParams } from "react-router-dom";
import AdminSideBar from "../../../../components/admin/sideBar";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useClient } from "../../../../utils/client/clientContext";
import Header from "../../../../components/header";
import TabSelector from "../../../../components/tabSelector";
import { warehouseClarifiationTabs } from "../tabs";
import WarehouseClarificationsTable, {
    initialWarehouseClarificationColumnOrder,
} from "../../../../tables/warehouseClarificationsTable";
import { useEffect, useState } from "react";
import { WarehouseClarificationTask } from "../tasks/page";
import { useSort } from "../../../../hooks/useSort";
import Results from "../../../../models/results";
import { fetchWarehouseClarifications } from "../api/warehouseClarificationApi";
import LoadingWheel from "../../../../components/loadingWheel";

function AdminWarehouseClarificationCompletedPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const { user_id, orderCount } = useClient();

    const [warehouseClarifications, setWarehouseClarifications] = useState<
        WarehouseClarificationTask[]
    >([]);

    const [results, setResults] = useState<Results | null>(null);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        const data = await fetchWarehouseClarifications("completed");
        if (data.status === "success") {
            setWarehouseClarifications(data.data.clarifications);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center">
                    <span className="text-2xl font-bold">Tasks</span>
                </div>

                <TabSelector tabs={warehouseClarifiationTabs(0)} />

                {!loading ? (
                    <WarehouseClarificationsTable
                        clarifications={warehouseClarifications}
                        results={results}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={handleRefetch}
                        emptyTableText="No requests made yet."
                        columnOrder={initialWarehouseClarificationColumnOrder}
                    />
                ) : (
                    <div className="flex flex-row items-center justify-center pt-44">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminWarehouseClarificationCompletedPage;
