/** @format */

import { useState } from "react";
import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import RequestQuote from "./requestQuote";
import { Helmet } from "react-helmet";
import FeaturesTable from "./featuresTable";

const onlineArbitrageCards = [
    {
        id: 1,
        label: "Online Arbitrage",
        units: [
            { label: "0-999 units/month", pricing: "$1.50", units: "Per unit" },
            {
                label: "1,000+ units/month",
                sub_label: "",
                pricing: "$1.25",
                units: "Per unit",
            },
        ],
        sub_label:
            "Pricing includes prep, labeling, and packaging for all items sourced from online retailers.",
        lowest: "As low as $1.25/unit",
    },
    {
        id: 2,
        label: "Wholesale & Private Label",
        units: [
            { label: "0-999 units/month", pricing: "$1.00", units: "Per unit" },
            {
                label: "1,000+ units/month",
                sub_label: "",
                pricing: "$0.70",
                units: "Per unit",
            },
        ],
        sub_label:
            "Pricing includes prep, labeling, and packaging for all items sourced from wholesale suppliers and private label brands.",
        lowest: "As low as $0.70/unit",
    },
    {
        id: 3,
        label: "Parcel Forwarding",
        units: [
            {
                label: "0-999 units/month",
                sub_label: "",
                pricing: "$3.00",
                units: "Per unit",
            },
            {
                label: "1,000+ units/month",
                sub_label: "",
                pricing: "$2.50",
                units: "Per unit",
            },
        ],
        sub_label:
            "Reshipping prices vary depending on the specifications, contact us to get a qoute!",
        sub_label2:
            "Save money on your orders by shipping them to our tax-free Delaware warehouse, and we'll reship them the same day we receive them. This is perfect for businesses doing the two-step drop shipping model.",
        lowest: "As low as $2.50/unit",
    },
    {
        id: 4,
        label: "Shipping Supplies",
        units: [
            {
                label: "Polybags",
                sub_label: "",
                pricing: "Free",
                units: "",
            },
            {
                label: "Small Box (14xx11x7)",
                sub_label: "",
                pricing: "$1.00",
                units: "Per box",
            },
            {
                label: "Medium Box (20x16x16)",
                sub_label: "",
                pricing: "$2.00",
                units: "Per box",
            },
            {
                label: "XL Box (24x20x20)",
                sub_label: "",
                pricing: "$3.50",
                units: "Per box",
            },
            {
                label: "Palletization (Outbound)",
                sub_label: "",
                pricing: "$30.00",
                units: "Per pallet",
            },
        ],
        sub_label: "",
        lowest: "",
    },
    {
        id: 5,
        label: "Storage",
        units: [
            {
                label: "First 14 Days",
                sub_label: "",
                pricing: "Free",
                units: "",
            },
            {
                label: "Long-Term Storage",
                sub_label: "After 14 days",
                pricing: "$0.01/day",
                units: "Per unit",
            },
        ],
        sub_label: "",
        lowest: "",
    },
    {
        id: 6,
        label: "Additional fees",
        units: [
            {
                label: "Sneakers",
                sub_label: "",
                pricing: "+$0.50",
                units: "Per unit",
            },
            {
                label: "Fragile Items",
                sub_label: "",
                pricing: "+$0.25",
                units: "Per unit",
            },
            {
                label: "Oversized Items",
                sub_label: "",
                pricing: "+$0.50",
                units: "Per unit",
            },
            {
                label: "Multi Pack / Bundle",
                sub_label: "",
                pricing: "+$0.25",
                units: "Per bundle",
            },
            {
                label: "Merchant Fulfillment",
                sub_label: "",
                pricing: "+$1.00",
                units: "Per order",
            },
            {
                label: "Return to Supplier",
                sub_label: "",
                pricing: "+$2.00",
                units: "Per return",
            },
            // {
            //     label: "Label Removing",
            //     sub_label: "",
            //     pricing: "+$0.20",
            //     units: "Per unit",
            // },
        ],
        sub_label: "",
        lowest: "",
    },
];

const isMobile = () => {
    if (typeof window !== "undefined") {
        return window.innerWidth < 640; // Mobile breakpoint for Tailwind (sm)
    }
    return false; // Default to false if window is undefined (e.g., during SSR)
};

// Function to return the appropriate costs array
const getAdditionalCosts = () => {
    const additionalCostsDesktop = [
        { label: "Sneakers: +$0.50" },
        { label: "Storage: Free for 30 days" },
        { label: "Small Box (14xx11x7): $1.00" },
        { label: "Fragile Items: +$0.25" },
        { label: "Long-Term Storage: $30/month per pallet" },
        { label: "Medium Box (20x16x16): $2.00" },
        { label: "Multi Pack / Bundle: +$0.25" },
        { label: "Merchant Fulfillment: +$1.00" },
        { label: "XL Box (24x20x20): $3.50" },
        { label: "Palletization: $30/pallet" },
        { label: "Return to Supplier: $2.00/return" },
        { label: "Polybags: Free" },
    ];

    const additionalCostsMobile = [
        { label: "Sneakers: + $0.50" },
        { label: "Small Box (14xx11x7) : $1.00" },
        { label: "Fragile Items: +$0.25" },
        { label: "Medium Box (20x16x16): $2.00" },
        { label: "Multi Pack / Bundle: + $0.25" },
        { label: "XL Box (24x20x20): $3.50" },
        { label: "Storage: Free for 30 days" },
        { label: "Merchant Fulfillment: +$1.00" },
        { label: "Palletization: $30/pallet" },
        { label: "Polybags: Free" },
        { label: "Long-Term Storage: $30/month per pallet" },
        { label: "Return to Supplier: $2.00/return" },
    ];

    return isMobile() ? additionalCostsMobile : additionalCostsDesktop;
};

function PricingPage() {
    const [additionalCosts, setAdditionalCosts] = useState(
        getAdditionalCosts()
    );

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div>
            <Helmet>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=AW-16730636970"
                ></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'AW-16730636970');
          `}
                </script>
            </Helmet>
            <Header />

            <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-32 flex flex-col">
                <div className="mr-20 w-full">
                    <div className="lg:mr-10 w-full flex flex-col items-start">
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                            Pricing
                        </p>
                    </div>
                </div>

                {/* <div className="flex flex-col sm:flex-row items-center sm:space-y-0 space-y-5 sm:space-x-14 pt-4 pb-4 h-full"> */}
                <div className="grid grid-cols-1 sm:grid-cols-3 items-center h-full sm:gap-x-14 gap-x-0 gap-y-5 pt-4 pb-4">
                    {onlineArbitrageCards.map((item, index) => (
                        <div
                            key={item.id}
                            className={`${
                                [0, 1, 2].includes(index) ? "h-72" : "h-80"
                            } justify-between border border-gray-200 shadow-sm w-full rounded-md py-4 px-3 flex flex-col`}
                        >
                            <div className="flex flex-col">
                                <span className="font-semibold">
                                    {item.label}
                                </span>
                                <div className="text-xs text-gray-600 pt-2">
                                    {item.sub_label2
                                        ? item.sub_label2
                                        : item.sub_label}
                                </div>
                                {item.units.length > 0 ? (
                                    <>
                                        {item.units.map((unit) => (
                                            <div className="flex flex-row items-center justify-between pt-2 text-sm -space-y-1">
                                                <div>
                                                    <span className="">
                                                        {unit.label}:
                                                    </span>
                                                    {unit.sub_label !== "" && (
                                                        <p className="text-2xs">
                                                            {unit.sub_label}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="flex flex-col items-end">
                                                    <span className="">
                                                        {unit.pricing}
                                                    </span>
                                                    <p className="text-2xs">
                                                        {unit.units}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <span className="text-sm pt-2">
                                        {item.sub_label}
                                    </span>
                                )}
                            </div>
                            {item.lowest !== "" && (
                                <div>
                                    <div
                                        onClick={() =>
                                            scrollToSection("request-quote")
                                        }
                                        className="bg-accentbg px-3 py-2 rounded-md hover:bg-accentbg/70 cursor-pointer flex flex-row items-center justify-between"
                                    >
                                        <span className="text-accent font-semibold text-sm">
                                            {item.lowest}
                                        </span>
                                        <span className="text-accent font-medium text-xs">
                                            Request a quote
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* <div className="border border-gray-200 shadow-sm w-full rounded-md py-4 px-3 flex flex-col">
                    <span className="font-semibold">Additional Fees</span>
                    <div className="grid grid-cols-2 sm:grid-cols-3 text-sm sm:gap-x-14 gap-y-2 gap-x-0.5 sm:gap-y-4 pt-2">
                        {additionalCosts.map((item, index) => (
                            <span
                                key={index}
                                className={`text-2xs sm:text-sm ${
                                    index === additionalCosts.length - 1
                                        ? "col-span-1 sm:col-span-1"
                                        : ""
                                }`}
                            >
                                {item.label}
                            </span>
                        ))}
                    </div>
                </div> */}
            </div>

            <div className="mx-auto max-w-5xl px-6 lg:px-8 pt-10">
                <FeaturesTable />
            </div>

            <div id="request-quote"></div>
            <RequestQuote />

            <Footer />
        </div>
    );
}

export default PricingPage;
