/** @format */

import React, { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { MdOutlineCalendarMonth } from "react-icons/md";

const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

interface CustomWeekRangeCalendarProps {
    selectedRange: { start: Dayjs | null; end: Dayjs | null };
    setSelectedRange: (range: {
        start: Dayjs | null;
        end: Dayjs | null;
    }) => void;
    currentDate: Dayjs;
    setCurrentDate: (date: Dayjs) => void;
    isCalendarVisible: boolean;
    setIsCalendarVisible: (visible: boolean) => void;
    includeBorder?: boolean;
}

const CustomWeekRangeCalendar: React.FC<CustomWeekRangeCalendarProps> = ({
    selectedRange,
    setSelectedRange,
    currentDate,
    setCurrentDate,
    isCalendarVisible,
    setIsCalendarVisible,
    includeBorder = false,
}) => {
    const calendarRef = useRef<HTMLDivElement>(null);
    const toggleButtonRef = useRef<HTMLSpanElement>(null);

    const generateCalendarDays = () => {
        const startOfMonth = currentDate.startOf("month");
        const endOfMonth = currentDate.endOf("month");
        const startDate = startOfMonth.startOf("week");
        const endDate = endOfMonth.endOf("week");

        const days: Dayjs[] = [];
        let day = startDate;
        while (day.isBefore(endDate, "day") || day.isSame(endDate, "day")) {
            days.push(day);
            day = day.add(1, "day");
        }
        return days;
    };

    const days = generateCalendarDays();
    const today = dayjs();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                toggleButtonRef.current &&
                !calendarRef.current.contains(event.target as Node) &&
                !toggleButtonRef.current.contains(event.target as Node)
            ) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsCalendarVisible]);

    const handleDateClick = (day: Dayjs) => {
        if (
            !selectedRange.start ||
            (selectedRange.start && selectedRange.end)
        ) {
            // Start a new range selection
            setSelectedRange({ start: day, end: null });
        } else {
            // Complete the range selection
            if (day.isBefore(selectedRange.start, "day")) {
                setSelectedRange({ start: day, end: selectedRange.start });
            } else {
                setSelectedRange({ start: selectedRange.start, end: day });
            }
        }
    };

    return (
        <div className="relative">
            <span
                ref={toggleButtonRef}
                className={`${
                    includeBorder && "border-x rounded-md"
                } flex h-9 w-12 items-center justify-center border-y border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 hover:bg-gray-50 cursor-pointer`}
                onClick={() => setIsCalendarVisible(!isCalendarVisible)}
            >
                <MdOutlineCalendarMonth className="h-6 w-6" />
            </span>
            {isCalendarVisible && (
                <div
                    ref={calendarRef}
                    className="absolute left-0 top-full mt-2 bg-white rounded-xl shadow-lg w-96 z-30"
                >
                    <div className="p-4">
                        <div className="flex items-center justify-between mb-4">
                            <button
                                onClick={() =>
                                    setCurrentDate(
                                        currentDate.subtract(1, "month")
                                    )
                                }
                                className="text-gray-600 hover:text-gray-800"
                            >
                                <GoChevronLeft className="h-5 w-5" />
                            </button>
                            <span className="text-sm font-semibold">
                                {currentDate.format("MMMM YYYY")}
                            </span>
                            <button
                                onClick={() =>
                                    setCurrentDate(currentDate.add(1, "month"))
                                }
                                className="text-gray-600 hover:text-gray-800"
                            >
                                <GoChevronRight className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="grid grid-cols-7 text-center mb-3">
                            {daysOfWeek.map((day) => (
                                <div
                                    key={day}
                                    className="text-xs text-gray-500"
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                        <div className="border border-gray-200 rounded-xl overflow-hidden">
                            <div className="grid grid-cols-7 bg-gray-200 gap-px">
                                {days.map((day) => {
                                    const isToday = day.isSame(today, "day");
                                    const isInRange =
                                        selectedRange.start &&
                                        selectedRange.end &&
                                        day.isAfter(
                                            selectedRange.start,
                                            "day"
                                        ) &&
                                        day.isBefore(selectedRange.end, "day");
                                    const isSelectedStart =
                                        selectedRange.start &&
                                        day.isSame(selectedRange.start, "day");
                                    const isSelectedEnd =
                                        selectedRange.end &&
                                        day.isSame(selectedRange.end, "day");
                                    const isCurrentMonth =
                                        day.month() === currentDate.month();

                                    let classes =
                                        "bg-white hover:bg-gray-100 hover:cursor-pointer";
                                    let textColor = "text-gray-800";

                                    if (!isCurrentMonth) {
                                        classes = "bg-gray-50 text-gray-400";
                                    }
                                    if (isSelectedStart || isSelectedEnd) {
                                        classes = "bg-accent text-white";
                                    } else if (isInRange) {
                                        classes = "bg-accentbg text-blue-800";
                                    }
                                    if (isToday) {
                                        textColor = "text-accent";
                                    }

                                    return (
                                        <div
                                            key={day.format("YYYY-MM-DD")}
                                            onClick={() => handleDateClick(day)}
                                            className={`relative text-sm flex items-center justify-center h-11 ${classes}`}
                                        >
                                            <span className={textColor}>
                                                {day.date()}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomWeekRangeCalendar;
