/** @format */

import { useEffect, useState } from "react";
import LoadingWheel from "../../../../../components/loadingWheel";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { Order, ShipmentQuantities } from "../../../../../models/order";
import { fetchLabels } from "../../api/labelApi";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import InventoryTable from "../inventoryTable";
import { Label } from "../../../../../models/label";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { requestOutboundShipment } from "../../api/inventoryApi";
import { InputField } from "../../../../../components/inputField";
import { ActionButton } from "../../../../../components/buttons";
import { LabelModal } from "./labelModal";
import { UploadLabelModal } from "./uploadLabelModal";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const getFileExtension = (url: string): string => {
    return url.split(".").pop()?.split(/#|\?/)[0] || "";
};

export const PdfViewer: React.FC<{ url: string; isSelected?: boolean }> = ({
    url,
    isSelected,
}) => {
    const [numPages, setNumPages] = useState<number | null>(null);

    return (
        <div>
            <div
                className={`${
                    isSelected ? "border-accent" : "border-gray-300"
                } border  cursor-pointer shadow-sm h-44 w-36 overflow-hidden rounded-md hover:border-accent transition-all hover:scale-105`}
            >
                <Document
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    file={url}
                >
                    <Page pageNumber={1} height={200} width={120} />
                </Document>
            </div>
            <span className="text-xs">{numPages} pages</span>
        </div>
    );
};

export function RequestOutboundShipment({
    selectedOrders,
    setSelectedOrders,
    openRequestActionsModal,
    setOpenRequestActionsModal,
    handleRefetch,
}: {
    selectedOrders: Order[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    openRequestActionsModal: boolean;
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [labels, setLabels] = useState<Label[]>([]);

    const [refetchLabelData, setRefetchLabelData] = useState<number>(0);
    const handleLabelRefetch = () => {
        setRefetchLabelData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchLabels();
            if (data.status === "success") {
                setLabels(data.data.labels);
            } else {
                showNotification(
                    "Failed to fetch labels",
                    data.message,
                    "error"
                );
            }
        };
        if (openRequestActionsModal || refetchLabelData) {
            fetchData();
        }
    }, [openRequestActionsModal, refetchLabelData]);

    const [selectedViewingLabel, setSelectedViewingLabel] =
        useState<Label | null>(null);

    const [openLabelModal, setOpenLabelModal] = useState(false);
    const [selectedPagesByLabel, setSelectedPagesByLabel] = useState<{
        [labelId: string]: number[];
    }>({});

    // Function to update selected pages for a particular label
    const updateSelectedPages = (labelId: string, selectedPages: number[]) => {
        setSelectedPagesByLabel((prevState) => ({
            ...prevState,
            [labelId]: selectedPages, // Update selected pages for the specific label
        }));
    };

    useEffect(() => {
        if (selectedViewingLabel) {
            setOpenLabelModal(true);
        }
    }, [selectedViewingLabel]);

    useEffect(() => {
        if (!openLabelModal) {
            setSelectedViewingLabel(null);
        }
    }, [openLabelModal]);

    const [openUploadLabelModal, setOpenUploadLabelModal] = useState(false);
    const [shipmentName, setShipmentName] = useState("");
    const [shipmentNotes, setShipmentNotes] = useState("");

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const clearFields = () => {
        setShipmentQuantities({});
        setSelectedPagesByLabel({});
    };

    const handleRequest = async () => {
        setLoading(true);
        const order_ids = selectedOrders.map((order) => order.order_id);
        const data = await requestOutboundShipment(
            order_ids,
            shipmentQuantities,
            shipmentName,
            shipmentNotes,
            selectedPagesByLabel
        );
        if (data.status === "success") {
            setOpenRequestActionsModal(false);
            showNotification("Requested outbound shipment", "", "success");
            handleRefetch();
            setSelectedPagesByLabel({});
            setSelectedOrders([]);
        } else {
            showNotification("Failed to create request", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto mt-4 mb-4">
                <p className="text-sm text-gray-800 inline-flex gap-x-2 pb-2">
                    <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    We'll package the selected units and ship them using the
                    uploaded label(s).
                </p>
                <InventoryTable
                    inventoryOrders={selectedOrders}
                    handleRefetch={handleRefetch}
                    shipmentQuantities={shipmentQuantities}
                    handleQuantityChange={handleQuantityChange}
                />

                <div className="pt-6"></div>

                <div className="flex flex-row space-x-4">
                    <div className="w-2/3">
                        <InputField
                            label="Shipment name"
                            value={shipmentName}
                            onChange={(e) => setShipmentName(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <InputField
                        label="Notes"
                        value={shipmentNotes}
                        onChange={(e) => setShipmentNotes(e.target.value)}
                        placeholder=""
                    />
                </div>

                <div className="flex flex-row items-center justify-between pt-10">
                    <div className="flex flex-row items-center">
                        <h1 className="text-base font-semibold">
                            Select Labels
                            {" - "}
                        </h1>
                        <span className="text-sm text-accent font-medium">
                            {""}
                            {Object.values(selectedPagesByLabel).flat().length}
                            {" pages selected"}
                        </span>
                    </div>
                    <span
                        onClick={() => setOpenUploadLabelModal(true)}
                        className="text-sm text-accent cursor-pointer inline-flex items-center gap-x-1 hover:text-accenthighlight"
                    >
                        <PlusCircleIcon className="h-4 w-4" />
                        Upload label
                    </span>
                </div>
                <div className="flex flex-row space-x-4 overflow-scroll">
                    {labels.map((label, index) => (
                        <div
                            onClick={() => setSelectedViewingLabel(label)}
                            key={index}
                            className="pl-2 pt-4"
                        >
                            {getFileExtension(label.public_url) === "pdf" ? (
                                <>
                                    <PdfViewer url={label.public_url} />
                                    <span className="text-sm text-accent font-semibold">
                                        {selectedPagesByLabel[label.label_id]
                                            ? `${
                                                  selectedPagesByLabel[
                                                      label.label_id
                                                  ].length
                                              } ${" selected"}`
                                            : ""}{" "}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <div className="border border-gray-300 cursor-pointer shadow-sm h-44 w-36 overflow-hidden rounded-md hover:border-accent transition-all hover:scale-105">
                                        <img src={label.public_url} />
                                    </div>
                                    <span className="text-sm text-accent font-semibold">
                                        {selectedPagesByLabel[label.label_id]
                                            ? `${
                                                  selectedPagesByLabel[
                                                      label.label_id
                                                  ].length
                                              } ${" selected"}`
                                            : ""}{" "}
                                    </span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Fixed buttons at the bottom */}
            <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                <div className="flex flex-row-reverse">
                    <ActionButton
                        label="Request shipment"
                        handleClick={() => handleRequest()}
                        height={9}
                        loading={loading}
                    />
                </div>
                <div className="space-x-2">
                    <button
                        type="button"
                        onClick={() => setOpenRequestActionsModal(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => clearFields()}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                        Clear all fields
                    </button>
                </div>
            </div>
            <LabelModal
                selectedViewingLabel={selectedViewingLabel}
                openLabelModal={openLabelModal}
                setOpenLabelModal={setOpenLabelModal}
                selectedPages={
                    selectedPagesByLabel[
                        selectedViewingLabel?.label_id || ""
                    ] || []
                }
                updateSelectedPages={(pages) =>
                    selectedViewingLabel &&
                    updateSelectedPages(selectedViewingLabel.label_id, pages)
                }
                handleLabelRefetch={handleLabelRefetch}
            />

            <UploadLabelModal
                openUploadLabelModal={openUploadLabelModal}
                setOpenUploadLabelModal={setOpenUploadLabelModal}
                handleLabelRefetch={handleLabelRefetch}
            />
        </>
    );
}
