import { apiRequest } from "../../../../utils/api/apiRequest"
import { buildUrl } from "../../../../utils/url/buildUrl";


export const fetchWorklist = async (action_type: string | null, all_rows: boolean | null) => {
    const baseUrl = `/admin/worklist`;
        const params = {
            action_type,
            all_rows: all_rows ? "true" : "false"
        };
    
        const url = buildUrl(baseUrl, params);
    
        return apiRequest(url, "GET");
}

export const assignTask = async (task: string) => {
    return apiRequest("/admin/worklist/tasks", "POST", {task})
}

export const markFbaShipmentsRequestedDone = async (id: number) => {
    return apiRequest("/admin/worklist/fba-requested-shipments/mark-done", "POST", {id})
}

export const markAssignedTaskDone = async (task_id: string) => {
    return apiRequest("/admin/worklist/assigned-task/mark-done", "POST", {task_id})
}

export const fetchOrdersOfPictureRequest = async (id: number, isAdmin: boolean) => {
    return apiRequest(`${isAdmin ? '/admin/worklist' : "/outbound"}/picture-request/${id}`, "GET")
}

export const uploadPicturesToPictureRequest = async (id: number, images: File[]) => {
    return apiRequest(`/admin/worklist/picture-request/${id}`, "POST", images, true)
}

export const fetchOrdersFromDisposalRequest = async (id: number, isAdmin: boolean) => {
    return apiRequest(`${isAdmin ? '/admin' : ""}/disposal/${id}`, "GET")
}

export const approveDisposalRequest = async (id: number) => {
    return apiRequest(`/admin/disposal/${id}`, "POST")
}