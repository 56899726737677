import { saveAs } from "file-saver"
import { apiRequest } from "../../../utils/api/apiRequest"


export const exportAdminData = async (
    user_id: string | null,
    data_type: "inbound" | "received" | "inventory" | "outbound" | "billing" | "received" | "checked-in",
    specific_data: "units" | "trackings" | "shipped" | "requested" | null,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    showNotification: (title: string, subtitle?: string, type?: "success" | "error") => void
) => {
    setLoading(true)
    const data = await apiRequest("/admin/export", "POST", {data_type, specific_data, user_id}, false, {}, true)
    if (data.type === "application/json") {
        showNotification("Failed to download data.", "If this issue persists, contact an administrator.", "error")
    } else {
        saveAs(data, `${data_type}_data.csv`)
        showNotification("Downloaded csv.", "Check your downloads folder.", "success")
    }
    setLoading(false)
}