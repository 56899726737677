/** @format */

import { useCallback, useEffect, useRef, useState } from "react";
import AdminSearchBar from "../../../components/admin/searchBar";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import OrdersTable, { getTableHeaders } from "../../../tables/ordersTable";
import { Order } from "../../../models/order";
import Results from "../../../models/results";
import { deleteClientOrders, fetchClientOrders } from "../api/unitsApi";
import { useSearchParams } from "react-router-dom";
import { useSort } from "../../../hooks/useSort";
import { useNotification } from "../../../utils/notification/notificationContext";
import LoadingWheel from "../../../components/loadingWheel";
import { ClientFilterPopup } from "../clients/filters/clientFilter";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/20/solid";
import CreateOutboundShipmentModal from "../clients/client-data/shipments/outbound-shipment/createOutboundShipment";
import { AddInventoryOrderModal } from "./addInventoryOrderModal";
import { getAdminType } from "../../../utils/adminTypes/actions";
import { orderTypes } from "../../../models/data";
import { initialTrackingsColumnOrder } from "../../../tables/trackingsTable";
import ClientDrawer from "../clients/clientDrawer";
import { ArrowUpTrayIcon, UserIcon } from "@heroicons/react/24/outline";
import { useClient } from "../../../utils/client/clientContext";
import { Column } from "../../../tables/models";
import ColumnOrderDropdown from "../../../tables/columnDragPopup";
import { SecondaryButton } from "../../../components/buttons";
import { exportAdminData } from "../api/exportApi";
import WarehouseClarificationModal from "../warehouse-clarification/warehouseClarificationModal";
import { EmployeeFilterPopup } from "../clients/filters/employeeFilter";
import { Administrator } from "../../../models/accountData";

export default function AdminInventoryPage() {
    const isOwner = getAdminType() === "owner";
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        client,
        setClient,
        user_id,
        setUserId,
        orderCount,
        handleOrderCountRefetch,
    } = useClient();
    const [employee, setEmployee] = useState<Administrator | null>(null);

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    const [orders, setOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

    const [keywords, setKeywords] = useState<string[]>([]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort(
        {
            key: "created_at",
            order: "desc",
        },
        orders,
        setOrders,
        undefined,
        undefined,
        keywords
    );

    const fetchData = async (orderType: string) => {
        if (orderType === "units") {
            const data = await fetchClientOrders(
                user_id,
                "inventory",
                undefined,
                searchParams.get("offset")
                    ? Number(searchParams.get("offset"))
                    : 0,
                searchParams.get("limit")
                    ? Number(searchParams.get("limit"))
                    : 200,
                sortConfig,
                undefined,
                undefined,
                employee?.employee_id
            );
            if (data.status === "success") {
                setOrders(data.data.orders);
                setResults(data.data.results);
            } else {
                showNotification(
                    "Failed fetching orders",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
            setSortLoading(false);
            setSelectedOrders([]);
        }
    };

    const triggerRefetch = useCallback(() => {
        if (keywords.length > 0) return;
        setLoading(true);
        const controller = new AbortController();
        fetchData(selectedOrderType.value);
        hasMounted.current = true;

        return () => {
            controller.abort();
        };
    }, [sortConfig, user_id, selectedOrderType, employee]);

    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            triggerRefetch();
        }
    }, [sortConfig, user_id, selectedOrderType, employee]);

    const handleRefetch = (fromSearch: boolean = false) => {
        if (keywords.length < 1 && inputValue === "") {
            triggerRefetch();
        } else {
            setRefetchSearchedData((prev) => !prev);
        }
        if (!fromSearch) {
            handleOrderCountRefetch();
        }
    };

    useEffect(() => {
        fetchData(selectedOrderType.value);
        hasMounted.current = true;
    }, []);

    const clientFilterRef = useRef<HTMLButtonElement>(null);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const employeeFilterRef = useRef<HTMLButtonElement>(null);
    const [isEmployeeFilterOpen, setIsEmployeeFilterOpen] =
        useState<boolean>(false);
    const toggleEmployeeFilter = () => {
        setIsEmployeeFilterOpen((prevState) => !prevState);
    };

    const [deleteLoading, setDeleteLoading] = useState(false);

    const [openCreateOutboundShipment, setOpenCreateOutboundShipment] =
        useState(false);

    const [openAddInventoryOrderModal, setOpenAddInventoryOrderModal] =
        useState(false);

    const handleDelete = async () => {
        setDeleteLoading(true);
        const data = await deleteClientOrders(user_id || "", selectedOrders);
        if (data.status === "success") {
            handleRefetch();

            showNotification("Deleted order.", "", "success");
        } else {
            showNotification("Failed to delete orders", data.message, "error");
        }
        setDeleteLoading(false);
    };

    const [clientDrawerOpen, setClientDrawerOpen] = useState(false);

    const [totalSum, setTotalSum] = useState("");

    const [refetchSearchedData, setRefetchSearchedData] = useState(false);
    const [inputValue, setInputValue] = useState<string>("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inventory")
    );
    const hiddenHeaders: string[] = [
        "input_shipping_quantity",
        "remove_selected_row",
        "input_shelf_location",
        "location",
        "qa",
        "quantity_to_dispose",
    ];

    const [trackingsColumnOrder, setTrackingsColumnOrder] = useState<Column[]>(
        initialTrackingsColumnOrder
    );

    const [exportLoading, setExportLoading] = useState(false);

    const [
        openRequestWarehouseClarificationModal,
        setOpenRequestWarehouseClarificationModal,
    ] = useState(false);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center">
                    <span className="text-2xl font-bold">Inventory</span>
                    <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                        {orderCount?.inventory.toLocaleString()}
                    </span>
                </div>

                <div className="flex flex-row items-center justify-between pt-4">
                    <div className="flex flex-row items-center space-x-4">
                        <div className="relative">
                            <button
                                ref={clientFilterRef}
                                onClick={toggleFilter}
                                className="text-xs text-gray-600 hover:bg-gray-50 flex flex-row items-center rounded-md shadow-sm border border-gray-300 px-2 h-7 divide-x gap-x-2"
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    {!user_id ? (
                                        <PencilSquareIcon className="w-4 h-4" />
                                    ) : (
                                        <XCircleIcon
                                            className="w-4 h-4 text-red-500 hover:text-red-400"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                searchParams.delete("user_id");
                                                // Update the URL with the modified searchParams
                                                window.history.replaceState(
                                                    null,
                                                    "",
                                                    `?${searchParams.toString()}`
                                                );
                                                setUserId(null);
                                            }}
                                        />
                                    )}
                                    <span>Client ID</span>
                                </div>
                                {user_id && (
                                    <span className="font-medium text-accent pl-2">
                                        {user_id} -{" "}
                                        <span className="text-gray-600">
                                            {client &&
                                                client.first_name +
                                                    " " +
                                                    client.last_name}
                                        </span>
                                    </span>
                                )}
                            </button>
                            <ClientFilterPopup
                                isOpen={isFilterOpen}
                                onClose={() => setIsFilterOpen(false)}
                                taxButtonRef={clientFilterRef}
                                user_id={user_id}
                                setUserId={setUserId}
                            />
                        </div>

                        <div className="relative">
                            <button
                                ref={employeeFilterRef}
                                onClick={toggleEmployeeFilter}
                                className="text-xs text-gray-600 hover:bg-gray-50 flex flex-row items-center rounded-md shadow-sm border border-gray-300 px-2 h-7 divide-x gap-x-2"
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    {!employee ? (
                                        <PencilSquareIcon className="w-4 h-4" />
                                    ) : (
                                        <XCircleIcon
                                            className="w-4 h-4 text-red-500 hover:text-red-400"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEmployee(null);
                                            }}
                                        />
                                    )}
                                    <span>Employee ID</span>
                                </div>
                                {employee && (
                                    <span className="font-medium text-accent pl-2">
                                        {employee.employee_id} -{" "}
                                        <span className="text-gray-600">
                                            {employee &&
                                                employee.first_name +
                                                    " " +
                                                    employee.last_name}
                                        </span>
                                    </span>
                                )}
                            </button>
                            <EmployeeFilterPopup
                                isOpen={isEmployeeFilterOpen}
                                onClose={() => setIsEmployeeFilterOpen(false)}
                                taxButtonRef={employeeFilterRef}
                                employee={employee}
                                setEmployee={setEmployee}
                            />
                        </div>
                        {user_id && (
                            <button
                                onClick={() => setClientDrawerOpen(true)}
                                className="flex h-7 flex-row items-center text-xs px-2 border border-gray-300 shadow-sm rounded-md text-black"
                            >
                                <UserIcon className="w-4 h-4" />{" "}
                                <span className="pl-1.5">View client</span>
                            </button>
                        )}
                        <ColumnOrderDropdown
                            columnOrder={
                                selectedOrderType.value === "units"
                                    ? unitsColumnOrder
                                    : trackingsColumnOrder
                            }
                            setColumnOrder={
                                selectedOrderType.value === "units"
                                    ? setUnitsColumnOrder
                                    : setTrackingsColumnOrder
                            }
                            localStorageKey={
                                selectedOrderType.value === "units"
                                    ? "unitsColumnOrder"
                                    : "trackingsColumnOrder"
                            }
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={
                                selectedOrderType.value === "units"
                                    ? getTableHeaders("inventory")
                                    : initialTrackingsColumnOrder
                            }
                        />
                    </div>
                    <div className="flex flex-row items-center space-x-4 divide-x divide-gray-200">
                        {totalSum !== "" && (
                            <span className="text-gray-700 text-sm">
                                Sum: {totalSum}
                            </span>
                        )}
                        {selectedOrders && selectedOrders?.length > 0 && (
                            <span className="text-gray-700 text-sm pl-2">
                                {selectedOrders.length} shipment
                                {selectedOrders.length > 1 && "s"} selected
                            </span>
                        )}
                        {selectedOrders.length > 0 && (
                            <div className="h-7 pl-2 flex flex-row items-center justify-center">
                                <p
                                    onClick={() =>
                                        setOpenRequestWarehouseClarificationModal(
                                            true
                                        )
                                    }
                                    className="hover:bg-accentbg cursor-pointer text-sm text-accent px-2 h-7 flex flex-row items-center justify-center rounded-md"
                                >
                                    Clarification
                                </p>
                            </div>
                        )}
                        <SecondaryButton
                            label="Export"
                            handleClick={() =>
                                exportAdminData(
                                    user_id,
                                    "inventory",
                                    "units",
                                    setExportLoading,
                                    showNotification
                                )
                            }
                            iconDisplayed={ArrowUpTrayIcon}
                            loading={exportLoading}
                        />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:space-x-4 pt-3 pb-4 sm:pb-0">
                    <AdminSearchBar
                        setData={setOrders}
                        setLoading={setLoading}
                        handleRefetch={handleRefetch}
                        page="inventory"
                        sub_page={selectedOrderType.value}
                        user_id={user_id}
                        keywords={keywords}
                        setKeywords={setKeywords}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        refetchSearchedData={refetchSearchedData}
                    />
                    <div className="flex flex-row items-start space-x-4">
                        {isOwner &&
                            selectedOrders &&
                            selectedOrders.length > 0 && (
                                <div
                                    className={`transition-opacity duration-300 ease-in-out ${
                                        selectedOrders.length > 0
                                            ? "opacity-100"
                                            : "opacity-0 pointer-events-none"
                                    }`}
                                >
                                    <button
                                        onClick={() => handleDelete()}
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 h-8 text-xs font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                                    >
                                        {deleteLoading ? (
                                            <LoadingWheel
                                                color="white"
                                                size="small"
                                            />
                                        ) : (
                                            <>Delete</>
                                        )}
                                    </button>
                                </div>
                            )}
                        <button
                            disabled={
                                selectedOrders.length === 0 ||
                                selectedOrders.some(
                                    (order) => order.fulfilled_by === "merchant"
                                ) ||
                                selectedOrders.some(
                                    (order, _, array) =>
                                        order.user_id !== array[0].user_id
                                )
                            }
                            onClick={() => setOpenCreateOutboundShipment(true)}
                            className={`${
                                selectedOrders.length === 0 ||
                                selectedOrders.some(
                                    (order) => order.fulfilled_by === "merchant"
                                ) ||
                                selectedOrders.some(
                                    (order, _, array) =>
                                        order.user_id !== array[0].user_id
                                )
                                    ? "bg-accentdim"
                                    : "bg-accent hover:bg-accenthighlight"
                            } text-white font-semibold px-5 text-xs rounded-md h-8 whitespace-nowrap`}
                        >
                            New Outbound Shipment
                        </button>
                        <button
                            onClick={() => setOpenAddInventoryOrderModal(true)}
                            className={`bg-accent hover:bg-accenthighlight text-white font-semibold px-5 text-xs rounded-md h-8 whitespace-nowrap`}
                        >
                            Add Inventory
                        </button>
                    </div>
                </div>

                {!loading ? (
                    <OrdersTable
                        orderStatus="inventory"
                        orders={orders}
                        results={results}
                        selectedOrders={selectedOrders}
                        setSelectedOrders={setSelectedOrders}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={handleRefetch}
                        client_type="admin"
                        setTotalSum={setTotalSum}
                        columnOrder={unitsColumnOrder}
                        hiddenHeaders={hiddenHeaders}
                    />
                ) : (
                    <div className="flex flex-grow items-center pb-44 justify-center">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <span className="text-sm text-gray-500">
                                User has no inbound orders
                            </span>
                        )}
                    </div>
                )}
            </div>
            <CreateOutboundShipmentModal
                selectedInventoryOrders={selectedOrders}
                setSelectedInventoryOrders={setSelectedOrders}
                openCreateOutbountShipment={openCreateOutboundShipment}
                setOpenCreateOutbountShipment={setOpenCreateOutboundShipment}
                handleFullRefetch={handleRefetch}
            />

            <AddInventoryOrderModal
                user_id={user_id || ""}
                openAddInventoryOrderModal={openAddInventoryOrderModal}
                setOpenAddInventoryOrderModal={setOpenAddInventoryOrderModal}
                handleRefetch={handleRefetch}
            />

            <ClientDrawer
                user_id={user_id || ""}
                open={clientDrawerOpen}
                setOpen={setClientDrawerOpen}
                client={client}
                setClient={setClient}
            />

            <WarehouseClarificationModal
                orderType="inventory"
                modalType="create"
                orders={selectedOrders}
                setOrders={setSelectedOrders}
                openWarehouseClarificationModal={
                    openRequestWarehouseClarificationModal
                }
                setOpenWarehouseClarificationModal={
                    setOpenRequestWarehouseClarificationModal
                }
                handleRefetch={() => {}}
            />
        </div>
    );
}
