/** @format */

import { useSearchParams } from "react-router-dom";
import LoadingWheel, { SortLoading } from "../components/loadingWheel";
import useCellSelection from "../hooks/useCellSelection";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import Results from "../models/results";
import { convertToESTV2 } from "../utils/date/date_formatter";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import { PaginationResults } from "../utils/pagination/paginationResults";
import { formatMoney } from "../utils/price/formatMoney";
import ColumnSort from "../utils/sort/columnSort";
import { Column } from "./models";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { ReceivedOrder, ShipmentQuantities } from "../models/order";
import { ReceivedOrderStatusTag } from "../components/statusTags";
import { useEffect, useState } from "react";
import EditReceivedOrderModal from "../pages/admin/received/editReceivedOrderModal";
import { SmallCopyButton } from "../components/copyButton";
import {
    createTrackingUrl,
    Carrier,
} from "../utils/carriers/buildTrackingNumberUrl";
import TrackingNumbersModal from "../components/modals/trackingNumbersModal";
import { useTrackingNumbersModal } from "../hooks/useTrackingNumbers";

export const initialReceivedOrdersColumnOrder: Column[] = [
    {
        key: "select",
        label: "Select",
        className: "sm:min-w-10 min-w-10",
        isSortable: false,
        nonMovable: true,
    },
    {
        key: "input_shipping_quantity",
        label: "Shipping quantity",
        className: "sm:min-w-40 min-w-32",
        isSortable: false,
        nonMovable: true,
    },
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
        isSortable: true,
    },
    {
        key: "created_at",
        label: "Date Received",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "order_name",
        label: "Name",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "quantity_to_dispose",
        label: "Disposal Quantity",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },

    {
        key: "tracking_number",
        label: "Tracking",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "carrier",
        label: "Carrier",
        className: "sm:min-w-32 min-w-28 pl-4",
        isSortable: false,
    },
    {
        key: "location",
        label: "Location",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "retailer",
        label: "Supplier",
        className: "min-w-32",
        isSortable: false,
    },
    {
        key: "item_name",
        label: "Item Name",
        className: "sm:min-w-48 min-w-48",
        isSortable: false,
    },
    {
        key: "size",
        label: "Size",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "quantity",
        label: "Quantity Received",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
        isSummable: true,
    },
    {
        key: "color",
        label: "Color",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "brand_sku",
        label: "Brand SKU",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "upc",
        label: "UPC",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "check_in_notes",
        label: "Check in notes",
        className: "sm:min-w-32 min-w-28 pl-4",
        isSortable: false,
    },
    {
        key: "team_member_notes",
        label: "Team member notes",
        className: "sm:min-w-44 min-w-28 whitespace-nowrap pl-4",
        isSortable: false,
    },
    {
        key: "client_notes",
        label: "Client notes",
        className: "sm:min-w-32 min-w-28 pl-4",
        isSortable: false,
    },
];
interface ReceivedOrdersTableProps {
    orders: ReceivedOrder[];
    setOrders: React.Dispatch<React.SetStateAction<ReceivedOrder[]>>;
    results: Results | null;
    selectedOrders?: ReceivedOrder[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<ReceivedOrder[]>>;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    setTotalSum: React.Dispatch<React.SetStateAction<string>>;
    columnOrder: Column[];
    hiddenHeaders: string[];
    onRowClick: (order: ReceivedOrder) => void;
    client_type?: string;
    selectedTab?: string;
    shipmentQuantities?: ShipmentQuantities;
    handleQuantityChange?: (orderId: string, value: string) => void;
}

function ReceivedOrdersTable({
    orders,
    setOrders,
    results,
    selectedOrders,
    setSelectedOrders,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    setTotalSum,
    columnOrder,
    hiddenHeaders,
    onRowClick,
    client_type = "client",
    selectedTab,
    shipmentQuantities,
    handleQuantityChange,
}: ReceivedOrdersTableProps) {
    const [searchParams] = useSearchParams();
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) => !hiddenHeaders.includes(header.key)
    );

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        if (displayedHeaders) {
            const key = displayedHeaders[columnIndex].key;
            if (key) return order[key];
            return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection(
        [],
        getCellValue,
        displayedHeaders
            ?.map((column, index) => (column.isSummable ? index : -1))
            .filter((index) => index !== -1) || []
    );

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setSelectedOrders) {
            if (e.target.checked) {
                setSelectedOrders(orders);
            } else {
                // setAllClientsSelected(false);
                setSelectedOrders([]);
            }
        }
    };

    const handleCheckboxChange = (order: ReceivedOrder) => {
        const orderId = order.received_order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.received_order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders(
                    selectedOrders.filter(
                        (o) => o.received_order_id !== orderId
                    )
                );
            } else {
                // Add the order using its id
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    useEffect(() => {
        const formattedTotal = sumSelectedQuantities
            .filter(({ sum }) => sum > 0)
            .map(({ columnIndex, sum }) =>
                displayedHeaders &&
                ["unit_cost", "list_price", "shipment_value"].includes(
                    displayedHeaders[columnIndex].key
                )
                    ? formatMoney(sum)
                    : sum.toString()
            )
            .join(", ");

        setTotalSum(formattedTotal);
    }, [sumSelectedQuantities]);

    const [selectedOrderForNotes, setSelectedOrderForNotes] =
        useState<ReceivedOrder | null>(null);
    const [openReceivedOrderNotesModal, setOpenReceivedOrderNotesModal] =
        useState(false);

    useEffect(() => {
        if (selectedOrderForNotes) setOpenReceivedOrderNotesModal(true);
    }, [selectedOrderForNotes]);

    useEffect(() => {
        if (!openReceivedOrderNotesModal) setSelectedOrderForNotes(null);
    }, [openReceivedOrderNotesModal]);

    const [selectedOrder, setSelectedOrder] = useState<ReceivedOrder | null>(
        null
    );
    const [openEditReceivedOrderModal, setOpenEditReceivedOrderModal] =
        useState(false);

    useEffect(() => {
        if (selectedOrder) setOpenEditReceivedOrderModal(true);
    }, [selectedOrder]);

    useEffect(() => {
        if (!openEditReceivedOrderModal) setSelectedOrder(null);
    }, [openEditReceivedOrderModal]);

    const handleRowClick = (order: ReceivedOrder) => {
        setSelectedOrder(order);
    };

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    return (
        <div className="flex flex-col">
            {orders && orders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                            >
                                                {header.key === "select" ? (
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={
                                                            handleSelectAll
                                                        }
                                                        checked={
                                                            selectedOrders &&
                                                            orders.length ===
                                                                selectedOrders.length &&
                                                            orders.length > 0
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        {header.label}
                                                        <ColumnSort
                                                            header={header}
                                                            sortConfig={
                                                                sortConfig
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {orders.map((order, rowIndex) => (
                                    <tr
                                        key={order.received_order_id}
                                        className={`relative border-t border-gray-200 text-sm text-gray-500 ${
                                            client_type !== "client" && ""
                                        } ${
                                            selectedOrders &&
                                            selectedOrders.some(
                                                (o) =>
                                                    o.received_order_id ===
                                                    order.received_order_id
                                            )
                                                ? "bg-gray-100"
                                                : ""
                                        }`}
                                    >
                                        {displayedHeaders &&
                                            displayedHeaders.map(
                                                (header, colIndex) => (
                                                    <>
                                                        {header.key ===
                                                        "select" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            order
                                                                        )
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    checked={
                                                                        selectedOrders &&
                                                                        selectedOrders.some(
                                                                            (
                                                                                o
                                                                            ) =>
                                                                                o.received_order_id ===
                                                                                order.received_order_id
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        ) : header.key ===
                                                              "input_shipping_quantity" &&
                                                          handleQuantityChange &&
                                                          shipmentQuantities ? (
                                                            <td>
                                                                <input
                                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                                    type="number"
                                                                    value={
                                                                        shipmentQuantities[
                                                                            order
                                                                                .received_order_id
                                                                        ] || ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleQuantityChange(
                                                                            order.received_order_id,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onWheel={(
                                                                        e
                                                                    ) => {
                                                                        const target =
                                                                            e.target as HTMLInputElement;
                                                                        target.blur();
                                                                        setTimeout(
                                                                            () =>
                                                                                target.focus(),
                                                                            0
                                                                        );
                                                                    }}
                                                                ></input>
                                                            </td>
                                                        ) : header.key ===
                                                          "status" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                <ReceivedOrderStatusTag
                                                                    status={
                                                                        order.status
                                                                    }
                                                                    handleClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleRowClick(
                                                                            order
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                        ) : header.key ===
                                                          "created_at" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                {convertToESTV2(
                                                                    order?.created_at ||
                                                                        ""
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "tracking_number" ? (
                                                            <td className="text-accent cursor-pointer whitespace-nowrap">
                                                                {order.trackings &&
                                                                order.trackings
                                                                    .length >
                                                                    0 ? (
                                                                    <span
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            handleViewTrackings(
                                                                                [
                                                                                    ...(order.trackings ||
                                                                                        []),
                                                                                    {
                                                                                        tracking:
                                                                                            order.tracking_number,
                                                                                        carrier:
                                                                                            order.carrier,
                                                                                    },
                                                                                ]
                                                                            );
                                                                        }}
                                                                    >
                                                                        View
                                                                    </span>
                                                                ) : (
                                                                    <div className="flex flex-row items-center">
                                                                        <span
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    createTrackingUrl(
                                                                                        order.tracking_number,
                                                                                        order.carrier as Carrier
                                                                                    ),
                                                                                    "_blank"
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                order.tracking_number
                                                                            }
                                                                        </span>
                                                                        {order.tracking_number &&
                                                                            order.tracking_number !==
                                                                                "" && (
                                                                                <span className="mt-1">
                                                                                    <SmallCopyButton
                                                                                        textToCopy={
                                                                                            order.tracking_number
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "upc" ? (
                                                            <td className="text-accent cursor-pointer whitespace-nowrap">
                                                                <div className="flex flex-row items-center">
                                                                    <span
                                                                        onClick={() =>
                                                                            window.open(
                                                                                "https://www.barcodelookup.com/" +
                                                                                    order.upc,
                                                                                "_blank"
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            order.upc
                                                                        }
                                                                    </span>
                                                                    {order.upc &&
                                                                        order.upc !==
                                                                            "" && (
                                                                            <span className="mt-1">
                                                                                <SmallCopyButton
                                                                                    textToCopy={
                                                                                        order.upc
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </td>
                                                        ) : header.key ===
                                                          "check_in_notes" ? (
                                                            <td className="pl-4">
                                                                <div className="flex flex-col items-start">
                                                                    <span>
                                                                        {
                                                                            order.check_in_notes
                                                                        }
                                                                    </span>
                                                                    {order.check_in_notes && (
                                                                        <span className="text-2xs font-semibold whitespace-nowrap">
                                                                            {convertToESTV2(
                                                                                order.created_at,
                                                                                true,
                                                                                false
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        ) : header.key ===
                                                          "team_member_notes" ? (
                                                            <td className="pl-4">
                                                                <div className="flex flex-col items-start">
                                                                    <span>
                                                                        {
                                                                            order.team_member_notes
                                                                        }
                                                                    </span>
                                                                    {order.team_member_notes_at && (
                                                                        <span className="text-2xs font-semibold whitespace-nowrap">
                                                                            {convertToESTV2(
                                                                                order.team_member_notes_at,
                                                                                true,
                                                                                false
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        ) : header.key ===
                                                          "client_notes" ? (
                                                            <td className="pl-4">
                                                                <div className="flex flex-col items-start">
                                                                    <span>
                                                                        {
                                                                            order.client_notes
                                                                        }
                                                                    </span>
                                                                    {order.client_notes_at && (
                                                                        <span className="text-2xs font-semibold whitespace-nowrap">
                                                                            {convertToESTV2(
                                                                                order.client_notes_at,
                                                                                true,
                                                                                false
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        ) : header.isSummable ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        orders
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        orders
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        orders
                                                                    )
                                                                }
                                                                className={`py-2.5 ${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                colIndex
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {[
                                                                    "unit_cost",
                                                                    "list_price",
                                                                    "shipment_value",
                                                                ].includes(
                                                                    String(
                                                                        header.key
                                                                    )
                                                                ) ? (
                                                                    <>
                                                                        {formatMoney(
                                                                            Number(
                                                                                order[
                                                                                    header.key as keyof ReceivedOrder
                                                                                ]
                                                                            )
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {String(
                                                                            order[
                                                                                header.key as keyof ReceivedOrder
                                                                            ]
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                                className={`${
                                                                    header.className?.includes(
                                                                        "pl-4"
                                                                    ) && "pl-4"
                                                                }`}
                                                            >
                                                                {String(
                                                                    order[
                                                                        header.key as keyof ReceivedOrder
                                                                    ]
                                                                )}
                                                            </td>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {results && (
                        <>
                            <nav
                                aria-label="Pagination"
                                className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                            >
                                <div className="hidden sm:block">
                                    <div className="flex flex-row items-center space-x-3">
                                        <PaginationResults results={results} />
                                        <LimitSelector />
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-between sm:justify-end">
                                    <PaginationLink
                                        href={(function () {
                                            const params = new URLSearchParams(
                                                searchParams
                                            );
                                            // Set the previous page offset
                                            params.set(
                                                "offset",
                                                calculateOffset(
                                                    searchParams,
                                                    "back"
                                                ).toString()
                                            );
                                            if (
                                                !params.has("page") &&
                                                selectedTab
                                            ) {
                                                params.set("page", selectedTab);
                                            } else if (selectedTab) {
                                                params.set("page", selectedTab); // Update existing "page" value
                                            }

                                            return `${
                                                window.location.pathname
                                            }?${params.toString()}`;
                                        })()}
                                        disabled={getOffset(searchParams) === 0}
                                        text="Previous"
                                    />
                                    <PaginationLink
                                        href={(function () {
                                            const params = new URLSearchParams(
                                                searchParams
                                            );
                                            // Set the next page offset
                                            params.set(
                                                "offset",
                                                calculateOffset(
                                                    searchParams,
                                                    "forward"
                                                ).toString()
                                            );
                                            if (
                                                !params.has("page") &&
                                                selectedTab
                                            ) {
                                                params.set("page", selectedTab);
                                            } else if (selectedTab) {
                                                params.set("page", selectedTab); // Update existing "page" value
                                            }
                                            return `${
                                                window.location.pathname
                                            }?${params.toString()}`;
                                        })()}
                                        disabled={
                                            results.showing_to >=
                                            results.total_results
                                        }
                                        text="Next"
                                        className="ml-3"
                                    />
                                </div>
                            </nav>
                        </>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    {false ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            No records found given this criteria.
                        </span>
                    )}
                </div>
            )}

            <EditReceivedOrderModal
                receivedOrder={selectedOrder}
                setOrders={setOrders}
                openEditReceivedOrderModal={openEditReceivedOrderModal}
                setOpenEditReceivedOrderModal={setOpenEditReceivedOrderModal}
                handleRefetch={handleRefetch}
            />

            <TrackingNumbersModal
                openTrackingNumbersModal={openTrackingNumbersModal}
                setOpenTrackingNumbersModal={setOpenTrackingNumbersModal}
                trackingNumbers={selectedTrackingNumbers}
            />
        </div>
    );
}

export default ReceivedOrdersTable;
