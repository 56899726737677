/** @format */

// /** @format */

// import { useState, useEffect, useRef } from "react";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
// import { IoMdAdd } from "react-icons/io";
// import dayjs, { Dayjs } from "dayjs";
// import WeekCalendar from "./weekCalendar";
// import CalendarEvents, { CalendarEventsNoTimes } from "./events";
// import AdminSideBar from "../../../../components/admin/sideBar";
// import Header from "../../../../components/header";
// import { useClient } from "../../../../utils/client/clientContext";
// import { fetchEmployees, fetchScheduleEvents } from "../api/employeesApi";
// import AddScheduleEventModal from "./addScheduleEventModal";
// import { Employee } from "../../../../models/employees";
// import { useNotification } from "../../../../utils/notification/notificationContext";
// import MediumSelector from "../../../../components/dropdowns/mediumSelector";
// import { SortLoading } from "../../../../components/loadingWheel";
// import { getAdminType } from "../../../../utils/adminTypes/actions";

// export interface ScheduleEvent {
//     event_id: string;
//     date: string;
//     start_time: string;
//     end_time: string;
//     description: string;
//     column_number: number;
//     employee_id: string;
//     employee_name: string;
// }

// export default function AdminScheduleCalendar() {
//     const { showNotification } = useNotification();
//     const { orderCount } = useClient();

//     const isOwner = getAdminType() === "owner";

//     const [selectedWeek, setSelectedWeek] = useState<Dayjs[]>([]); // use this variable for

//     const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
//     const [isCalendarVisible, setIsCalendarVisible] = useState(false);
//     const container = useRef<HTMLDivElement>(null);
//     const containerNav = useRef<HTMLDivElement>(null);
//     const containerOffset = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//         const startOfWeek = getStartOfWeekMonday(dayjs());
//         updateSelectedWeek(startOfWeek);
//     }, []);

//     const getStartOfWeekMonday = (date: Dayjs) => {
//         const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
//         const diff = (weekday + 6) % 7; // Calculate difference to Monday
//         return date.subtract(diff, "day").startOf("day");
//     };

//     const updateSelectedWeek = (startOfWeek: Dayjs) => {
//         const weekDays: Dayjs[] = [];
//         let day = startOfWeek;
//         for (let i = 0; i < 7; i++) {
//             weekDays.push(day);
//             day = day.add(1, "day");
//         }
//         setSelectedWeek(weekDays);
//         setCurrentDate(startOfWeek); // Update current date to reflect selected week
//     };

//     const handlePreviousWeek = () => {
//         const startOfWeek = getStartOfWeekMonday(
//             currentDate.subtract(1, "week")
//         );
//         updateSelectedWeek(startOfWeek);
//     };

//     const handleNextWeek = () => {
//         const startOfWeek = getStartOfWeekMonday(currentDate.add(1, "week"));
//         updateSelectedWeek(startOfWeek);
//     };

//     const [scheduleEvents, setScheduleEvents] = useState<ScheduleEvent[]>([]);

//     const [selectedEmployee, setSelectedEmployee] = useState<{
//         id: string;
//         name: string;
//         value: string;
//     }>({ id: "null", name: "All employees", value: "all" });

//     const [refetchData, setRefetchData] = useState(false);
//     const handleRefetch = () => {
//         setRefetchData((prev) => !prev);
//     };

//     const [loading, setLoading] = useState(false);
//     useEffect(() => {
//         const controller = new AbortController(); // Create an AbortController instance
//         const signal = controller.signal; // Get the signal to attach to fetch

//         const fetchData = async () => {
//             try {
//                 setLoading(true);
//                 const data = await fetchScheduleEvents(
//                     selectedEmployee.id,
//                     selectedWeek[0]?.format("YYYY-MM-DD"),
//                     signal
//                 );
//                 if (data.status === "success") {
//                     setScheduleEvents(data.data.events);
//                 }
//             } catch (error) {
//                 if (error instanceof Error) {
//                     if (error.name === "AbortError") {
//                         console.log("Request aborted due to week change.");
//                     } else {
//                         console.error("Fetch error:", error);
//                     }
//                 }
//             }
//             setLoading(false);
//         };

//         if (selectedWeek.length > 0) {
//             fetchData();
//         }

//         return () => {
//             controller.abort();
//         };
//     }, [selectedWeek, selectedEmployee, refetchData]);

//     const [openAddScheduleEventModal, setOpenAddScheduleEventModal] =
//         useState(false);

//     const [employees, setEmployees] = useState<Employee[]>([]);

//     const selectorData = [
//         { id: "null", name: "All employees", value: "all" },
//         ...employees.map((employee) => ({
//             id: employee.employee_id,
//             name: employee.name,
//             value: employee.email,
//         })),
//     ];

//     const fetchEmployeesData = async () => {
//         const data = await fetchEmployees();
//         if (data.status === "success") {
//             setEmployees(data.data.employees);
//         } else {
//             showNotification("Failed to fetch data", data.message, "error");
//         }
//     };

//     useEffect(() => {
//         fetchEmployeesData();
//     }, []);

//     return (
//         <div className="flex flex-row">
//             <AdminSideBar orderCount={orderCount} />
//             <div className="flex h-full flex-col w-full sm:px-10 px-4 pt-10">
//                 <Header />
//                 <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4">
//                     <h1 className="text-base font-semibold leading-6 text-gray-900">
//                         {selectedWeek[0]?.format("MMMM D")}
//                         {selectedWeek[0]?.month() !== selectedWeek[6]?.month()
//                             ? " - " + selectedWeek[6]?.format("MMMM D, YYYY")
//                             : " - " + selectedWeek[6]?.format("D, YYYY")}
//                     </h1>
//                     <div className="flex items-center">
//                         {isOwner && (
//                             <div className="pr-4">
//                                 <div className="w-64">
//                                     <MediumSelector
//                                         selected={selectedEmployee}
//                                         setSelected={setSelectedEmployee}
//                                         selectorData={selectorData}
//                                     />
//                                 </div>
//                             </div>
//                         )}
//                         <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
//                             <button
//                                 type="button"
//                                 onClick={handlePreviousWeek}
//                                 className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 hover:bg-gray-50"
//                             >
//                                 <span className="sr-only">Previous week</span>
//                                 <ChevronLeftIcon
//                                     className="h-5 w-5"
//                                     aria-hidden="true"
//                                 />
//                             </button>
//                             <WeekCalendar
//                                 selectedWeek={selectedWeek}
//                                 setSelectedWeek={setSelectedWeek}
//                                 currentDate={currentDate}
//                                 setCurrentDate={setCurrentDate}
//                                 isCalendarVisible={isCalendarVisible}
//                                 setIsCalendarVisible={setIsCalendarVisible}
//                             />
//                             <button
//                                 type="button"
//                                 onClick={handleNextWeek}
//                                 className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 hover:bg-gray-50"
//                             >
//                                 <span className="sr-only">Next week</span>
//                                 <ChevronRightIcon
//                                     className="h-5 w-5"
//                                     aria-hidden="true"
//                                 />
//                             </button>
//                         </div>
//                         {isOwner && (
//                             <div className="hidden md:ml-4 md:flex md:items-center">
//                                 <div className="mx-6 h-6 w-px bg-gray-300" />
//                                 <button
//                                     className="flex h-8 flex-row items-center rounded-lg bg-accent px-2 hover:bg-accenthighlight"
//                                     onClick={() =>
//                                         setOpenAddScheduleEventModal(true)
//                                     }
//                                 >
//                                     <IoMdAdd
//                                         size={16}
//                                         style={{ color: "white" }}
//                                     />
//                                     <p className="pl-1 text-white font-medium text-xs">
//                                         Add Event
//                                     </p>
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 </header>
//                 <div
//                     ref={container}
//                     className="isolate flex flex-auto flex-col overflow-auto bg-white"
//                 >
//                     <div
//                         style={{ width: "165%" }}
//                         className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
//                     >
//                         <div
//                             ref={containerNav}
//                             className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
//                         >
//                             <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
//                                 {selectedWeek.map((day) => {
//                                     const isToday = day.isSame(dayjs(), "day");
//                                     return (
//                                         <button
//                                             key={day.format("YYYY-MM-DD")}
//                                             type="button"
//                                             className="flex flex-col items-center pb-3 pt-2"
//                                         >
//                                             {day.format("dd").charAt(0)}
//                                             <span
//                                                 className={`mt-1 flex h-8 w-8 items-center justify-center font-semibold ${
//                                                     isToday
//                                                         ? "rounded-full bg-accent text-white"
//                                                         : "text-gray-900"
//                                                 }`}
//                                             >
//                                                 {day.format("D")}
//                                             </span>
//                                         </button>
//                                     );
//                                 })}
//                             </div>
//                             <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
//                                 <div className="col-end-1 w-14" />
//                                 {selectedWeek.map((day) => {
//                                     const isToday = day.isSame(dayjs(), "day");
//                                     return (
//                                         <div
//                                             key={day.format("YYYY-MM-DD")}
//                                             className="flex items-center justify-center py-3"
//                                         >
//                                             <span className="flex items-baseline">
//                                                 {day.format("ddd")}{" "}
//                                                 <span
//                                                     className={`h-8 flex items-center justify-center font-semibold ${
//                                                         day.isSame(
//                                                             dayjs(),
//                                                             "day"
//                                                         )
//                                                             ? "w-8 ml-1.5 rounded-full bg-accent text-white"
//                                                             : "text-gray-900 ml-1"
//                                                     }`}
//                                                 >
//                                                     {day.format("D")}
//                                                 </span>
//                                             </span>
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                         <div className="flex flex-auto">
//                             <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
//                             <div className="grid flex-auto grid-cols-1 grid-rows-1 relative">
//                                 <SortLoading
//                                     sortLoading={loading}
//                                     scrollOffset={0}
//                                     removePadding={true}
//                                 />
//                                 {/* Horizontal lines */}
//                                 {/* <div
//                                     className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
//                                     style={{
//                                         gridTemplateRows:
//                                             "repeat(12, minmax(3.5rem, 1fr))", // Changed to 6 rows for 4-hour increments
//                                     }}
//                                 >
//                                     <div
//                                         ref={containerOffset}
//                                         className="row-end-1 h-7"
//                                     ></div>

//                                     {[
//                                         "12AM",
//                                         "2AM",
//                                         "4AM",
//                                         "6AM",
//                                         "8AM",
//                                         "10AM",
//                                         "12PM",
//                                         "2PM",
//                                         "4PM",
//                                         "6PM",
//                                         "8PM",
//                                         "10PM",
//                                     ].map((time, index) => (
//                                         <div key={index}>
//                                             <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
//                                                 {time}
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div> */}

//                                 <div
//                                     className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
//                                     style={{
//                                         gridTemplateRows:
//                                             "repeat(9, minmax(3.5rem, 1fr))", // 18 rows for 6:00 AM to 12:00 AM
//                                     }}
//                                 >
//                                     <div
//                                         ref={containerOffset}
//                                         className="row-end-1 h-7"
//                                     ></div>

//                                     {[
//                                         "6AM",
//                                         "8AM",
//                                         "10AM",
//                                         "12PM",
//                                         "2PM",
//                                         "4PM",
//                                         "6PM",
//                                         "8PM",
//                                         "10PM",
//                                         "12AM",
//                                     ].map((time, index) => (
//                                         <div key={index}>
//                                             {(selectedEmployee.id !== "null" ||
//                                                 !isOwner) && (
//                                                 <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
//                                                     {time}
//                                                 </div>
//                                             )}
//                                         </div>
//                                     ))}
//                                 </div>

//                                 {/* Vertical lines */}
//                                 <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
//                                     <div className="col-start-1 row-span-full" />
//                                     <div className="col-start-2 row-span-full" />
//                                     <div className="col-start-3 row-span-full" />
//                                     <div className="col-start-4 row-span-full" />
//                                     <div className="col-start-5 row-span-full" />
//                                     <div className="col-start-6 row-span-full" />
//                                     <div className="col-start-7 row-span-full" />
//                                     <div className="col-start-8 row-span-full w-8" />
//                                 </div>

//                                 {/* Events */}
//                                 {selectedEmployee.id !== "null" || !isOwner ? (
//                                     <CalendarEvents
//                                         scheduleEvents={scheduleEvents}
//                                         handleRefetch={handleRefetch}
//                                     />
//                                 ) : (
//                                     <CalendarEventsNoTimes
//                                         scheduleEvents={scheduleEvents}
//                                         handleRefetch={handleRefetch}
//                                     />
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <AddScheduleEventModal
//                 openAddScheduleEventModal={openAddScheduleEventModal}
//                 setOpenAddScheduleEventModal={setOpenAddScheduleEventModal}
//                 handleRefetch={handleRefetch}
//             />
//         </div>
//     );
// }

/** @format */

import { useState, useEffect, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { IoMdAdd } from "react-icons/io";
import dayjs, { Dayjs } from "dayjs";
import WeekCalendar from "./weekCalendar";
import CalendarEvents, { CalendarEventsNoTimes } from "./events";
import AdminSideBar from "../../../../components/admin/sideBar";
import Header from "../../../../components/header";
import { useClient } from "../../../../utils/client/clientContext";
import { fetchEmployees, fetchScheduleEvents } from "../api/employeesApi";
import AddScheduleEventModal from "./addScheduleEventModal";
import { Employee } from "../../../../models/employees";
import { useNotification } from "../../../../utils/notification/notificationContext";
import MediumSelector from "../../../../components/dropdowns/mediumSelector";
import { SortLoading } from "../../../../components/loadingWheel";
import { getAdminType } from "../../../../utils/adminTypes/actions";
import { convertToESTV2 } from "../../../../utils/date/date_formatter";
import CopyButton from "../../../../components/copyButton";

export interface ScheduleEvent {
    event_id: string;
    date: string;
    start_time: string;
    end_time: string;
    description: string;
    column_number: number;
    employee_id: string;
    employee_name: string;
}

const copyScheduleToClipboard = (scheduleEvents: ScheduleEvent[]) => {
    const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const groupedByDay = scheduleEvents.reduce((acc, event) => {
        const dayName = daysOfWeek[(new Date(event.date).getDay() + 6) % 7];
        if (!acc[dayName]) acc[dayName] = [];
        acc[dayName].push(event);
        return acc;
    }, {} as Record<string, ScheduleEvent[]>);

    let scheduleText = "";

    for (const day of daysOfWeek) {
        if (groupedByDay[day]) {
            const date = new Date(groupedByDay[day][0].date).toLocaleDateString(
                "en-US"
            );
            scheduleText += `${day} ${date}\n`;
            groupedByDay[day].forEach((event) => {
                const startTime = event.start_time;
                const endTime = event.end_time ? event.end_time : "No end time";
                scheduleText += `${event.employee_name}: ${startTime}-${endTime}\n`;
            });
            scheduleText += "\n"; // Add spacing between days
        }
    }

    return scheduleText;
};

export default function AdminScheduleCalendar() {
    const { showNotification } = useNotification();
    const { orderCount } = useClient();

    const isOwner = getAdminType() === "owner";

    const [selectedWeek, setSelectedWeek] = useState<Dayjs[]>([]); // use this variable for

    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const containerNav = useRef<HTMLDivElement>(null);
    const containerOffset = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const startOfWeek = getStartOfWeekMonday(dayjs());
        updateSelectedWeek(startOfWeek);
    }, []);

    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    const updateSelectedWeek = (startOfWeek: Dayjs) => {
        const weekDays: Dayjs[] = [];
        let day = startOfWeek;
        for (let i = 0; i < 7; i++) {
            weekDays.push(day);
            day = day.add(1, "day");
        }
        setSelectedWeek(weekDays);
        setCurrentDate(startOfWeek); // Update current date to reflect selected week
    };

    const handlePreviousWeek = () => {
        const startOfWeek = getStartOfWeekMonday(
            currentDate.subtract(1, "week")
        );
        updateSelectedWeek(startOfWeek);
    };

    const handleNextWeek = () => {
        const startOfWeek = getStartOfWeekMonday(currentDate.add(1, "week"));
        updateSelectedWeek(startOfWeek);
    };

    const [scheduleEvents, setScheduleEvents] = useState<ScheduleEvent[]>([]);

    const [selectedEmployee, setSelectedEmployee] = useState<{
        id: string;
        name: string;
        value: string;
    }>({ id: "null", name: "All employees", value: "all" });

    const [refetchData, setRefetchData] = useState(false);
    const handleRefetch = () => {
        setRefetchData((prev) => !prev);
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const controller = new AbortController(); // Create an AbortController instance
        const signal = controller.signal; // Get the signal to attach to fetch

        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await fetchScheduleEvents(
                    selectedEmployee.id,
                    selectedWeek[0]?.format("YYYY-MM-DD"),
                    signal
                );
                if (data.status === "success") {
                    setScheduleEvents(data.data.events);
                }
            } catch (error) {
                if (error instanceof Error) {
                    if (error.name === "AbortError") {
                        console.log("Request aborted due to week change.");
                    } else {
                        console.error("Fetch error:", error);
                    }
                }
            }
            setLoading(false);
        };

        if (selectedWeek.length > 0) {
            fetchData();
        }

        return () => {
            controller.abort();
        };
    }, [selectedWeek, selectedEmployee, refetchData]);

    const [openAddScheduleEventModal, setOpenAddScheduleEventModal] =
        useState(false);

    const [employees, setEmployees] = useState<Employee[]>([]);

    const selectorData = [
        { id: "null", name: "All employees", value: "all" },
        ...employees
            .filter((employee) => !employee.hidden) // Exclude hidden employees
            .map((employee) => ({
                id: employee.employee_id,
                name: employee.name,
                value: employee.email,
            })),
    ];

    const fetchEmployeesData = async () => {
        const data = await fetchEmployees();
        if (data.status === "success") {
            setEmployees(data.data.employees);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
    };

    useEffect(() => {
        fetchEmployeesData();
    }, []);

    return (
        <div className="flex flex-row">
            <AdminSideBar orderCount={orderCount} />
            <div className="flex h-full flex-col w-full sm:px-10 px-4 pt-10">
                <Header />
                <header className="flex flex-col sm:flex-row sm:items-center sm:justify-between border-b border-gray-200 py-4">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        {selectedWeek[0]?.format("MMMM D")}
                        {selectedWeek[0]?.month() !== selectedWeek[6]?.month()
                            ? " - " + selectedWeek[6]?.format("MMMM D, YYYY")
                            : " - " + selectedWeek[6]?.format("D, YYYY")}
                    </h1>
                    <div className="flex sm:items-center flex-col sm:flex-row sm:space-y-0 space-y-4 sm:pt-0 pt-2">
                        <div className="flex flex-row">
                            {isOwner && (
                                <div className="pr-4 w-full">
                                    <div className="sm:w-64 w-full">
                                        <MediumSelector
                                            selected={selectedEmployee}
                                            setSelected={setSelectedEmployee}
                                            selectorData={selectorData}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                                <button
                                    type="button"
                                    onClick={handlePreviousWeek}
                                    className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 hover:bg-gray-50"
                                >
                                    <span className="sr-only">
                                        Previous week
                                    </span>
                                    <ChevronLeftIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                                <WeekCalendar
                                    selectedWeek={selectedWeek}
                                    setSelectedWeek={setSelectedWeek}
                                    currentDate={currentDate}
                                    setCurrentDate={setCurrentDate}
                                    isCalendarVisible={isCalendarVisible}
                                    setIsCalendarVisible={setIsCalendarVisible}
                                />
                                <button
                                    type="button"
                                    onClick={handleNextWeek}
                                    className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Next week</span>
                                    <ChevronRightIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                        {isOwner && (
                            <div className="sm:ml-4 flex items-center">
                                <div className="mx-6 h-6 w-px bg-gray-300 hidden sm:block" />
                                <div className="pr-3 mt-1">
                                    <CopyButton
                                        textToCopy={
                                            copyScheduleToClipboard(
                                                scheduleEvents
                                            ) || ""
                                        }
                                    />
                                </div>
                                <button
                                    className="flex h-8 flex-row items-center rounded-lg bg-accent px-2 hover:bg-accenthighlight"
                                    onClick={() =>
                                        setOpenAddScheduleEventModal(true)
                                    }
                                >
                                    <IoMdAdd
                                        size={16}
                                        style={{ color: "white" }}
                                    />
                                    <p className="pl-1 text-white font-medium text-xs">
                                        Add Event
                                    </p>
                                </button>
                            </div>
                        )}
                    </div>
                </header>
                <div
                    ref={container}
                    className="isolate flex flex-auto flex-col overflow-x-auto bg-white"
                >
                    <div
                        style={{ width: "165%" }}
                        className="flex flex-col max-w-full"
                    >
                        <div
                            ref={containerNav}
                            className="sticky top-0 z-30 bg-white shadow ring-1 ring-black ring-opacity-5 pr-8"
                            style={{ minWidth: "928px" }}
                        >
                            <div
                                className="-mr-px grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 grid"
                                style={{
                                    gridTemplateColumns:
                                        "repeat(7, minmax(120px, 1fr))",
                                }}
                            >
                                <div className="col-end-1 w-14" />
                                {selectedWeek.map((day) => {
                                    const isToday = day.isSame(dayjs(), "day");
                                    return (
                                        <div
                                            key={day.format("YYYY-MM-DD")}
                                            className="flex items-center justify-center py-3"
                                        >
                                            <span className="flex items-baseline">
                                                {day.format("ddd")}{" "}
                                                <span
                                                    className={`h-8 flex items-center justify-center font-semibold ${
                                                        day.isSame(
                                                            dayjs(),
                                                            "day"
                                                        )
                                                            ? "w-8 ml-1.5 rounded-full bg-accent text-white"
                                                            : "text-gray-900 ml-1"
                                                    }`}
                                                >
                                                    {day.format("D")}
                                                </span>
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex flex-auto">
                            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                            <div
                                style={{ minWidth: "870px" }}
                                className="grid flex-auto grid-cols-1 grid-rows-1 relative"
                            >
                                <SortLoading
                                    sortLoading={loading}
                                    scrollOffset={0}
                                    removePadding={true}
                                />

                                <div
                                    className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                                    style={{
                                        gridTemplateRows:
                                            selectedEmployee.id !== "null" ||
                                            !isOwner
                                                ? "repeat(9, minmax(3.5rem, 1fr))"
                                                : "repeat(18, minmax(3.5rem, 1fr))",
                                        // 18 rows for 6:00 AM to 12:00 AM
                                    }}
                                >
                                    <div
                                        ref={containerOffset}
                                        className="row-end-1 h-7"
                                    ></div>

                                    {[
                                        "6AM",
                                        "8AM",
                                        "10AM",
                                        "12PM",
                                        "2PM",
                                        "4PM",
                                        "6PM",
                                        "8PM",
                                        "10PM",
                                        "12AM",
                                    ].map((time, index) => (
                                        <div key={index}>
                                            {(selectedEmployee.id !== "null" ||
                                                !isOwner) && (
                                                <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                                    {time}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* Vertical lines */}
                                <div
                                    style={{
                                        gridTemplateColumns:
                                            "repeat(7, minmax(120px, 1fr))",
                                    }}
                                    className="col-start-1 col-end-2 row-start-1 grid-rows-1 divide-x divide-gray-100 grid grid-cols-7 "
                                >
                                    <div className="col-start-1 row-span-full" />
                                    <div className="col-start-2 row-span-full" />
                                    <div className="col-start-3 row-span-full" />
                                    <div className="col-start-4 row-span-full" />
                                    <div className="col-start-5 row-span-full" />
                                    <div className="col-start-6 row-span-full" />
                                    <div className="col-start-7 row-span-full" />
                                    <div className="col-start-8 row-span-full w-8" />
                                </div>

                                {/* Events */}
                                {selectedEmployee.id !== "null" || !isOwner ? (
                                    <CalendarEvents
                                        scheduleEvents={scheduleEvents}
                                        handleRefetch={handleRefetch}
                                    />
                                ) : (
                                    <CalendarEventsNoTimes
                                        scheduleEvents={scheduleEvents}
                                        handleRefetch={handleRefetch}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddScheduleEventModal
                openAddScheduleEventModal={openAddScheduleEventModal}
                setOpenAddScheduleEventModal={setOpenAddScheduleEventModal}
                weekToSelect={selectedWeek}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}
