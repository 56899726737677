/** @format */

import { useSearchParams } from "react-router-dom";
import AdminSideBar from "../../../../components/admin/sideBar";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useClient } from "../../../../utils/client/clientContext";
import Header from "../../../../components/header";
import TabSelector from "../../../../components/tabSelector";
import { warehouseClarifiationTabs } from "../tabs";
import { useEffect, useState } from "react";
import { convertToESTV2 } from "../../../../utils/date/date_formatter";
import { ActionButton } from "../../../../components/buttons";
import { fetchWorklist } from "../../worklist/api/worklistApi";
import LoadingWheel from "../../../../components/loadingWheel";
import WarehouseClarificationModal from "../warehouseClarificationModal";
import { ReceivedOrder } from "../../../../models/order";
import { TrashIcon } from "@heroicons/react/20/solid";
import { deleteWarehouseClarification } from "../api/warehouseClarificationApi";

export interface WarehouseClarificationTask {
    id: number;
    order_ids: string[];
    question: string;
    response: string;
    created_at: string;
    updated_at: string;
    response_by: string;
    order_type: "received" | "inventory";
    images: string[];
    status: "completed" | "answered" | "not-answered";
}

function AdminWarehouseClarificationTasksPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const { user_id, setUserId, orderCount } = useClient();

    const [warehouseClarificationTasks, setWarehouseClarificationTasks] =
        useState<WarehouseClarificationTask[]>([]);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        const data = await fetchWorklist("warehouse_clarification_tasks", true);
        if (data.status === "success") {
            setWarehouseClarificationTasks(
                data.data.warehouse_clarification_tasks.data
            );
            setCount(data.data.warehouse_clarification_tasks.total_count);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const [_orders, _setOrders] = useState<ReceivedOrder[]>([]);

    const [
        openWarehouseClarificationModal,
        setOpenWarehouseClarificationModal,
    ] = useState(false);

    const [selectedWarehouseClarification, setSelectedWarehouseClarification] =
        useState<WarehouseClarificationTask | null>(null);

    useEffect(() => {
        if (selectedWarehouseClarification)
            setOpenWarehouseClarificationModal(true);
    }, [selectedWarehouseClarification]);

    useEffect(() => {
        if (!openWarehouseClarificationModal)
            setSelectedWarehouseClarification(null);
    }, [openWarehouseClarificationModal]);

    const [deleteLoadings, setDeleteLoadings] = useState<number[]>([]);
    const handleDelete = async (id: number) => {
        setDeleteLoadings((prev) => [...prev, id]);
        const data = await deleteWarehouseClarification(id);
        if (data.status === "success") {
            showNotification(data.message, "", "success");
            setWarehouseClarificationTasks((prev) =>
                prev.filter((task) => task.id !== id)
            );
        } else {
            showNotification(data.message, data.errors[0], "error");
        }
        setDeleteLoadings((prev) => prev.filter((id) => id !== id));
    };

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center space-x-4">
                    <span className="text-2xl font-bold">Tasks</span>
                    <div className="border border-accent px-1.5 py-1 text-accent text-xs font-medium rounded-xl">
                        {count} Actions
                    </div>
                </div>

                <TabSelector tabs={warehouseClarifiationTabs(0)} />

                <div className="pt-6">
                    <div className="grid grid-cols-1 w-full gap-y-2 pt-2 pb-10">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <>
                                {warehouseClarificationTasks &&
                                    warehouseClarificationTasks.length < 1 && (
                                        <p className="text-sm text-gray-600">
                                            No warehouse clarification tasks
                                        </p>
                                    )}
                            </>
                        )}
                        {!loading &&
                            warehouseClarificationTasks &&
                            warehouseClarificationTasks.map((action) => (
                                <div key={action.id}>
                                    <div
                                        onClick={() =>
                                            setSelectedWarehouseClarification(
                                                action
                                            )
                                        }
                                        className="flex flex-row items-center justify-between hover:bg-gray-100 py-1 rounded-md cursor-pointer"
                                    >
                                        <div className="flex flex-col">
                                            <h4 className="font-medium text-gray-700">
                                                Warehouse clarification
                                                requested
                                            </h4>
                                            <p className="text-gray-600 text-sm">
                                                By {"VA"} at{" "}
                                                {convertToESTV2(
                                                    action.created_at,
                                                    true,
                                                    false
                                                )}
                                            </p>
                                            <p className="text-gray-600 text-sm">
                                                {action.question}
                                            </p>
                                        </div>
                                        {!deleteLoadings.includes(action.id) ? (
                                            <TrashIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(action.id);
                                                }}
                                                className="h-5 w-5 text-red-500 hover:text-red-400 cursor-pointer"
                                            />
                                        ) : (
                                            <LoadingWheel />
                                        )}
                                    </div>
                                    <div className="pt-2">
                                        <div className="w-full h-px bg-gray-300"></div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {selectedWarehouseClarification && (
                <WarehouseClarificationModal
                    orderType={selectedWarehouseClarification.order_type}
                    modalType="view"
                    orders={_orders}
                    setOrders={_setOrders}
                    openWarehouseClarificationModal={
                        openWarehouseClarificationModal
                    }
                    setOpenWarehouseClarificationModal={
                        setOpenWarehouseClarificationModal
                    }
                    handleRefetch={handleRefetch}
                    selectedWarehouseClarification={
                        selectedWarehouseClarification
                    }
                />
            )}
        </div>
    );
}

export default AdminWarehouseClarificationTasksPage;
