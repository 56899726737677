/** @format */

import { useEffect, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import { SmallSelectorWithNavigate } from "../../../../components/dropdowns/smallSelector";
import Header from "../../../../components/header";
import TabSelector from "../../../../components/tabSelector";
import { FbaShipmentRequest } from "../../../../models/clients";
import FbaShipmentRequestsTable, {
    initialFbaShipmentRequestsColumnOrder,
} from "../../../../tables/fbaShipmentRequestsTable";
import { useSort } from "../../../../hooks/useSort";
import { useClient } from "../../../../utils/client/clientContext";
import Results from "../../../../models/results";
import LoadingWheel from "../../../../components/loadingWheel";
import { fetchFbaShipmentRequests } from "../api/outboundApi";
import ClientSearchBar from "../../../../components/client/searchBar";

function FbaShipmentRequestsPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [keywords, setKeywords] = useState<string[]>([]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [fbaShipmentRequests, setFbaShipmentRequests] = useState<
        FbaShipmentRequest[]
    >([]);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchFbaShipmentRequests();
            if (data.status === "success") {
                setFbaShipmentRequests(data.data.requests);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto sm:px-10 px-4">
                <div className="">
                    <Header />
                </div>

                <div className="pt-10 flex flex-col overflow-x-auto min-h-screen">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">
                            Requested FBA Shipments
                        </span>
                        <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                            {orderCount?.shipping_details}
                        </span>
                    </div>

                    <div className="flex flex-row items-start space-x-4 pt-4">
                        <SmallSelectorWithNavigate
                            selectorData={[
                                {
                                    id: 1,
                                    name: "Shipments",
                                    value: "/outbound/requested",
                                },
                                {
                                    id: 2,
                                    name: "FBA",
                                    value: "/outbound/fba-shipment-requests",
                                },
                                {
                                    id: 3,
                                    name: "Details",
                                    value: "/outbound/requested-details",
                                },
                                {
                                    id: 4,
                                    name: "Pictures",
                                    value: "/outbound/requested-pictures",
                                },
                            ]}
                        />
                        <ClientSearchBar
                            setData={setFbaShipmentRequests}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="fba_shipment_requests"
                            sub_page=""
                            keywords={keywords}
                            setKeywords={setKeywords}
                        />
                    </div>
                    {!loading ? (
                        <FbaShipmentRequestsTable
                            fbaShipmentRequests={fbaShipmentRequests}
                            setFbaShipmentRequests={setFbaShipmentRequests}
                            results={results}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            emptyTableText="You have no fba shipment requests."
                            columnOrder={initialFbaShipmentRequestsColumnOrder}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pb-44 justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no fba shipment requests
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FbaShipmentRequestsPage;
