/** @format */

import { useSearchParams } from "react-router-dom";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { PictureRequest } from "../models/clients";
import { CompletedStatusTag } from "../components/statusTags";
import PictureRequestModal from "../pages/admin/worklist/picture-requests/pictureRequestModal";
import { useState } from "react";
import { KpiMetric } from "../models/kpi";
import KpiAuditsModal from "../pages/admin/audits/kpis/metrics/kpiAuditsModal";

export const initialKpisColumnOrder = [
    {
        key: "employee_name",
        label: "Employee name",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "action_type",
        label: "Action type",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "start_time",
        label: "Start time",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "end_time",
        label: "End time",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "duration",
        label: "Duration",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "total_units",
        label: "Total units",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "duration_per_unit",
        label: "Duration per unit",
        isSortable: true,
        className: "sm:min-w-32 min-w-32",
    },
];

interface KpisTableProps {
    kpis: KpiMetric[];
    results: Results | null;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
    handleDelete?: (shipping_name: string) => Promise<void>;
}

function KpisTable({
    kpis,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    handleDelete,
}: KpisTableProps) {
    const [searchParams] = useSearchParams();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const [selectedKpi, setSelectedKpi] = useState<KpiMetric | null>(null);

    return (
        <div className="flex flex-col pt-3">
            {kpis && results && kpis.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {columnOrder &&
                                        columnOrder.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {kpis.map((kpi: KpiMetric, index: number) => (
                                    <tr
                                        onClick={() => setSelectedKpi(kpi)}
                                        key={index}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {columnOrder &&
                                            columnOrder.map(
                                                (header: Column) => (
                                                    <>
                                                        {[
                                                            "start_time",
                                                            "end_time",
                                                        ].includes(
                                                            header.key
                                                        ) ? (
                                                            <td className="py-2.5 text-sm">
                                                                {convertDateToEST(
                                                                    String(
                                                                        kpi[
                                                                            header.key as keyof KpiMetric
                                                                        ]
                                                                    )
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "status" ? (
                                                            <td>
                                                                <CompletedStatusTag
                                                                    completed={
                                                                        kpi.created_at
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td
                                                                key={`${index}-${header.key}`}
                                                                className="py-2.5 text-sm"
                                                            >
                                                                {
                                                                    kpi[
                                                                        header.key as keyof KpiMetric
                                                                    ]
                                                                }
                                                            </td>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/kpis/metrics?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/kpis/metrics?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}

            <KpiAuditsModal
                kpi={selectedKpi}
                setKpi={setSelectedKpi}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default KpisTable;
