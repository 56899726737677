/** @format */

import { useEffect, useState } from "react";
import AdminDashboardLayout from "../../../../components/admin/dashboardLayout";
import PictureRequestsTable, {
    initialPictureRequestsColumnOrder,
} from "../../../../tables/pictureRequestsTable";
import { useClient } from "../../../../utils/client/clientContext";
import { PictureRequest } from "../../../../models/clients";
import Results from "../../../../models/results";
import { useSort } from "../../../../hooks/useSort";
import LoadingWheel from "../../../../components/loadingWheel";
import { fetchWorklist } from "../api/worklistApi";

function AdminPictureRequestsPage() {
    const { user_id, orderCount } = useClient();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });
    const [pictureRequests, setPictureRequests] = useState<PictureRequest[]>(
        []
    );
    const [results, setResults] = useState<Results | null>(null);

    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchWorklist("picture_requests", true);
            if (data.status === "success") {
                setPictureRequests(data.data.picture_requests.data);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <AdminDashboardLayout user_id={user_id} orderCount={orderCount}>
            <div className="flex flex-row items-center justify-between">
                <span className="text-2xl font-bold">Picture Requests</span>
            </div>

            {!loading ? (
                <PictureRequestsTable
                    pictureRequests={pictureRequests}
                    results={results}
                    sortLoading={sortLoading}
                    sortConfig={sortConfig}
                    toggleSort={toggleSort}
                    handleRefetch={handleRefetch}
                    emptyTableText="No picture requests found."
                    columnOrder={initialPictureRequestsColumnOrder}
                />
            ) : (
                <div className="flex flex-grow items-center pb-44 justify-center">
                    <LoadingWheel />
                </div>
            )}
        </AdminDashboardLayout>
    );
}

export default AdminPictureRequestsPage;
