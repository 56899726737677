/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { Order, ReceivedOrder } from "../../../../models/order";
import { useSort } from "../../../../hooks/useSort";
import { Column } from "../../../../tables/models";
import { useNotification } from "../../../../utils/notification/notificationContext";
import LoadingWheel from "../../../../components/loadingWheel";
import { DisposalRequest } from "../../../../models/clients";
import {
    approveDisposalRequest,
    fetchOrdersFromDisposalRequest,
    fetchOrdersOfPictureRequest,
} from "../api/worklistApi";
import { ActionButton } from "../../../../components/buttons";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../../tables/receivedOrdersTable";

interface DisposalRequestModalProps {
    disposalRequest: DisposalRequest | null;
    setDisposalRequest: React.Dispatch<
        React.SetStateAction<DisposalRequest | null>
    >;
    handleRefetch: () => void;
}

function DisposalRequestModal({
    disposalRequest,
    setDisposalRequest,
    handleRefetch,
}: DisposalRequestModalProps) {
    const isAdmin = () => window.location.href.includes("admin");

    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [orders, setOrders] = useState<Order[]>([]);
    const [receivedOrders, setReceivedOrders] = useState<ReceivedOrder[]>([]);

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );
    const hiddenHeaders: string[] = [
        "select",
        "location",
        "qa",
        "input_shelf_location",
        "input_shipping_quantity",
        "remove_selected_row",
        "order_name",
    ];

    const [dataLoading, setDataLoading] = useState(false);

    const fetchData = async () => {
        setDataLoading(true);
        const data = await fetchOrdersFromDisposalRequest(
            disposalRequest?.id || -1,
            isAdmin()
        );
        if (data.status === "success") {
            if (data.data.table_name === "orders") {
                setOrders(data.data.orders);
            } else {
                setReceivedOrders(data.data.orders);
            }
        }
        setDataLoading(false);
    };

    useEffect(() => {
        if (disposalRequest) {
            fetchData();
        }
    }, [disposalRequest]);

    const [approveLoading, setApproveLoading] = useState(false);
    const handleApprove = async () => {
        setApproveLoading(true);
        const data = await approveDisposalRequest(disposalRequest?.id || -1);
        if (data.status === "success") {
            showNotification("Successfully approved disposal request.");
            setDisposalRequest(null);
            handleRefetch();
        } else {
            showNotification(data.message, data.errors[0], "error");
        }
        setApproveLoading(false);
    };

    return (
        <Dialog
            open={disposalRequest !== null}
            onClose={() => setDisposalRequest(null)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-[95%] sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setDisposalRequest(null)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Disposal Request
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            {!dataLoading &&
                            disposalRequest?.table_name == "orders" ? (
                                <OrdersTable
                                    orderStatus="inbound"
                                    orders={orders}
                                    results={null}
                                    selectedOrders={[]}
                                    setSelectedOrders={setOrders}
                                    sortLoading={sortLoading}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    handleRefetch={() => {}}
                                    client_type="admin"
                                    setTotalSum={setTotalSum}
                                    columnOrder={unitsColumnOrder}
                                    hiddenHeaders={hiddenHeaders}
                                    quantityName="Quantity Ordered"
                                />
                            ) : !dataLoading &&
                              disposalRequest?.table_name ==
                                  "received_orders" ? (
                                <ReceivedOrdersTable
                                    orders={receivedOrders}
                                    setOrders={setReceivedOrders}
                                    results={null}
                                    sortLoading={sortLoading}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    handleRefetch={() => {}}
                                    setTotalSum={setTotalSum}
                                    columnOrder={
                                        initialReceivedOrdersColumnOrder
                                    }
                                    hiddenHeaders={[
                                        "select",
                                        "order_name",
                                        "input_shipping_quantity",
                                    ]}
                                    onRowClick={() => {}}
                                    client_type="admin"
                                />
                            ) : (
                                <div className="flex flex-row w-full items-center justify-center">
                                    <LoadingWheel />
                                </div>
                            )}

                            <div className="w-full pb-4 pt-2">
                                <label
                                    htmlFor="comment"
                                    className="block text-sm/6 font-medium text-gray-900 mx-1"
                                >
                                    Client Notes
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        id="comment"
                                        name="comment"
                                        rows={3}
                                        className="block w-full bg-gray-100 rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                                        value={disposalRequest?.client_notes}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        {!disposalRequest?.confirmed && (
                            <div className="flex flex-row-reverse">
                                <ActionButton
                                    label="Approve disposal"
                                    handleClick={() => handleApprove()}
                                    height={9}
                                    loading={approveLoading}
                                />
                            </div>
                        )}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default DisposalRequestModal;
