/** @format */

export const warehouseClarifiationTabs = (
    orderCount: number | null | undefined // Pass the count as an argument
) => [
    {
        name: "Tasks",
        href: "/admin/worklist/warehouse-clarification/tasks",
        icon: null,
        value: "tasks",
    },
    {
        name: `Answers`,
        href: "/admin/worklist/warehouse-clarification/answers",
        icon: null,
        value: "answers",
    },
    {
        name: `Completed`,
        href: "/admin/worklist/warehouse-clarification/completed",
        icon: null,
        value: "completed",
    },
];
