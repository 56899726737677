
export const cleanTrackingNumber = (trackingNumber: string): [string, string] => {
    const upsRegex = /\b1Z[A-Z0-9]{16}\b/;
    const uspsRegex = /\b([A-Z]{2}\d{9}[A-Z]{2}|(420\d{9}(9[2345])?)?\d{20}|(420\d{5})?(9[12345])?(\d{24}|\d{20})|82\d{8})\b/;
    const dhlRegex = /\b(\d{4}[- ]?\d{4}[- ]?\d{2}|\d{3}[- ]?\d{8}|[A-Z]{3}\d{7})\b/;
    const fedexRegex = /\b([0-9]{12}|100\d{31}|\d{15}|\d{18}|96\d{20}|96\d{32})\b/

    const lasershipRegex = /^1LSCXLN[A-Z0-9]{8}$/;

    const trackingNumberSorted = trackingNumber.toUpperCase()

    if (upsRegex.test(trackingNumberSorted)) {
        return [trackingNumberSorted, "UPS"];
    } else if (dhlRegex.test(trackingNumberSorted)) {
        return [trackingNumberSorted, "DHL"]
    } else if (trackingNumberSorted.length === 34) {
        if (trackingNumberSorted.startsWith('96') || trackingNumberSorted.startsWith('12')) {
            return [trackingNumberSorted.slice(22), "FedEx"];
        } else if (trackingNumberSorted.startsWith('420')) {
            const index = trackingNumberSorted.indexOf("92");
            const result = index !== -1 ? trackingNumberSorted.substring(index) : null;
            if (result) {
                return [result, "USPS"];
            }
        }
    } else if (trackingNumberSorted.length === 30) {
        return [trackingNumberSorted.slice(8), "USPS"]; // USPS
    } else if (uspsRegex.test(trackingNumberSorted)) {
        return [trackingNumberSorted, "USPS"];
    } else if (lasershipRegex.test(trackingNumberSorted)) {
        return [trackingNumberSorted, "LaserShip"];
    } else if (trackingNumberSorted.startsWith('1LS')) {
        return [trackingNumberSorted, "LaserShip"];
    } else if (fedexRegex.test(trackingNumberSorted)) {
        return [trackingNumberSorted, "FedEx"]
    } else if (trackingNumberSorted.startsWith("TBA")) {
        return [trackingNumberSorted, "Amazon"]
    }
    
    return [trackingNumberSorted, "Unknown"];
};