/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../../components/admin/sideBar";
import Header from "../../../../components/header";
import { useClient } from "../../../../utils/client/clientContext";
import { useSort } from "../../../../hooks/useSort";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useSearchParams } from "react-router-dom";
import Results from "../../../../models/results";
import { fetchFormSubmissions, updateFormSubmissions } from "../api/leadsApi";
import FormSubmissionsTable, {
    initialFormSubmissionsColumnOrder,
} from "../../../../tables/formSubmissionsTable";
import LoadingWheel from "../../../../components/loadingWheel";
import {
    ActionButton,
    GreenActionButton,
    YellowActionButton,
} from "../../../../components/buttons";
import AdminSearchBar from "../../../../components/admin/searchBar";

export interface FormSubmission {
    form_submission_id: string;
    created_at: string;
    name: string;
    company_name: string;
    email: string;
    phone: string;
    units_a_month: string;
    business_details: string;
    status: string;
    type: string;
    notes: string;
    last_follow_up: string;
}

function AdminFormSubmissions() {
    const {
        client,
        setClient,
        user_id,
        setUserId,
        orderCount,
        handleOrderCountRefetch,
    } = useClient();

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [formSubmissions, setFormSubmissions] = useState<FormSubmission[]>(
        []
    );
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [selectedFormSubmissions, setSelectedFormSubmissions] = useState<
        FormSubmission[]
    >([]);

    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchFormSubmissions(searchParams, sortConfig);
        if (data.status === "success") {
            setFormSubmissions(data.data.form_submissions);
            setResults(data.data.results);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setLoading(false);
    };

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const [updateLoading, setUpdateLoading] = useState(false);
    const [convertedUpdateLoading, setConvertedUpdateLoading] = useState(false);
    const handleContacted = async (status: string) => {
        if (status === "converted") {
            setConvertedUpdateLoading(true);
        } else {
            setUpdateLoading(true);
        }
        const formSubmissionIds = selectedFormSubmissions.map(
            (f) => f.form_submission_id
        );
        const data = await updateFormSubmissions(formSubmissionIds, status);
        if (data.status === "success") {
            setSelectedFormSubmissions([]);
            handleRefetch();
            showNotification(
                "Updated contacted status on form submissions",
                "",
                "success"
            );
        } else {
            showNotification("Failed to update status", data.message, "error");
        }
        setUpdateLoading(false);
        setConvertedUpdateLoading(false);
    };

    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState("");

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center justify-between pb-3">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">
                            Form Submissions
                        </span>
                    </div>
                    <div className="flex flex-row items-center">
                        <GreenActionButton
                            label="Mark converted"
                            handleClick={() => handleContacted("converted")}
                            height={8}
                            loading={convertedUpdateLoading}
                            viewDisabled={selectedFormSubmissions.length < 1}
                        />
                    </div>
                </div>

                <AdminSearchBar
                    setData={setFormSubmissions}
                    setLoading={setLoading}
                    handleRefetch={handleRefetch}
                    page="form-submissions"
                    sub_page=""
                    user_id={null}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    refetchSearchedData={false}
                />

                <div className="pt-3"></div>

                {!loading ? (
                    <FormSubmissionsTable
                        formSubmissions={formSubmissions}
                        results={results}
                        selectedFormSubmissions={selectedFormSubmissions}
                        setSelectedFormSubmissions={setSelectedFormSubmissions}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={handleRefetch}
                        emptyTableText="No records found"
                        columnOrder={initialFormSubmissionsColumnOrder}
                        hiddenHeaders={[]}
                    />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminFormSubmissions;
