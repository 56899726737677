/** @format */

import { useEffect } from "react";

export default function NoWebsiteRedirect() {
    useEffect(() => {
        const currentDomain = window.location.hostname;

        if (!currentDomain.startsWith("dashboard.")) {
            const newDomain = `https://dashboard.${currentDomain.replace(
                "www.",
                ""
            )}`;
            window.location.href = newDomain;
        }
    }, []);
    return <div></div>;
}
