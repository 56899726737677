/** @format */

import { useEffect, useRef, useState } from "react";
import { Box } from "../../../../models/adminDashboard";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { TrashIcon } from "@heroicons/react/24/solid";
import { createPortal } from "react-dom";

interface BoxesPopupProps {
    boxes: Box[];
    selectedBoxes: Box[];
    setSelectedBoxes: React.Dispatch<React.SetStateAction<Box[]>>;
    widthFull?: boolean;
}

export default function BoxesPopup({
    boxes,
    selectedBoxes,
    setSelectedBoxes,
    widthFull = false,
}: BoxesPopupProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        if (buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            // Calculate available space
            const spaceBelow = viewportHeight - buttonRect.bottom;
            const spaceAbove = buttonRect.top;

            // Determine dropdown placement
            if (spaceBelow < 300 && spaceAbove > spaceBelow) {
                // Position above the button
                setDropdownStyle({
                    bottom: viewportHeight - buttonRect.top,
                    left: buttonRect.left,
                });
            } else {
                // Position below the button
                setDropdownStyle({
                    top: buttonRect.bottom,
                    left: buttonRect.left,
                });
            }
        }

        setIsOpen((prevState) => !prevState);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleBoxClick = (box: Box) => {
        setSelectedBoxes((prev) => {
            const existingBox = prev.find((b) => b.box_id === box.box_id);

            if (existingBox) {
                return prev.map((b) =>
                    b.box_id === box.box_id
                        ? { ...b, quantity: b.quantity + 1 }
                        : b
                );
            } else {
                return [...prev, { ...box, quantity: 1 }];
            }
        });
        setIsOpen(false);
    };

    const handleBoxQuantityChange = (boxName: string, quantity: number) => {
        if (quantity < 1) return;
        setSelectedBoxes((prev) =>
            prev.map((b) => (b.name === boxName ? { ...b, quantity } : b))
        );
    };

    const handleRemoveBox = (boxName: string) => {
        setSelectedBoxes(selectedBoxes.filter((box) => box.name !== boxName));
    };

    const availableBoxes = boxes.filter(
        (box) =>
            !selectedBoxes.some((selectedBox) => selectedBox.name === box.name)
    );

    return (
        <div className="relative">
            <div
                ref={buttonRef}
                onClick={toggleDropdown}
                className="flex items-center cursor-pointer"
            >
                <span className="font-medium text-sm">
                    Add shipping supplies
                </span>
                <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
            </div>
            {isOpen &&
                createPortal(
                    <div
                        ref={dropdownRef}
                        className={`${
                            widthFull ? "w-full" : "w-48"
                        } absolute max-h-80 overflow-auto pt-1 bg-white border border-gray-300 shadow-lg rounded-md z-50`}
                        style={{
                            ...dropdownStyle,
                            position: "fixed", // Use fixed positioning for proper viewport alignment
                        }}
                    >
                        {availableBoxes.map((box) => (
                            <div
                                key={box.name}
                                onClick={() => handleBoxClick(box)}
                                className="text-sm flex flex-col py-2 space-y-0.5 pl-2 hover:bg-gray-100 transition-all cursor-pointer"
                            >
                                <span className="font-medium">{box.name}</span>
                                <span className="text-gray-700">
                                    {formatMoney(box.price)}
                                </span>
                            </div>
                        ))}
                    </div>,
                    document.body
                )}
            <div className="w-full pt-2">
                <div className="w-full pt-2">
                    <div className="flex flex-col space-y-3 w-full">
                        {selectedBoxes.length > 0 &&
                            selectedBoxes.map((box) => (
                                <div
                                    key={box.box_id}
                                    className="flex items-center space-x-2"
                                >
                                    <div className="flex flex-col text-sm min-w-44">
                                        <span className="font-medium">
                                            {box.name}
                                        </span>
                                        <span className="text-gray-700">
                                            {formatMoney(box.price)}
                                        </span>
                                    </div>
                                    <input
                                        type="number"
                                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        min="1"
                                        value={box.quantity}
                                        onChange={(e) =>
                                            handleBoxQuantityChange(
                                                box.name,
                                                parseInt(e.target.value)
                                            )
                                        }
                                        onWheel={(e) => {
                                            const target =
                                                e.target as HTMLInputElement;
                                            target.blur();
                                            setTimeout(() => target.focus(), 0);
                                        }}
                                    />
                                    <TrashIcon
                                        onClick={() =>
                                            handleRemoveBox(box.name)
                                        }
                                        className="w-5 h-5 text-red-600 hover:text-red-500 cursor-pointer"
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
