/** @format */

import { useEffect, useState } from "react";
import AdminDashboardLayout from "../../../../components/admin/dashboardLayout";
import { useClient } from "../../../../utils/client/clientContext";
import { useSort } from "../../../../hooks/useSort";
import Results from "../../../../models/results";
import { fetchWorklist } from "../api/worklistApi";
import LoadingWheel from "../../../../components/loadingWheel";
import { DisposalRequest } from "../../../../models/clients";
import DisposalRequestsTable, {
    initialDisposalRequestsColumnOrder,
} from "../../../../tables/disposalRequestsTable";

function AdminDisposalRequestsPage() {
    const { user_id, orderCount } = useClient();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });
    const [disposalRequests, setDisposalRequests] = useState<DisposalRequest[]>(
        []
    );

    const [loading, setLoading] = useState(false);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchWorklist("disposal_requests", true);
            if (data.status === "success") {
                setDisposalRequests(data.data.disposal_requests);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);
    return (
        <AdminDashboardLayout user_id={user_id} orderCount={orderCount}>
            <div className="flex flex-row items-center justify-between">
                <span className="text-2xl font-bold">Disposal Requests</span>
            </div>
            {!loading ? (
                <DisposalRequestsTable
                    disposalRequests={disposalRequests}
                    results={null}
                    sortLoading={false}
                    sortConfig={sortConfig}
                    toggleSort={toggleSort}
                    handleRefetch={handleRefetch}
                    emptyTableText="You have no disposal requests."
                    columnOrder={initialDisposalRequestsColumnOrder}
                />
            ) : (
                <div className="flex flex-grow items-center pb-44 justify-center">
                    <LoadingWheel />
                </div>
            )}
        </AdminDashboardLayout>
    );
}

export default AdminDisposalRequestsPage;
