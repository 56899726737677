/** @format */

import { useState } from "react";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";

import { useEffect } from "react";

export interface Limit {
    id: number;
    value: number;
}

const limits: Limit[] = [
    { id: 0, value: 200 },
    { id: 1, value: 10 },
    { id: 4, value: 100 },
    { id: -1, value: -1 },
];

interface LimitSelectorProps {
    setLimit?: React.Dispatch<React.SetStateAction<number>>;
}

export default function LimitSelector({ setLimit }: LimitSelectorProps) {
    const [selected, setSelected] = useState<Limit>(limits[0]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const limitFromUrl = params.get("limit");
        if (limitFromUrl) {
            const limitValue = parseInt(limitFromUrl, 10);
            const matchedLimit = limits.find(
                (limit) => limit.value === limitValue
            );
            if (matchedLimit) {
                setSelected(matchedLimit);
            }
        }
    }, [limits]);

    const handleChange = (newLimit: Limit) => {
        // Update the selected limit
        setSelected(newLimit);

        // Update the URL parameter and reload the page
        const params = new URLSearchParams(window.location.search);
        params.set("limit", newLimit.value.toString());
        params.set("offset", "0");
        window.location.search = params.toString(); // This will reload the page
    };

    const handleAdminChange = (newLimit: Limit) => {
        if (setLimit) {
            setLimit(newLimit.value);
            setSelected(newLimit);
        }
    };

    return (
        <Listbox
            value={selected}
            onChange={setLimit ? handleAdminChange : handleChange}
        >
            <div className="relative">
                <ListboxButton className="relative w-12 cursor-default rounded-md bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs">
                        {selected.value !== -1 ? selected.value : "All"}
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-50 mt-1 max-h-60 w-full bottom-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {limits.map((limit) => (
                        <ListboxOption
                            key={limit.id}
                            value={limit}
                            className={({ active, selected }) =>
                                `group relative cursor-default select-none py-2 pl-3
                            ${
                                active
                                    ? "bg-accent text-white"
                                    : selected
                                    ? "text-accent font-semibold"
                                    : "text-gray-900"
                            }
                            `
                            }
                        >
                            <span className="text-xs block truncate">
                                {limit.value !== -1 ? limit.value : "All"}
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}

interface AdminLimitSelectorProps {
    limit: number; // Passed in from the parent component
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
}

export function AdminLimitSelector({
    limit,
    setLimit,
    setOffset,
}: AdminLimitSelectorProps) {
    const [selected, setSelected] = useState<Limit>(
        limits.find((l) => l.value === limit) || limits[0]
    );

    useEffect(() => {
        const matchedLimit = limits.find((l) => l.value === limit);
        if (matchedLimit) {
            setSelected(matchedLimit);
        }
    }, [limit]);

    const handleChange = (newLimit: Limit) => {
        setSelected(newLimit);
        setLimit(newLimit.value); // Update parent state
        setOffset(0);
    };

    return (
        <Listbox value={selected} onChange={handleChange}>
            <div className="relative">
                <ListboxButton className="relative w-12 cursor-default rounded-md bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs">
                        {selected.value !== -1 ? selected.value : "All"}
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-50 mt-1 max-h-60 w-full bottom-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {limits.map((limit) => (
                        <ListboxOption
                            key={limit.id}
                            value={limit}
                            className={({ active, selected }) =>
                                `group relative cursor-default select-none py-2 pl-3
                                ${
                                    active
                                        ? "bg-accent text-white"
                                        : selected
                                        ? "text-accent font-semibold"
                                        : "text-gray-900"
                                }
                                `
                            }
                        >
                            <span className="text-xs block truncate">
                                {limit.value !== -1 ? limit.value : "All"}
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}
