/** @format */

import React, { useRef, useState } from "react";
import { Order } from "../../../../../../models/order";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import LoadingWheel from "../../../../../../components/loadingWheel";

interface PackingSlipProps {
    orders: Order[];
    quantityLabel: "quantity_shipped" | "inventory_quantity";
}

const PackingSlip: React.FC<PackingSlipProps> = ({
    orders,
    quantityLabel = "quantity_shipped",
}) => {
    // Function to generate the packing slip content
    const { showNotification } = useNotification();
    const slipRef = useRef<HTMLDivElement>(null);
    const generatePackingSlipContent = () => {
        return orders.map((order) => (
            <div
                key={order.order_id}
                style={{
                    marginBottom: "10px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                }}
            >
                <p>
                    <strong>Item Name:</strong> {order.item_name}
                </p>
                <p>
                    <strong>
                        {quantityLabel === "quantity_shipped"
                            ? "Quantity"
                            : "Inventory Quantity"}
                        :
                    </strong>{" "}
                    {quantityLabel === "quantity_shipped"
                        ? order.quantity_shipped
                        : order.quantity_remaining}
                </p>
                <p>
                    <strong>UPC:</strong> {order.upc || "N/A"}
                </p>
                <p>
                    <strong>Brand SKU:</strong> {order.brand_sku || "N/A"}
                </p>
                <p>
                    <strong>Size:</strong> {order.size}
                </p>
                <p>
                    <strong>Color:</strong> {order.color}
                </p>
                <p>
                    <strong>Shipment Notes:</strong> {order.user_notes || "N/A"}
                </p>
                <p>
                    <strong>Check-in Notes:</strong>{" "}
                    {order.admin_notes || "N/A"}
                </p>
                <p>
                    <strong>Location:</strong>{" "}
                    {Array.from(
                        new Set(
                            order.check_ins
                                .map((checkIn) => checkIn.location)
                                .filter((location) => location !== null)
                        )
                    ).join(", ")}
                </p>
                <p>
                    <strong>Checked-in by:</strong>{" "}
                    {order.order_logs.length > 0
                        ? order.order_logs[order.order_logs.length - 1]
                              .employee_name
                        : "N/A"}
                </p>
            </div>
        ));
    };

    // Function to handle printing
    const handleBrowserPrint = () => {
        const content = orders
            .map((order) =>
                ReactDOMServer.renderToString(
                    <div
                        key={order.order_id}
                        className="label"
                        style={{
                            width: "4in",
                            height: "3in",
                            padding: "10px",
                            margin: "10px",
                            pageBreakAfter: "always",
                        }}
                    >
                        <p>
                            <strong>Item Name:</strong> {order.item_name}
                        </p>
                        <p>
                            <strong>
                                {quantityLabel === "quantity_shipped"
                                    ? "Quantity"
                                    : "Inventory Quantity"}
                                :
                            </strong>{" "}
                            {quantityLabel === "quantity_shipped"
                                ? order.quantity_shipped
                                : order.quantity_remaining}
                        </p>
                        <p>
                            <strong>UPC:</strong> {order.upc || "N/A"}
                        </p>
                        <p>
                            <strong>Brand SKU:</strong>{" "}
                            {order.brand_sku || "N/A"}
                        </p>
                        <p>
                            <strong>Size:</strong> {order.size}
                        </p>
                        <p>
                            <strong>Color:</strong> {order.color}
                        </p>
                        <p>
                            <strong>User Notes:</strong>{" "}
                            {order.user_notes || "N/A"}
                        </p>
                        <p>
                            <strong>Check-in Notes:</strong>{" "}
                            {order.admin_notes || "N/A"}
                        </p>
                        <p>
                            <strong>Location:</strong>{" "}
                            {Array.from(
                                new Set(
                                    order.check_ins
                                        .map((checkIn) => checkIn.location)
                                        .filter((location) => location !== null)
                                )
                            ).join(", ")}
                        </p>
                        <p>
                            <strong>Checked-in by:</strong>{" "}
                            {order.order_logs.length > 0
                                ? order.order_logs[order.order_logs.length - 1]
                                      .employee_name
                                : "N/A"}
                        </p>
                    </div>
                )
            )
            .join("");

        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.write(`
                <html>
                <head>
                    <title>Packing Slip</title>
                    <style>
                    @page {
                          size: 4in 6in;
                          margin: 0;
                        }
                        body {
                          font-family: Arial, sans-serif;
                          margin: 0;
                          padding: 10px;
                        }
                        .label {
                          width: 100%;
                          height: 3in;
                          padding: 5px;
                          box-sizing: border-box;
                        }
                    </style>
                </head>
                <body>
                    ${content}
                </body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
        }
    };

    const [loading, setLoading] = useState(false);
    const handleDownload = () => {
        const content = generatePackingSlipContent();
        const htmlContent = content
            .map((item) => ReactDOMServer.renderToString(item))
            .join("");
        const blob = new Blob([htmlContent], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "packing_slip.html";
        a.click();
        URL.revokeObjectURL(url);
    };

    const handlePrint = async () => {
        setLoading(true);
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: [4, 6], // 4x6 inches for shipping labels
        });

        const margin = 0.2;
        const maxWidth = 3.6; // 4 - (0.2 * 2) for left and right margins

        orders.forEach((order, index) => {
            let startY = margin;
            let contentAdded = false;

            const addText = (label: string, value: string | number) => {
                const text = `${label}: ${value}`;
                const splitText = pdf.splitTextToSize(text, maxWidth);
                if (splitText.length > 0) {
                    pdf.text(splitText, margin, startY);
                    startY += splitText.length * 0.35;
                    contentAdded = true;
                }
            };

            addText("Item Name", order.item_name);
            addText(
                quantityLabel === "quantity_shipped"
                    ? "Quantity"
                    : "Inventory Quantity",
                quantityLabel === "quantity_shipped"
                    ? order.quantity_shipped
                    : order.quantity_remaining
            );
            addText("UPC", order.upc || "N/A");
            addText("Brand SKU", order.brand_sku || "N/A");
            addText("Size", order.size);
            addText("Color", order.color);
            addText("User Notes", order.user_notes || "N/A");
            addText("Check-in Notes", order.admin_notes || "N/A");
            addText(
                "Location",
                Array.from(
                    new Set(
                        order.check_ins
                            .map((checkIn) => checkIn.location)
                            .filter((location) => location !== null)
                    )
                ).join(", ") || "N/A"
            );
            addText(
                "Checked-in by: ",
                order.order_logs.length > 0
                    ? order.order_logs[order.order_logs.length - 1]
                          .employee_name
                    : "N/A"
            );

            // Only add a new page if content was added and it's not the last order
            if (contentAdded && index < orders.length - 1) {
                pdf.addPage([4, 6], "portrait");
            }
        });

        // Convert PDF to blob
        const pdfBlob = pdf.output("blob");

        // Prepare FormData
        const formData = new FormData();
        formData.append("file", pdfBlob, "packing_slip.pdf");
        formData.append("isLabel", "true");

        try {
            const printResponse = await fetch("http://localhost:8000/print", {
                method: "POST",
                body: formData,
            });

            const printData = await printResponse.json();

            if (printData.status !== "success") {
                showNotification("Failed to queue print job.", "", "error");
                return;
            }

            showNotification(
                "Print job successfully queued.",
                "",
                "successPrint"
            );
        } catch (error) {
            showNotification("Failed to queue print job.", "", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-row items-center space-x-4 text-base font-medium">
            {!loading ? (
                <button
                    className="text-accent cursor-pointer hover:text-accenthighlight"
                    onClick={handlePrint}
                >
                    Print Packing List
                </button>
            ) : (
                <LoadingWheel />
            )}
            <button
                className="text-accent cursor-pointer hover:text-accenthighlight"
                onClick={handleDownload}
            >
                Download Packing List
            </button>
        </div>
    );
};

export default PackingSlip;
