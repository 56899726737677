/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { KpiMetric } from "../../../../../models/kpi";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { ActionButton } from "../../../../../components/buttons";
import { fetchKpiAudits } from "../../api/kpisApi";
import { AuditLog } from "../../page";
import LoadingWheel from "../../../../../components/loadingWheel";
import { convertToESTV2 } from "../../../../../utils/date/date_formatter";

dayjs.extend(isoWeek);

interface KpiAuditsModalProps {
    kpi: KpiMetric | null;
    setKpi: React.Dispatch<React.SetStateAction<KpiMetric | null>>;
    handleRefetch: () => void;
}

function KpiAuditsModal({ kpi, setKpi, handleRefetch }: KpiAuditsModalProps) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState<boolean>(false);

    const [audits, setAudits] = useState<AuditLog[]>([]);

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchKpiAudits(
            kpi?.row_id || "",
            kpi?.table_name || ""
        );
        if (data.status === "success") {
            setAudits(data.data.audits);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (kpi) {
            fetchData();
        }
    }, [kpi]);

    return (
        <Dialog
            open={kpi !== null}
            onClose={() => setKpi(null)}
            className="relative z-30"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-30 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setKpi(null)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-3"
                        >
                            Kpi Audits
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        {!loading ? (
                            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                                {audits.map((audit) => (
                                    <div className="flex flex-col">
                                        <div className="flex flex-row items-center justify-between">
                                            <h3 className="text-gray-900 font-medium text-sm">
                                                {audit.description}
                                            </h3>
                                            <p className="text-sm text-gray-700">
                                                {convertToESTV2(
                                                    audit.created_at,
                                                    true
                                                )}
                                            </p>
                                        </div>
                                        <a className="text-accent cursor-pointer hover:text-accenthighlight text-sm">
                                            {audit.row_id}
                                        </a>
                                        <p className="text-sm text-gray-700">
                                            {audit.operation} -{" "}
                                            {audit.table_name}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            By {audit.modified_by}
                                        </p>
                                        <div className="pt-2">
                                            <div className="h-px w-full bg-gray-200"></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 flex flex-row items-center justify-center">
                                <LoadingWheel />
                            </div>
                        )}
                        {/* <div className="flex flex-row-reverse justify-between">
                            <ActionButton
                                label="Save"
                                handleClick={() => handleEdit()}
                                height={9}
                                loading={editLoading}
                            />
                        </div> */}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default KpiAuditsModal;
