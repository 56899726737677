/** @format */

import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useClient } from "../../../../utils/client/clientContext";
import { shipmentTypes } from "../../../../models/data";
import { useCallback, useEffect, useRef, useState } from "react";
import Shipment, { ShipmentType } from "../../../../models/shipments";
import Results from "../../../../models/results";
import { TrackingInfo } from "../../../../models/trackings";
import { useSort } from "../../../../hooks/useSort";
import { fetchClientOrders } from "../../api/unitsApi";
import { fetchAllShipmentTrackings } from "../../api/shipmentsApi";
import { Column } from "../../../../tables/models";
import ShipmentsTable, {
    initialShipmentsColumnOrder,
} from "../../../../tables/shipmentsTable";
import TrackingsTable, {
    initialTrackingsColumnOrder,
} from "../../../../tables/trackingsTable";
import AdminSideBar from "../../../../components/admin/sideBar";
import Header from "../../../../components/header";
import SmallSelector, {
    SmallSelectorWithNavigate,
} from "../../../../components/dropdowns/smallSelector";
import { ArrowUpTrayIcon, UserIcon } from "@heroicons/react/24/outline";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { ClientFilterPopup } from "../../clients/filters/clientFilter";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import AdminSearchBar from "../../../../components/admin/searchBar";
import LoadingWheel from "../../../../components/loadingWheel";
import ClientDrawer from "../../clients/clientDrawer";
import { SecondaryButton } from "../../../../components/buttons";
import { exportAdminData } from "../../api/exportApi";

export default function AdminShipmentTrackingsPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [selectedShipmentType, setSelectedShipmentType] = useState(
        shipmentTypes[2]
    );

    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [trackings, setTrackings] = useState<TrackingInfo[]>([]);
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(false);

    const [selectedShipments, setSelectedShipments] = useState<Shipment[]>([]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const fetchData = async (
        shipmentType: string,
        controller: AbortController
    ) => {
        if (["shipped", "requested"].includes(selectedShipmentType.value)) {
            const data = await fetchClientOrders(
                user_id,
                "shipments",
                undefined,
                searchParams.get("offset")
                    ? Number(searchParams.get("offset"))
                    : 0,
                searchParams.get("limit")
                    ? Number(searchParams.get("limit"))
                    : 200,
                sortConfig,
                shipmentType,
                controller.signal
            );
            if (data.status === "success") {
                setShipments(data.data.orders);
                setResults(data.data.results);
                setLoading(false);
            } else {
                if (data.data.message !== "Request cancelled") {
                    showNotification(
                        "Failed fetching orders",
                        data.message,
                        "error"
                    );
                }
                setLoading(false);
            }
            setSortLoading(false);
        } else if (["trackings"].includes(selectedShipmentType.value)) {
            const data = await fetchAllShipmentTrackings(
                user_id,
                searchParams.get("offset")
                    ? Number(searchParams.get("offset"))
                    : 0,
                searchParams.get("limit")
                    ? Number(searchParams.get("limit"))
                    : 200,
                sortConfig
            );
            if (data.status === "success") {
                setTrackings(data.data.trackings);
                setTrackingsResults(data.data.results);
            } else {
                showNotification(
                    "Failed fetching orders",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
            setSortLoading(false);
        }
    };

    const triggerRefetch = useCallback(() => {
        const controller = new AbortController();
        setLoading(true);
        fetchData(selectedShipmentType.value, controller);

        return () => {
            controller.abort();
            setLoading(false);
        };
    }, [sortConfig, user_id]);

    useEffect(() => {
        const cleanup = triggerRefetch();
        return cleanup;
    }, [sortConfig, user_id]);

    const handleRefetch = () => {
        if (keywords.length < 1 && inputValue === "") {
            triggerRefetch();
        } else {
            setRefetchSearchedData((prev) => !prev);
        }
    };

    const clientFilterRef = useRef<HTMLButtonElement>(null);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const [clientDrawerOpen, setClientDrawerOpen] = useState(false);

    const [totalSum, setTotalSum] = useState("");

    const [refetchSearchedData, setRefetchSearchedData] = useState(false);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        initialShipmentsColumnOrder
    );
    const [trackingsColumnOrder, setTrackingsColumnOrder] = useState<Column[]>(
        initialTrackingsColumnOrder
    );

    const [exportLoading, setExportLoading] = useState(false);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center">
                    <span className="text-2xl font-bold">Outbound</span>
                    <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                        {orderCount?.outbound.toLocaleString()}
                    </span>
                </div>

                <div className="flex flex-row items-center justify-between pt-4">
                    <div className="flex flex-row items-center space-x-4">
                        <SmallSelectorWithNavigate
                            selectorData={[
                                {
                                    id: 1,
                                    name: "Shipped",
                                    value: "/admin/outbound/shipped",
                                },
                                {
                                    id: 2,
                                    name: "Requested",
                                    value: "/admin/outbound/requested",
                                },
                                {
                                    id: 3,
                                    name: "Trackings",
                                    value: "/admin/outbound/trackings",
                                },
                            ]}
                        />
                        <div className="relative">
                            <button
                                ref={clientFilterRef}
                                onClick={toggleFilter}
                                className="text-xs text-gray-600 hover:bg-gray-50 flex flex-row items-center rounded-md border border-gray-300 px-2 h-7 shadow-sm divide-x gap-x-2"
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    {!user_id ? (
                                        <PencilSquareIcon className="w-4 h-4" />
                                    ) : (
                                        <XCircleIcon
                                            className="w-4 h-4 text-red-500 hover:text-red-400"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                searchParams.delete("user_id");
                                                // Update the URL with the modified searchParams
                                                window.history.replaceState(
                                                    null,
                                                    "",
                                                    `?${searchParams.toString()}`
                                                );
                                                setUserId(null);
                                            }}
                                        />
                                    )}
                                    <span>Client ID</span>
                                </div>
                                {user_id && (
                                    <span className="font-medium text-accent pl-2">
                                        {user_id} -{" "}
                                        <span className="text-gray-600">
                                            {client &&
                                                client.first_name +
                                                    " " +
                                                    client.last_name}
                                        </span>
                                    </span>
                                )}
                            </button>
                            <ClientFilterPopup
                                isOpen={isFilterOpen}
                                onClose={() => setIsFilterOpen(false)}
                                taxButtonRef={clientFilterRef}
                                user_id={user_id}
                                setUserId={setUserId}
                            />
                        </div>
                        {user_id && (
                            <button
                                onClick={() => setClientDrawerOpen(true)}
                                className="flex h-7 flex-row items-center text-xs px-2 border border-gray-300 shadow-sm rounded-md text-black"
                            >
                                <UserIcon className="w-4 h-4" />{" "}
                                <span className="pl-1.5">View client</span>
                            </button>
                        )}
                        <ColumnOrderDropdown
                            columnOrder={trackingsColumnOrder}
                            setColumnOrder={setTrackingsColumnOrder}
                            localStorageKey="trackingsColumnOrder"
                            selectedOrderType={selectedShipmentType}
                            initialColumnOrder={initialTrackingsColumnOrder}
                        />
                    </div>
                    <div className="flex flex-row items-center space-x-4 divide-x divide-gray-200">
                        {totalSum !== "" && (
                            <span className="text-gray-700 text-sm">
                                Sum: {totalSum}
                            </span>
                        )}
                        {selectedShipments && selectedShipments?.length > 0 && (
                            <span className="text-gray-700 text-sm pl-2">
                                {selectedShipments.length} shipment
                                {selectedShipments.length > 1 && "s"} selected
                            </span>
                        )}
                    </div>
                    <SecondaryButton
                        label="Export"
                        handleClick={() =>
                            exportAdminData(
                                user_id,
                                "outbound",
                                "trackings",
                                setExportLoading,
                                showNotification
                            )
                        }
                        iconDisplayed={ArrowUpTrayIcon}
                        loading={exportLoading}
                    />
                </div>

                <div className="flex flex-row items-start space-x-4 pt-3">
                    <AdminSearchBar
                        setData={setTrackings}
                        setLoading={setLoading}
                        handleRefetch={handleRefetch}
                        page="shipments"
                        sub_page={selectedShipmentType.value}
                        user_id={user_id || null}
                        keywords={keywords}
                        setKeywords={setKeywords}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        refetchSearchedData={refetchSearchedData}
                    />
                </div>

                {!loading ? (
                    <TrackingsTable
                        trackings={trackings}
                        results={trackingsResults}
                        trackingType="outbound"
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={handleRefetch}
                        emptyTableText="You'll see the trackings that have been sent outbound here."
                        columnOrder={trackingsColumnOrder}
                        hiddenHeaders={["actions"]}
                    />
                ) : (
                    <div className="flex flex-grow items-center pb-44 justify-center">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <span className="text-sm text-gray-500">
                                User has no shipments
                            </span>
                        )}
                    </div>
                )}
            </div>

            <ClientDrawer
                user_id={user_id || ""}
                open={clientDrawerOpen}
                setOpen={setClientDrawerOpen}
                client={client}
                setClient={setClient}
            />
        </div>
    );
}
