import Cookies from "js-cookie";
import { buildUrl } from '../../../utils/url/buildUrl';
import { debounce } from 'lodash';
import { SortConfig } from '../../../hooks/useSort';
import { apiRequest } from '../../../utils/api/apiRequest';
import { DebouncedFunc } from "lodash";

export const fetchClients = async (
    searchParams: URLSearchParams,
    sortConfig: SortConfig
) => {

    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/clients`;
        const params = {
            offset: searchParams.get('offset'),
            limit: searchParams.get('limit'),
            email: searchParams.get('email'),
            sort_key: sortConfig ? sortConfig.key : "inventory",
            sort_order: sortConfig ? sortConfig.order : "desc"
        };

        const url = buildUrl(baseUrl, params);

        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data

    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
};

export const fetchClientData = async (
    user_id: string | undefined,
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/${user_id}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return {success: false, message: String(error)}
    }
}

export const updateClientData = async (
    user_id: string | undefined,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    amazonStoreName: string,
    inventoryLabsEmail: string,
    inventoryLabsPassword: string,
    boxemEmail: string,
    boxemPassword: string,
    merchantUnitPrice: string,
    amazonUnitPrice: string,
    parcelForwardingUnitPrice: string,
    taxRate: string,
    maxShippingNames: string
) => {
    try {
        const jsonBody = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            amazon_store_name: amazonStoreName,
            inventory_labs_email: inventoryLabsEmail,
            inventory_labs_password: inventoryLabsPassword,
            boxem_email: boxemEmail,
            boxem_password: boxemPassword,
            merchant_unit_price: merchantUnitPrice,
            amazon_unit_price: amazonUnitPrice,
            parcel_forwarding_unit_price: parcelForwardingUnitPrice,
            tax_rate: taxRate,
            max_shipping_names: maxShippingNames
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/${user_id}`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data: {success: false, message: String(error)}}
    }
}

export const deleteClients = async (
    selectedClients: string[],
    mode: string,
    allClientsSelected: boolean
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients`,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({ selectedClients, allClientsSelected }),
            }
        );

        const data = await response.json();

        return data
    } catch (error) {
        return { data : {success : "failed", message: String(error)}}
    }
}

type SearchDataFunction = DebouncedFunc<(
    searchText: string,
    user_id: string | undefined | null,
    page: string | null,
    sub_page: string | null,
    setData: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleRefetch: () => void,
    order_ids?: string[]
) => Promise<void>> & { controller?: AbortController | null };

export const searchData: SearchDataFunction = debounce(async (
    searchText,
    user_id,
    page,
    sub_page,
    setData,
    setLoading,
    handleRefetch,
    order_ids
) => {
    if (searchText.trim() === "") {
        handleRefetch(true);
        return;
    }

    if (searchData.controller) {
        searchData.controller.abort();
    }

    searchData.controller = new AbortController();
    const { signal } = searchData.controller;

    try {
        setLoading(true);

        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/search`;
        const params = {
            searchText,
            user_id: user_id ? user_id : null,
            page,
            sub_page,
            order_ids: order_ids ? order_ids : null
        };

        const url = buildUrl(baseUrl, params);

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + Cookies.get("jwt")
            },
            signal
        });

        // Only proceed if the request was not aborted
        if (!signal.aborted) {
            const data = await response.json();
            if (data.status === "success") {
                setData(data.data);
            }
        }
    } catch (error) {
        if (error instanceof Error) {
            if (error.name !== "AbortError") {
                console.error("An error occurred:", error.message);
            }
        } else {
            console.error("An unknown error occurred:", error);
        }
    } finally {
        setLoading(false);
    }
}, 300);

searchData.controller = null;

export const searchSpecificOrders = async (
    searchText: string,
    user_id: string | undefined | null,
    page: string | null,
    sub_page: string | null,
    match_all_keywords: boolean = true
) => {
    const baseUrl = `/admin/search`;
    const params = {
        searchText,
        user_id: user_id ? user_id : null,
        page,
        sub_page,
        match_all_keywords: match_all_keywords ? "true" : "false"
    };

    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const fetchTotalOrderCount = async () => {
    return apiRequest("/admin/order-count", "GET")
}

export const approveShippingNamesRequest = async (user_id: string) => {
    return apiRequest(`/clients/${user_id}/shipping-names`, "PUT")
}