/** @format */

import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import LoadingWheel from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import { Order } from "../../../../models/order";
import { useSort } from "../../../../hooks/useSort";
import { fetchInboundOrders } from "../api/inboundApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { ArrowUpTrayIcon, PlusIcon } from "@heroicons/react/24/outline";
import { MdOutlineFileUpload } from "react-icons/md";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import { AddBulkOrderModal, AddOrderModal } from "../addOrderModal";
import ClientSearchBar from "../../../../components/client/searchBar";
import DeleteOrderModal from "../inbound-data/deleteOrderModal";
import { BulkEditOrderModal } from "../inbound-data/bulkEditOrderModal";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { Column } from "../../../../tables/models";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../models/data";
import { useClient } from "../../../../utils/client/clientContext";
import {
    ActionButton,
    DeleteButton,
    SecondaryButton,
} from "../../../../components/buttons";
import { exportData } from "../../api/exportApi";
import { SmallSelectorWithNavigate } from "../../../../components/dropdowns/smallSelector";
import {
    createStripeBillingPortal,
    fetchPaymentDetails,
} from "../../billing/api/billingApi";
import { PaymentDetailsAlertBanner } from "../../billing/page";

export default function InboundUnitsPage() {
    const { orderCount, handleOrderCountRefetch } = useClient();

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [selectedInboundOrder, setSelectedInboundOrder] =
        useState<Order | null>(null);

    useEffect(() => {
        if (selectedInboundOrder) {
            setOpenEditModal(true);
        }
    }, [selectedInboundOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedInboundOrder(null);
        }
    }, [openEditModal]);

    const [orders, setOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [keywords, setKeywords] = useState<string[]>([]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort(
        {
            key: "created_at",
            order: "desc",
        },
        orders,
        setOrders,
        undefined,
        undefined,
        keywords
    );

    const [openAddOrderModal, setOpenAddOrderModal] = useState(false);
    const [openAddBulkOrderModal, setOpenAddBulkOrderModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = (fromSearch: boolean = false) => {
        setRefetchData((prevValue) => prevValue + 1);
        if (!fromSearch) {
            handleOrderCountRefetch();
        }
    };

    const hasFetchedOrders = useRef(false);
    const fetchData = async () => {
        const data = await fetchInboundOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setOrders(data.data.inbound);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
        setSelectedOrders([]);
        hasFetchedOrders.current = true;
    };

    useEffect(() => {
        if (!hasFetchedOrders.current || refetchData != 0) {
            setLoading(true);
            fetchData();
        }
    }, [refetchData]);

    useEffect(() => {
        if (hasFetchedOrders.current) {
            if (keywords.length > 0) return;
            setSortLoading(true);
            fetchData();
        }
    }, [sortConfig]);

    useEffect(() => {
        const openModal = searchParams.get("openModal");
        if (openModal === "new_inbound_shipment") {
            setOpenAddOrderModal(true);
        }
    }, []);

    useEffect(() => {
        fetchPaymentData();
    }, []);

    const [totalSum, setTotalSum] = useState("");

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);
    const hiddenHeaders = [
        "input_shipping_quantity",
        "remove_selected_row",
        "input_shelf_location",
        "location",
        "quantity_to_dispose",
    ];

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );

    const [exportLoading, setExportLoading] = useState(false);

    const [portalLoading, setPortalLoading] = useState(false);
    const handlePortalCreation = async () => {
        if (portalLoading) return;
        setPortalLoading(true);
        const data = await createStripeBillingPortal();
        if (data.status === "success") {
            window.location.href = data.data.url;
        } else {
            showNotification(
                "Failed to redirect to billing portal.",
                data.message,
                "error"
            );
        }
    };

    const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
    const fetchPaymentData = async () => {
        const data = await fetchPaymentDetails();
        if (data.status === "success") {
            setHasPaymentMethod(data.data.has_payment_method);
        }
    };

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto pb-4">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto min-h-screen">
                    {!hasPaymentMethod && (
                        <PaymentDetailsAlertBanner
                            handleRedirect={() => handlePortalCreation()}
                        />
                    )}
                    <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                        <div className="flex flex-row items-center">
                            <span className="text-2xl font-bold">Inbound</span>
                            <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                                {orderCount?.inbound.toLocaleString()}
                            </span>
                        </div>
                        <div className="flex flex-row items-center space-x-4 pt-4 sm:pt-0">
                            <ActionButton
                                label="New Inbound Shipment"
                                iconDisplayed={PlusIcon}
                                handleClick={() => setOpenAddOrderModal(true)}
                                viewDisabled={!hasPaymentMethod}
                            />
                            <SecondaryButton
                                label="Upload CSV"
                                iconDisplayed={MdOutlineFileUpload}
                                handleClick={() =>
                                    setOpenAddBulkOrderModal(true)
                                }
                                viewDisabled={!hasPaymentMethod}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row items-start py-4 space-x-4">
                        <SmallSelectorWithNavigate
                            selectorData={[
                                {
                                    id: 1,
                                    name: "Units",
                                    value: "/inbound/units",
                                },
                                {
                                    id: 2,
                                    name: "Trackings",
                                    value: "/inbound/trackings",
                                },
                            ]}
                        />
                        <ClientSearchBar
                            setData={setOrders}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inbound"
                            sub_page="units"
                            keywords={keywords}
                            setKeywords={setKeywords}
                        />
                        <ColumnOrderDropdown
                            columnOrder={unitsColumnOrder}
                            setColumnOrder={setUnitsColumnOrder}
                            localStorageKey="unitsColumnOrder"
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={getTableHeaders(
                                selectedOrderType.value
                            )}
                            buttonHeightClass="h-8"
                        />
                        <SecondaryButton
                            label="Export"
                            iconDisplayed={ArrowUpTrayIcon}
                            handleClick={() =>
                                exportData(
                                    "inbound",
                                    "units",
                                    setExportLoading,
                                    showNotification
                                )
                            }
                            loading={exportLoading}
                            height={8}
                        />
                        {selectedOrders.length > 0 && (
                            <>
                                <SecondaryButton
                                    label="Edit"
                                    handleClick={() =>
                                        setOpenBulkEditModal(true)
                                    }
                                    height={8}
                                />
                                <DeleteButton
                                    label="Delete"
                                    handleClick={() => setOpenDeleteModal(true)}
                                    height={8}
                                />
                            </>
                        )}
                    </div>
                    <div className="flex flex-row-reverse items-center">
                        {totalSum !== "" && (
                            <span className="text-gray-700 text-sm">
                                Sum: {totalSum}
                            </span>
                        )}
                    </div>
                    {!loading ? (
                        <OrdersTable
                            orderStatus="inbound"
                            orders={orders}
                            results={results}
                            selectedOrders={selectedOrders}
                            setSelectedOrders={setSelectedOrders}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            client_type="client"
                            setTotalSum={setTotalSum}
                            columnOrder={unitsColumnOrder}
                            hiddenHeaders={hiddenHeaders}
                            setOrders={setOrders}
                        />
                    ) : (
                        <div className="flex flex-grow items-center justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <AddOrderModal
                openAddOrderModal={openAddOrderModal}
                setOpenAddOrderModal={setOpenAddOrderModal}
                handleRefetch={handleRefetch}
            />

            <AddBulkOrderModal
                openAddBulkOrderModal={openAddBulkOrderModal}
                setOpenAddBulkOrderModal={setOpenAddBulkOrderModal}
                handleRefetch={handleRefetch}
            />

            <DeleteOrderModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                order_ids={selectedOrders.map((order) => order.order_id)}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
                setSelectedOrders={setSelectedOrders}
            />

            <BulkEditOrderModal
                orderIds={selectedOrders.map((order) => order.order_id)}
                openEditModal={openBulkEditModal}
                setOpenEditModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}
